import React from 'react'
import ComingSoon from '../Layout/Components/ComingSoon'

export default function RoleRight() {

  document.title = "Kalon | Role & Right";
  return (
      <div className="container">
      <ComingSoon/>
      </div>
  )
}
