import React, { useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import "react-data-table-component-extensions/dist/index.css";
import APICall from "../../networking/AxiousServices";
import { ApiTypes, AwsConstants } from "../../constants";
import { Button, Breadcrumb } from "react-bootstrap";
import SwitchButton from "../../Layout/Components/SwitchButton";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import S3 from "aws-sdk/clients/s3";
import { Credentials } from "aws-sdk";
import Swal from "sweetalert2";
import blank from "../../Assets/Image/Default/blank.png";
import CustomTableLoader from "../../Layout/Loader/CustomTableLoader";

export default function Categories() {
  document.title = "Kalon | Categories";
  let navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const textInput = useRef(null);
  const access = new Credentials({
    accessKeyId: AwsConstants.accessKeyId,
    secretAccessKey: AwsConstants.secretAccessKey,
  });

  const s3 = new S3({
    credentials: access,
    region: AwsConstants.region,
    signatureVersion: "v4",
  });

  const chageStatus = async (value, row) => {
    setCategories((prevState) =>
      prevState.map((mapItem) => {
        if (row.category_id === mapItem.category_id) {
          return {
            ...mapItem,
            is_active: value,
          };
        }
        return mapItem;
      })
    );
    let data = { category_id: row.category_id, is_active: value };
    let res = await APICall(ApiTypes.changeCategoryStatus, data);
    if (res.status == 1) {
      if (value == false) {
        toast.success("Category is disabled successfully.");
      } else {
        toast.success("Category is enabled successfully.");
      }
    }
  };

  const getImageUrls = async (response) => {
    let newArray = [];
    for (let i = 0; i < response.length; i++) {
      if (response[i].mst_category_pictures) {
        if (response[i].mst_category_pictures.length !== 0) {
          const url = await s3.getSignedUrlPromise("getObject", {
            Bucket: AwsConstants.Bucket,
            Key: `Home/Categories/${response[i].category_id}/${response[i].mst_category_pictures[0].display_pic}`,
            Expires: AwsConstants.signedUrlExpireSeconds,
          });
          response[i]["imageUrl"] = url;
        } else {
          response[i]["imageUrl"] = null;
        }
      } else {
        response[i]["imageUrl"] = null;
      }
      response[i]["index"] = i;
      newArray.push(response[i]);
    }
    return newArray;
  };

  const fetchCategories = async () => {
    setLoading(true);
    const bodyData = {
      search_keyword: textInput.current.value ? textInput.current.value : "",
    };
    let res = await APICall(ApiTypes.fetchCategories, bodyData);
    if (res.status === 1) {
      let newArray = await getImageUrls(res.data);
      setCategories(newArray);
      setLoading(false);
    }
  };

  const handleEditCategory = (cat_id) => {
    navigate(`/edit/${cat_id}/category`);
  };

  const handleDeleteCategory = async (cat_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      setLoading(true);
      if (result.isConfirmed) {
        let res = await APICall(ApiTypes.deleteCategory, {
          category_id: cat_id,
        });
        if (res) {
          if (res.status == 1) {
            toast.success("Category deleted successfully.");
            fetchCategories();
          } else {
            setLoading(false);
          }
        } else {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const columns = [
    {
      name: "Sr. No.",
      selector: (row) => row.index + 1,
      sortable: true,
      width: "100px",
    },
    {
      name: "Image",
      width: "100px",
      selector: (row) =>
        row.imageUrl ? (
          <img className="custom-image" src={row.imageUrl} alt=""></img>
        ) : (
          <img className="custom-image" src={blank} alt=""></img>
        )
    },
    {
      name: "Category Name",
      width: "400px",
      selector: (row) => row.category_name,
      sortable: true,
    },
    {
      name: "Code",
      selector: (row) => row.category_code,
      sortable: true,
      width: "100px",
    },
    {
      name: "Details",
      selector: (row) => row.details,
      sortable: true,
    },
    {
      name: "Status",
      width: "90px",
      selector: (row) => (
        <SwitchButton
          isActive={row.is_active}
          chageStatus={chageStatus}
          row={row}
        />
      ),
      sortable: true,
    },
    {
      name: "Action",
      sortable: false,
      width: "90px",
      selector: (row) => (
        <>
          <span
            // style={{ marginRight: "4px" }}
            onClick={() => {
              handleEditCategory(row.category_id);
            }}
            className="edit-icon"
          >
            <i className="fas fa-edit"></i>
          </span>
          {/* <Button
            onClick={() => {
              handleDeleteCategory(row.category_id);
            }}
            height="24px"
            width="24px"
            variant="danger"
          >
            <i className="fa fa-trash" aria-hidden="true"></i>
          </Button> */}
        </>
      ),
    },
  ];

  return (
    <div className="container-fluid">
      <div className="row mt-4">
        <div className="col-12">
          <div className="h4 mb-4">
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-column justify-content-center">
                <div className="d-flex breadcrum-navigation-title">
                  <div className="me-2">Categories</div>
                  <div className="me-2 text-muted">|</div>
                  <div className="d-flex">
                    <div onClick={() => navigate('/home')} className="cursor-pointer custom-breadcrum-inactive me-2">Home</div>
                    <div className="text-muted me-2">&gt;</div>
                    <div className="cursor-pointer custom-breadcrum-active me-2">Categories</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between mb-4">
            <input
              ref={textInput}
              onInput={fetchCategories}
              type="search"
              className="col-4 px-2"
              placeholder="Search..."
            />
            <Button
              onClick={() => {
                navigate("/add-category");
              }}
              className="ms-2"
              variant="primary"
            >
              Add Category
            </Button>
          </div>
          <div className="table-outer react-table">
            <DataTable
              data={categories}
              columns={columns}
              noHeader
              defaultSortField="id"
              defaultSortAsc={true}
              pagination
              highlightOnHover
              responsive={true}
              progressPending={loading}
              persistTableHead={true}
              progressComponent={<CustomTableLoader />}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
