import React from 'react'
import {Spinner} from 'react-bootstrap'

export default function CustomTableLoader() {
  return (<div className="m-4">
    <Spinner animation="border" role="status">
    <span className="visually-hidden">Loading...</span>
  </Spinner>
  </div> )
}
