import React from 'react'
import { useNavigate } from 'react-router-dom';
import { Form, Spinner } from "react-bootstrap";
import { useState } from 'react';
import InputTextPass from '../../Layout/Components/InputTextPass';
import APICall from '../../networking/AxiousServices';
import { ApiTypes } from '../../constants';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

export default function ChangePassword() {

    document.title = "Kalon | Change Password";
    const navigate = useNavigate();
    const loggedUserData = useSelector((state) => state.data);
    const [loading, setLoading] = useState(false);
    const [formInputs, setFormInputs] = useState({
        user_id: loggedUserData.userLoginData.user_id,
        old_password: "",
        updated_password: "",
        confirm_updated_password: ""
    });
    const [formInputsError, setFormInputsError] = useState({
        old_password: "",
        updated_password: "",
        confirm_updated_password: ""
    });
    const validateMsg = {
        old_password: "Current password is required.",
        updated_password: "Password is required.",
        confirm_updated_password: "Confirm password is required."
    }

    const validateForm = () => {
        let isValid = true;
        let errorJson = {};
        Object.keys(formInputsError).forEach(key_item => {
            if (formInputs[key_item] === "") {
                errorJson = {
                    ...errorJson,
                    [key_item]: validateMsg[key_item]
                }
                isValid = false
            } else {
                errorJson = {
                    ...errorJson,
                    [key_item]: ""
                }
            }
        })
        setFormInputsError({
            ...formInputsError,
            ...errorJson
        })
        return isValid;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            setLoading(true)
            let res = await APICall(ApiTypes.updatePassword, formInputs);
            if (res.status == 1) {
                setLoading(false)
                navigate('/')
                toast.success("Password has been changed successfully.");
            }
            else if (res.status == 0) {
                setLoading(false)
                toast.success(res.message);
            }
        }
    }

    return (
        <div className="container-fluid">
            <div className="row mt-4">
                <div className="col-12">
                    <div className="h4 mb-4">
                        <div className="d-flex justify-content-between">
                            <div className="d-flex flex-column justify-content-center">
                                <div className="d-flex breadcrum-navigation-title">
                                    <div className="me-2">Password</div>
                                    <div className="me-2 text-muted">|</div>
                                    <div className="d-flex">
                                        <div onClick={() => navigate('/home')} className="cursor-pointer custom-breadcrum-inactive me-2">Home</div>
                                        <div className="text-muted me-2">&gt;</div>
                                        <div className="cursor-pointer custom-breadcrum-active me-2">Change Password</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div style={{ background: "#fff" }}>
                                <div className="row">
                                    <div className="col-md-10 col-lg-8 col-xl-6">
                                        <Form className="py-4 px-3" onSubmit={(e) => handleSubmit(e)}>

                                            <InputTextPass
                                                title={'Current Password'}
                                                id={'current-password'}
                                                value={formInputs.old_password}
                                                validation={formInputsError.old_password}
                                                onChange={(value) => {
                                                    setFormInputs({
                                                        ...formInputs,
                                                        old_password: value
                                                    })
                                                }}
                                                placeholder={"Enter Current Password"}
                                            />
                                            <InputTextPass
                                                title={'Password'}
                                                id={'password'}
                                                value={formInputs.updated_password}
                                                validation={formInputsError.updated_password}
                                                onChange={(value) => {
                                                    setFormInputs({
                                                        ...formInputs,
                                                        updated_password: value
                                                    })
                                                }}
                                                placeholder={"Enter Password"}
                                            />
                                            <InputTextPass
                                                title={'Confirm Password'}
                                                id={'confirm-password'}
                                                value={formInputs.confirm_updated_password}
                                                validation={formInputsError.confirm_updated_password}
                                                onChange={(value) => {
                                                    setFormInputs({
                                                        ...formInputs,
                                                        confirm_updated_password: value
                                                    })
                                                }}
                                                placeholder={"Enter Confirm Password"}
                                            />

                                            <div className="row">
                                                <div className="mt-4 col-12 createbtn">
                                                    {!loading ? (
                                                        <button type="submit" className="btn btn-primary w-auto mb-2">
                                                            Update
                                                        </button>
                                                    ) : (
                                                        <button className="btn btn-primary w-auto mb-2 px-3">
                                                            <Spinner
                                                                style={{ height: "20px", width: "20px" }}
                                                                className="p-1"
                                                                animation="border"
                                                                variant="warning"
                                                            />
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
