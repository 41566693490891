import { ORDER_STATUS, PAYMENT_STATUS, PAYMENT_MODE ,SERVICE_REQUEST_STATUS ,PARLOUR_REQUEST_STATUS } from "../../constants/Enumeration"

const orderBadges = (order_status) => {
    switch (order_status) {
        case ORDER_STATUS.CONFIRMED:
            return <span className="badge success-label">Confirmed</span>
        case ORDER_STATUS.PENDING:
            return <span className="badge primary-label">Pending</span>
        case ORDER_STATUS.COMPLETED:
            return <span className="badge success-label">Completed</span>
        case ORDER_STATUS.PROCESSED:
            return <span className="badge badge-order-processed">Processed</span>
        case ORDER_STATUS.PROCESSING:
            return <span className="badge badge-order-processing">Processing</span>
        case ORDER_STATUS.CANCELLED:
            return <span className="badge danger-label">Cancelled</span>
        case ORDER_STATUS.REJECTED:
            return <span className="badge danger-label">Rejected</span>
        default:
            return "Looking forward to the Weekend";
    }
}

const payMentStatusBadges = (payment_status) => {
    switch (payment_status) {
        case PAYMENT_STATUS.PENDING:
            return <span className="badge primary-label">Pending</span>
        case PAYMENT_STATUS.SUCCESS:
            return <span className="badge success-label">Success</span>
        case PAYMENT_STATUS.PROCESSING:
            return <span className="badge badge-order-processing">Processing</span>
        case PAYMENT_STATUS.FAILURE:
            return <span className="badge danger-label">Failed</span>
        default:
            return "-";
    }
}

const paymentModeBadges = (payment_mode) => {
    switch (payment_mode) {
        case PAYMENT_MODE.ONLINE:
            return <span className="badge badge-online-label">Online</span>
        case PAYMENT_MODE.OFFLINE:
            return <span className="badge badge-offline-label">Offline</span>
        default:
            return <span className="text-center"> - </span>;
    }
}

const serviceRequestStatusBadges = (status) => {
    switch (status) {
        case SERVICE_REQUEST_STATUS.PENDING:
            return <span className="badge primary-label">Pending</span>
        case SERVICE_REQUEST_STATUS.REJECTED:
            return <span className="badge danger-label">Rejected</span>
        case SERVICE_REQUEST_STATUS.COMPLETED:
            return <span className="badge success-label">Completed</span>
        default:
            return "-";
    }
}

const ParlourRequestStatusBadges = (status) => {
    switch (status) {
        case PARLOUR_REQUEST_STATUS.PENDING:
            return <span className="badge primary-label">Pending</span>
        case PARLOUR_REQUEST_STATUS.REJECTED:
            return <span className="badge danger-label">Rejected</span>
        case PARLOUR_REQUEST_STATUS.ACCEPTED:
            return <span className="badge success-label">Accepted</span>
        default:
            return "-";
    }
}
export const Badges = {
    OrderStatusBadges: orderBadges,
    PayMentStatusBadges: payMentStatusBadges,
    PaymentmModeBadges: paymentModeBadges,
    ServiceRequestStatusBadges : serviceRequestStatusBadges,
    ParlourRequestStatusBadges :ParlourRequestStatusBadges,
}