import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
// import Layout from "../src/Layout";
import "./App.css";
// import Form from "./Pages/Form";
import * as All from "./Pages";
import { useSelector } from "react-redux";
import "../src/Assets/css/Custom.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./Layout/Footer/Footer";
import Header from "./Layout/Header/Header";
import Sidebar from "./Layout/Sidebar/Sidebar";
import { ProgressBar } from "react-bootstrap";

function App() {
  const loggedUserData = useSelector((state) => state.data);

  const [progress, setProgress] = useState(0);

  let isAuthenticated = loggedUserData.isLoggedIn;
  return (
    <>
      <div className="App position-relative w-100">
        {/* <Layout /> */}
        <Router>

          {progress !== 100 && progress !== 0 ? <ProgressBar className="progressbar" now={progress} /> : ""}
          <div>
            {isAuthenticated ? <Header /> : ""}
            {isAuthenticated ? <Sidebar /> : ""}
            <Routes>
              <Route
                exact
                path="/"
                element={
                  isAuthenticated ? (
                    <Navigate to="/home" />
                  ) : (
                    <All.AuthContainer
                      AuthComponent={All.Login}
                      title="Kalon | login"
                    />
                  )
                }
              />
              <Route
                exact
                path="/set-password"
                element={
                  isAuthenticated ? (
                    <Navigate to="/home" />
                  ) : (
                    <All.AuthContainer
                      AuthComponent={All.SetPassword}
                      title="Kalon | Set Password"
                    />
                  )
                }
              />
              <Route
                exact
                path="/forgot-password"
                element={
                  isAuthenticated ? (
                    <Navigate to="/home" />
                  ) : (
                    <All.AuthContainer
                      AuthComponent={All.ForgotPassword}
                      title="Kalon | Forgot Password"
                    />
                  )
                }
              />
            </Routes>

            <main id={isAuthenticated ? "main" : ""}>
              <Routes>
                <Route
                  exact
                  path="/home"
                  element={isAuthenticated ? <All.Home /> : <Navigate to="/" />}
                />

                <Route
                  exact
                  path="/categories"
                  element={
                    isAuthenticated ? <All.Categories /> : <Navigate to="/" />
                  }
                />
                <Route
                  exact
                  path="/add-category"
                  element={
                    isAuthenticated ? <All.AddCategory /> : <Navigate to="/" />
                  }
                />
                <Route
                  exact
                  path="/edit/:category_id/category"
                  element={
                    isAuthenticated ? <All.EditCategory /> : <Navigate to="/" />
                  }
                />

                <Route
                  exact
                  path="/services"
                  element={
                    isAuthenticated ? <All.Services /> : <Navigate to="/" />
                  }
                />
                <Route
                  exact
                  path="/add-service"
                  element={
                    isAuthenticated ? <All.AddService /> : <Navigate to="/" />
                  }
                />
                <Route
                  exact
                  path="/edit/:category_service_id/service"
                  element={
                    isAuthenticated ? <All.EditService /> : <Navigate to="/" />
                  }
                />

                <Route
                  exact
                  path="/service-requests"
                  element={
                    isAuthenticated ? <All.RequestedServices /> : <Navigate to="/" />
                  }
                />
                <Route
                  exact
                  path="/service-request/:category_service_request_id"
                  element={
                    isAuthenticated ? <All.RequestedServicesAction /> : <Navigate to="/" />
                  }
                />

                <Route
                  exact
                  path="/packages"
                  element={
                    isAuthenticated ? <All.Packages /> : <Navigate to="/" />
                  }
                />
                <Route
                  exact
                  path="/add-package"
                  element={
                    isAuthenticated ? <All.AddPackage /> : <Navigate to="/" />
                  }
                />
                <Route
                  exact
                  path="/edit/:package_id/package"
                  element={
                    isAuthenticated ? <All.EditPackage /> : <Navigate to="/" />
                  }
                />

                <Route
                  exact
                  path="/users"
                  element={isAuthenticated ? <All.Users /> : <Navigate to="/" />}
                />
                <Route
                  exact
                  path="/add-user"
                  element={
                    isAuthenticated ? <All.AddUser /> : <Navigate to="/" />
                  }
                />
                <Route
                  exact
                  path="/edit/:user_id/user"
                  element={
                    isAuthenticated ? <All.EditUser /> : <Navigate to="/" />
                  }
                />

                <Route
                  exact
                  path="/shopadmins"
                  element={
                    isAuthenticated ? <All.ShopAdmin /> : <Navigate to="/" />
                  }
                />
                <Route
                  exact
                  path="/add-shop-admin"
                  element={
                    isAuthenticated ? <All.AddShopAdmin /> : <Navigate to="/" />
                  }
                />
                <Route
                  exact
                  path="/edit/:parlour_id/shopadmin"
                  element={
                    isAuthenticated ? <All.EditShopAdmin /> : <Navigate to="/" />
                  }
                />settings
                <Route
                  exact
                  path={"/shopadmin/view/:parlour_id"}
                  element={
                    isAuthenticated ? <All.ViewShopAdmin /> : <Navigate to="/" />
                  }

                />
                <Route
                  exact
                  path={"/requested/shop-admin/view/:req_parlour_id"}
                  element={
                    isAuthenticated ? <All.RequestedShopAdminView /> : <Navigate to="/" />
                  }
                />
                {/* <Route exact path="/Form" element={<Form />} /> */}
                <Route
                  exact
                  path="/orders"
                  element={isAuthenticated ? <All.Orders /> : <Navigate to="/" />}
                />
                <Route
                  exact
                  path="/order/view/:order_id"
                  element={
                    isAuthenticated ? <All.ViewOrder setProgress={setProgress} /> : <Navigate to="/" />
                  }
                />
                <Route
                  exact
                  path="/posts"
                  element={isAuthenticated ? <All.Post /> : <Navigate to="/" />}
                />
                <Route
                  exact
                  path="/post/view/:post_id"
                  element={
                    isAuthenticated ? <All.ViewPost /> : <Navigate to="/" />
                  }
                />
                {/* <Route
                exact
                path="/post/likes/:post_id"
                element={
                  isAuthenticated ? <All.LikeMember /> : <Navigate to="/" />
                }
              /> */}

                <Route
                  exact
                  path="/customers"
                  element={
                    isAuthenticated ? <All.Customer /> : <Navigate to="/" />
                  }
                />
                <Route
                  exact
                  path="/customer/view/:customer_id"
                  element={
                    isAuthenticated ? <All.ViewCustomer /> : <Navigate to="/" />
                  }
                />
                <Route
                  exact
                  path="/news-blogs"
                  element={
                    isAuthenticated ? <All.NewsBlog /> : <Navigate to="/" />
                  }
                />
                <Route
                  exact
                  path="/notification"
                  element={
                    isAuthenticated ? <All.Notification /> : <Navigate to="/" />
                  }
                />

                <Route
                  exact
                  path="/report"
                  element={isAuthenticated ? <All.Report /> : <Navigate to="/" />}
                />
                <Route
                  exact
                  path="/rolesright"
                  element={
                    isAuthenticated ? <All.RoleRight /> : <Navigate to="/" />
                  }
                />

                <Route
                  exact
                  path="/settings"
                  element={
                    isAuthenticated ? <All.Settings /> : <Navigate to="/" />
                  }
                />

                <Route
                  exact
                  path="/transaction"
                  element={
                    isAuthenticated ? <All.Transaction /> : <Navigate to="/" />
                  }
                />
                <Route
                  exact
                  path="/hashtag"
                  element={
                    isAuthenticated ? <All.HashTag /> : <Navigate to="/" />
                  }
                />
                <Route
                  exact
                  path="/banner"
                  element={isAuthenticated ? <All.Banners /> : <Navigate to="/" />}
                />
                <Route
                  exact
                  path="/add-banner"
                  element={
                    isAuthenticated ? <All.AddBanner /> : <Navigate to="/" />
                  }
                />
                <Route
                  exact
                  path="/edit/:banner_id/banner"
                  element={
                    isAuthenticated ? <All.EditBanner /> : <Navigate to="/" />
                  }
                />
                <Route
                  exact
                  path="/change/password"
                  element={
                    isAuthenticated ? <All.ChangePassword /> : <Navigate to="/" />
                  }
                />
                <Route
                  exact
                  path="/requested/shop-admin"
                  element={
                    isAuthenticated ? <All.RequestedShopAdmin /> : <Navigate to="/" />
                  }
                />

              </Routes>

              {isAuthenticated ? <Footer /> : ""}
            </main>
          </div>
        </Router>
        <ToastContainer />
      </div>
    </>
  );
}

export default App;
