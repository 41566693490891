import React from 'react'
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiTypes, AwsConstants } from '../../constants';
import APICall from '../../networking/AxiousServices';
import { Credentials } from "aws-sdk";
import S3 from "aws-sdk/clients/s3";
import placeholder from "../../Assets/Image/Icon/placeholder.png";
import { Carousel ,Spinner } from 'react-bootstrap';
import SiteLoader from '../../Layout/Loader/SiteLoader';
import { PARLOUR_REQUEST_STATUS } from '../../constants/Enumeration';
import RejectModalParlour from './RejectModalParlour';


export default function RequestedShopAdminView() {

    document.title = "Kalon | Shop Admins Requests";
    let navigate = useNavigate();
    let { req_parlour_id } = useParams();

    const [loading, setLoading] = useState(true);
    const [shopAdmin, setShopAdmin] = useState({});

    const [actionLoading, setActionLoading] = useState(false);
    const [successLoading, setSuccessLoading] = useState(false);
    const [dangerLoading, setDangerLoading] = useState(false);

    const [showModalReject, setShowModalReject] = useState(false);

    const access = new Credentials({
        accessKeyId: AwsConstants.accessKeyId,
        secretAccessKey: AwsConstants.secretAccessKey,
    });

    const s3 = new S3({
        credentials: access,
        region: AwsConstants.region,
        signatureVersion: "v4",
    });


    const getImageUrls = async (response) => {
        let newArray = [];
        for (let i = 0; i < response.parlour_request_pictures.length; i++) {
            if (response.parlour_request_pictures) {
                if (response.parlour_request_pictures.length !== 0) {
                    const url = await s3.getSignedUrlPromise("getObject", {
                        Bucket: AwsConstants.Bucket,
                        Key: `Home/ParlourRequests/${response.parlour_request_id}/${response.parlour_request_pictures[i].display_pic}`,
                        Expires: AwsConstants.signedUrlExpireSeconds,
                    });
                    response.parlour_request_pictures[i]["imageUrl"] = url;
                } else {
                    response.parlour_request_pictures[i]["imageUrl"] = null;
                }
            } else {
                response.parlour_request_pictures[i]["imageUrl"] = null;
            }
        }
        newArray.push(response);
        return newArray;
    };


    const fetchShopAdmin = async () => {
        let res = await APICall(
            ApiTypes.getParlourRequestDetails,
            {
                parlour_request_id: req_parlour_id
            }
        );
        if (res.status === 1) {
            let newArray = await getImageUrls(res.data);
            setShopAdmin(newArray[0]);
            setLoading(false)
            // await getPersonImageUrls(res.data);
        } else {
            setLoading(false)
        }
    };

    useEffect(() => {
        fetchShopAdmin();
    }, []);

    const renderLocation = (value) => {
        let str = value.address_1 +
            ", " +
            (value?.address_2 ? value.address_2 + ", " : "") +
            (value?.city ? value.city + ", " : "") +
            (value.state ? value.state + ", " : "") +
            (value.address_3 ? value.address_3 + ", " : "");
        str = str.trim();
        str = str.slice(0, -1)
        return str;
    }
    const formatDate = (start_time) => {
        if (start_time !== null) {
            return new Date("1970-01-01T" + start_time + "Z").toLocaleTimeString(
                "en-US",
                { timeZone: "UTC", hour12: true, hour: "numeric", minute: "numeric" }
            );
        } else {
            return null;
        }
    };


    const handleAcceptReq = async (status_got , comment) => {
        setActionLoading(true);
        
        let res = await APICall(ApiTypes.parlourRequestStatusUpdate, {
            parlour_request_id: req_parlour_id,
            request_status: status_got,
            comment: comment
        })
        if (res.status === 1) {
           
            setSuccessLoading(false);
            setDangerLoading(false)
            setActionLoading(false);
            fetchShopAdmin()
            setShowModalReject(false)
        }
    }

    return (<>
        <div className="container-fluid mt-4">
            <div className="h4 mb-4">
                <div className="d-flex justify-content-between">
                    <div className="d-flex flex-column justify-content-center">
                        <div className="d-flex breadcrum-navigation-title">
                            <div className="me-2">Shop Admin</div>
                            <div className="me-2 text-muted">|</div>
                            <div className=" d-flex">
                                <div
                                    onClick={() => navigate("/home")}
                                    className="cursor-pointer custom-breadcrum-inactive me-2"
                                >
                                    Home
                                </div>
                                <div className="text-muted me-2">&gt;</div>
                                <div
                                    onClick={() => navigate("/requested/shop-admin")}
                                    className="cursor-pointer custom-breadcrum-inactive me-2"
                                >
                                    Shop Admin Requests
                                </div>
                                <div className="text-muted me-2">&gt;</div>
                                <div className="cursor-pointer  custom-breadcrum-active me-2">
                                    Shop Admin Request
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {!loading ?

                Object.keys(shopAdmin).length ? <>
                    <div className="key">
                        <div className="p-4 my-3 bg-white rounded">
                            <div className="fs-5 d-flex justify-content-between fw-bold">
                                <div className="h5 mb-4">{shopAdmin.parlour_name ? shopAdmin.parlour_name : ""}</div>
                                <div>
                                    {shopAdmin.request_status === PARLOUR_REQUEST_STATUS.PENDING && !actionLoading ? <><button type="Button" onClick={() => {
                                        setShowModalReject(true)
                                    }} className="btn btn-danger w-auto me-2 mb-2">
                                        REJECT
                                    </button>
                                        <button type="button" onClick={() => {
                                            setSuccessLoading(true);
                                            handleAcceptReq( PARLOUR_REQUEST_STATUS.ACCEPTED , "")
                                        }} className="btn btn-success w-auto mb-2">
                                            ACCEPT
                                        </button></> :<> {successLoading &&
                                          <button className="btn btn-success w-auto me-2 mb-2 ">
                                          <Spinner
                                            style={{ height: "20px", width: "20px" }}
                                            className="p-1"
                                            animation="border"
                                            variant="white"
                                          />
                                        </button>}
                                        {dangerLoading &&<button className="btn btn-danger w-auto me-2 mb-2">
                                        <Spinner
                                          style={{ height: "20px", width: "20px" }}
                                          className="p-1"
                                          animation="border"
                                          variant="secondary"
                                        />
                                      </button>}
                                      </> }
                                    {shopAdmin.request_status === PARLOUR_REQUEST_STATUS.REJECTED && <><button disabled={true} type="Button" onClick={() => { }} className="btn btn-danger w-auto me-2 mb-2">
                                        REJECTED
                                    </button>
                                    </>}
                                    {shopAdmin.request_status === PARLOUR_REQUEST_STATUS.ACCEPTED && <><button disabled={true} type="Button" onClick={() => { }} className="btn btn-success w-auto me-2 mb-2">
                                        ACCEPTED
                                    </button>
                                    </>}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-lg-6 col-xl-4 mb-4 mb-lg-0">
                                    <div className="selection-div p-4 bg-white rounded">
                                        <div className="image">
                                            <img
                                                className="socialImage"
                                                src={shopAdmin.parlour_request_pictures.length ? shopAdmin.parlour_request_pictures[0].imageUrl : placeholder}
                                                onError={(e) => {
                                                    e.target.src = placeholder
                                                }}
                                                alt=""
                                            ></img>
                                        </div>

                                        <div className="d-flex gap-2 flex-column">
                                            <div className="d-flex justify-content-center mt-2">
                                                <div className="fw-bold"> {shopAdmin.person_first_name || shopAdmin.person_last_name
                                                    ?
                                                    shopAdmin.person_first_name ? shopAdmin.person_first_name + " " + (shopAdmin.person_last_name ? shopAdmin.person_last_name : "") :
                                                        shopAdmin.person_last_name ? (shopAdmin.person_first_name ? shopAdmin.person_first_name : "") + " " + shopAdmin.person_last_name : ""
                                                    : ""} </div>
                                            </div>
                                            <dl className="mb-0 row">
                                                <dt className="mb-1 h6 col-md-4 col-sm-8 col-lg-6 ">
                                                    Email
                                                </dt>
                                                <dd className="mb-1 text-muted col-sm-12 col-md-8 col-lg-6 wb-keep-a">
                                                    {shopAdmin.parlour_email ? shopAdmin.parlour_email : '-'}
                                                </dd>
                                            </dl>

                                            <dl className="mb-0 row">
                                                <dt className="mb-1 h6 col-md-4 col-sm-8 col-lg-6">
                                                    Moblie No
                                                </dt>
                                                <dd className="mb-1 text-muted col-sm-12 col-md-8 col-lg-6 wb-keep-a">
                                                    {shopAdmin.parlour_mobile ? shopAdmin.parlour_mobile : "-"}
                                                </dd>
                                            </dl>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6 col-xl-8">
                                    <Carousel interval={null}>
                                        {shopAdmin.parlour_request_pictures.map((itm, index) => {
                                            return (
                                                <Carousel.Item key={index}>
                                                    <img
                                                        className="lazy-load-image"
                                                        src={itm.imageUrl}
                                                        alt="First slide"
                                                    />
                                                </Carousel.Item>
                                            );
                                        })}
                                    </Carousel>

                                    <div className=" mt-4 gap-3 d-flex  flex-column">
                                        {shopAdmin.address_1 ? <div>
                                            {/* <label className="fw-bold">Address: </label> */}
                                            <i className="fa-solid fa-location-dot me-2" aria-hidden="true"></i>
                                            <span className="mb-0">
                                                {renderLocation(shopAdmin)}
                                            </span>
                                        </div>
                                            : ""}

                                        <div className="d-flex  gap-4">
                                            <div className="d-flex gap-2">
                                                <div className="h6">Opening: </div>
                                                <div className="mb-0 text-muted">{shopAdmin.start_time ? formatDate(shopAdmin.start_time) : "-"}</div>
                                            </div>
                                            <div className="d-flex gap-2">
                                                <div className="h6">Closing: </div>
                                                <div className="mb-0 text-muted" >{shopAdmin.start_time ? formatDate(shopAdmin.end_time) : "-"}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </> : ""
                : (
                    <SiteLoader />
                )}

<RejectModalParlour
            req_parlour_id={req_parlour_id}
            showModalReject={showModalReject} 
            handleAcceptReq={handleAcceptReq}
            setDangerLoading={setDangerLoading}
            dangerLoading={dangerLoading}
            setShowModalReject={setShowModalReject}
            />
        </div>
    </>)
}
