import React, { useEffect, useState } from "react";
import "react-data-table-component-extensions/dist/index.css";
import { ApiTypes, AwsConstants } from "../../constants";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import ServiceComponent from "../../Layout/Components/ServiceComponent";

export default function Services() {
  document.title = "Kalon | Services";
  const navigate = useNavigate()
  return (
    <div className="container-fluid">
      <div className="row mt-4">
        <div className="col-12">
          <div className="h4 mb-4">
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-column justify-content-center">
                <div className="d-flex breadcrum-navigation-title">
                  <div className="me-2">Services</div>
                  <div className="me-2 text-muted">|</div>
                  <div className=" d-flex">
                    <div
                      onClick={() => navigate("/home")}
                      className="cursor-pointer custom-breadcrum-inactive me-2"
                    >
                      Home
                    </div>
                    <div className="text-muted me-2">&gt;</div>
                    <div className="cursor-pointer custom-breadcrum-active me-2">
                      Services
                    </div>
                  </div>
                </div>
              </div>
              <Button
                onClick={() => {
                  navigate("/add-service");
                }}
                className="ms-2"
                variant="primary"
              >
                Add Service
              </Button>
            </div>
          </div>
          <div className="react-table">
            <div className="table-outer">
              <ServiceComponent APIRoute={ApiTypes.fetchServices} />
              {/* {loading ?<SiteLoader />:""} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
