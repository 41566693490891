import React, { useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import APICall from "../../networking/AxiousServices";
import { ApiTypes, AwsConstants } from "../../constants";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import blank from "../../Assets/Image/Default/blank.png";
import { useNavigate } from "react-router-dom";
import { Credentials, S3 } from "aws-sdk";
import SwitchButton from "../../Layout/Components/SwitchButton";
import CustomTableLoader from "../../Layout/Loader/CustomTableLoader";


export default function Customer() {
  document.title = "Kalon | Customers";
  let navigate = useNavigate();
  const [customers, setCustomer] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(null);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const textInput = useRef(null);
  const [resetCount, setResetCount] = useState(0);
  const handleCustomerCategory = (customer_id) => {
    navigate(`/customer/view/${customer_id}`);
  };

  const access = new Credentials({
    accessKeyId: AwsConstants.accessKeyId,
    secretAccessKey: AwsConstants.secretAccessKey,
  });

  const s3 = new S3({
    credentials: access,
    region: AwsConstants.region,
    signatureVersion: "v4",
  });
  const resetOrders = () => {
    textInput.current.value = "";
    setResetCount(resetCount + 1);
  };

  const changeStatus = async (value, row) => {
    setCustomer((prevState) =>
      prevState.map((mapItem) => {
        if (row.customer_id === mapItem.customer_id) {
          return {
            ...mapItem,
            is_active: value,
          };
        }
        return mapItem;
      })
    );
    let data = { customer_id: row.customer_id, is_active: value };
    let res = await APICall(ApiTypes.changeCustomerStatus, data);
    if (res.status === 1) {
      if (value === false) {
        toast.success("Customer is disabled successfully.");
      } else {
        toast.success("Customer is enabled successfully.");
      }
    }
  };

  const getImageUrls = async (response) => {
    let newArray = [];
    for (let i = 0; i < response.length; i++) {
      const url = await s3.getSignedUrlPromise("getObject", {
        Bucket: AwsConstants.Bucket,
        Key: `Home/Customers/${response[i].customer_id}/${response[i].profile_pic}`,
        Expires: AwsConstants.signedUrlExpireSeconds,
      });
      response[i]["imageUrl"] = url;
      newArray.push(response[i]);
    }
    return newArray;
  };

  const fetchCustomers = async () => {
    setLoading(true);
    const bodyData = {
      search_keyword: textInput.current.value ? textInput.current.value : "",
    };
    let res = await APICall(
      ApiTypes.fetchCustomer,
      bodyData,
      `page=${page - 1}&size=${perPage}`
    );
    if (res.status === 1) {
      res.data.list.forEach((listItem, index) => {
        if (page - 1) {
          listItem["index"] = index + ((page - 1) * perPage);
        } else {
          listItem["index"] = index
        }
      });
      setTotalRows(res.data.totalItems);
      let newArray = await getImageUrls(res.data.list);
      setCustomer(newArray);
      setLoading(false);
    } else if (res.status === 0) {
      toast.error(res.message ? res.message : "Something went wrong.");
      setLoading(false);
    }
  };

  const handlePageChange = (page) => {
    setPage(page);
  };
  const handlePerRowsChange = async (newPerPage, pageGot) => {
    setPerPage(newPerPage);
    setPage(pageGot);
  };

  useEffect(() => {
    fetchCustomers();
  }, [page, perPage, resetCount]);

  let statusColors = {
    PENDING: "#999999",
    CONFIRMED: "#93C47D",
    PROCESSING: "#073763",
    PROCESSED: "#FF9900",
    COMPLETED: "#38761D",
    CANCELLED: "#CC0000",
    REJECTED: "#660000",
    FAILURE: "#CC0000",
    SUCCESS: "#38761D",
  };
  const columns = [
    {
      name: "Sr. No.",
      selector: (row) => row.index + 1,
      sortable: true,
      width: "100px",
    },
    {
      name: "Image",
      selector: (row) =>
        row.imageUrl ? (
          <img className="custom-image" src={row.imageUrl} onError={(e) => { e.target.setAttribute('src', blank) }} alt=""></img>
        ) : (
          <img className="custom-image" src={blank} alt=""></img>
        ),
      width: "100px",
    },
    {
      name: "Full Name",
      selector: (row) => row.full_name,
      sortable: true,
    },
    {
      name: "Mobile No",
      selector: (row) => row.mobile_no,
      sortable: true,
      width: "120px",
    },
    {
      name: "Email",
      selector: (row) => (row.email_id ? row.email_id : "-"),
      sortable: true,
    },
    {
      name: "Is Active",
      selector: (row) => (
        <SwitchButton
          isActive={row.is_active}
          chageStatus={changeStatus}
          row={row}
        />
      ),
      sortable: true,
      width: "100px",
    },
    {
      name: "Action",
      sortable: false,
      selector: (row) => (
        <span
          onClick={() => {
            handleCustomerCategory(row.customer_id);
          }}
          className="eye-icon"
        >
          <i className="fas fa-eye"></i>
        </span>
      ),
      width: "90px",
    },
  ];

  return (
    <div className="container-fluid">
      <div className="row mt-4">
        <div className="col-12">
          <div className="h4 mb-4">
            <div className="d-flex justify-content-between ">
              <div className="d-flex flex-column justify-content-center">
                <div className="d-flex breadcrum-navigation-title">
                  <div className="me-2">Customers</div>
                  <div className="me-2 text-muted">|</div>
                  <div className=" d-flex">
                    <div
                      onClick={() => navigate("/home")}
                      className="cursor-pointer custom-breadcrum-inactive me-2"
                    >
                      Home
                    </div>
                    <div className="text-muted me-2">&gt;</div>
                    <div className="cursor-pointer custom-breadcrum-active me-2">
                      Customers
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="my-4 d-flex justify-content-between">
          <div></div>
          <div>
            <Button
              onClick={() => {
                navigate("/add-category");
              }}
              className="mx-2"
              variant="primary"
            >
              Add Category{" "}
            </Button>
          </div>
        </div> */}
          <div className="d-flex justify-content-between mb-4">
            <div className="d-flex gap-2 col-3 align-items-center">
              <input
                ref={textInput}
                onInput={fetchCustomers}
                type="search"
                className="form-control"
                placeholder="Search..."
              />
            </div>
          </div>

          <div className="d-flex ms-4"></div>

          <div className="table-outer react-table">
            <DataTable
              data={customers}
              columns={columns}
              persistTableHead={true}
              noHeader
              paginationServer
              filter={false}
              print={false}
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              defaultSortField="id"
              defaultSortAsc={true}
              pagination
              highlightOnHover
              responsive={true}
              progressPending={loading}
              progressComponent={<CustomTableLoader />}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
