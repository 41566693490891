import React from 'react'
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiTypes, AwsConstants } from '../../constants';
import APICall from '../../networking/AxiousServices';
import S3 from "aws-sdk/clients/s3";
import { Credentials } from "aws-sdk";
import DataTable from "react-data-table-component";
import blank from "../../Assets/Image/Default/blank.png";
import CustomTableLoader from '../../Layout/Loader/CustomTableLoader';
import { Badges } from '../../Layout/Components/Badges';

export default function RequestedShopAdmin() {

    document.title = "Kalon | Shop Admins Requests";
    let navigate = useNavigate();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const [totalRows, setTotalRows] = useState(null);
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1)

    const [status, setStatus] = useState("");

    const access = new Credentials({
        accessKeyId: AwsConstants.accessKeyId,
        secretAccessKey: AwsConstants.secretAccessKey,
    });


    const s3 = new S3({
        credentials: access,
        region: AwsConstants.region,
        signatureVersion: "v4",
    });
    const handlePageChange = (page) => {
        setPage(page);
    };
    const handlePerRowsChange = async (newPerPage, pageGot) => {
        setPerPage(newPerPage);
        setPage(pageGot);
    };

    const getImageUrls = async (response) => {
        let newArray = [];
        for (let i = 0; i < response.length; i++) {
            if (response[i].request_pictures) {
                if (response[i].request_pictures.length != 0) {
                    const url = await s3.getSignedUrlPromise("getObject", {
                        Bucket: AwsConstants.Bucket,
                        Key: `Home/ParlourRequests/${response[i].parlour_request_id}/${response[i].person_profile_pic}`,
                        Expires: AwsConstants.signedUrlExpireSeconds,
                    });
                    response[i]['imageUrl'] = url;
                }
                else {
                    response[i]['imageUrl'] = null;
                }
            }
            else {
                response[i]['imageUrl'] = null;
            }
            if (page - 1) {
                response[i]["index"] = i + ((page - 1) * perPage);
            } else {
                response[i]["index"] = i
            }
            newArray.push(response[i]);
        }
        return newArray;
    };

    const fetchData = async () => {
        setLoading(true);

        let res = await APICall(ApiTypes.getParlourRequestList, {
            page: page - 1,
            size: perPage,
            parlour_request_status: status
        });
        if (res.status == 1) {
            let newArray = await getImageUrls(res.data.list);
            setTotalRows(res.data.totalItems);
            setData(newArray);
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchData();
    }, [page, perPage]);

    const handleViewParlour = (par_id) => {
        navigate(`/requested/shop-admin/view/${par_id}`)
      }
    const columns = [
        {
            name: "No.",
            selector: (row) => row.index + 1,
            sortable: false,
            width: "80px",
        },
        {
            name: "Image",
            selector: (row) =>
                row.imageUrl ? (
                    <img className="custom-image" src={row.imageUrl} alt=""></img>
                ) : (
                    <img className="custom-image" src={blank} alt="
              "></img>
                ),
            width: "100px"
        },
        {
            name: "Parlour Name",
            selector: (row) => row.parlour_name,
            sortable: true,
        },
        {
            name: "Person  Name",
            selector: (row) => row.person_first_name || row.person_last_name
                ?
                row.person_first_name ? row.person_first_name + " " + (row.person_last_name ? row.person_last_name : "") :
                    row.person_last_name ? (row.person_first_name ? row.person_first_name : "") + " " + row.person_last_name : ""
                : "",
            sortable: true,
        },
        {
            name: "Contacts",
            selector: (row) => (
                <div>
                    <span>{row.parlour_mobile}</span>
                    {/* <span>{row.contactno_2}</span> */}
                </div>
            ),
            sortable: true,
            width: "110px"
        },
        {
            name: "Email",
            selector: (row) => row.parlour_email,
            sortable: true,

        },
        {
            name: "Request Status",
            selector: (row) => (
                
                Badges.ParlourRequestStatusBadges(row.request_status)
                // <span
                //   className="payment-status"
                //   style={{ background: `${statusColors[row.payment_status]}` }}
                // >
                //   {row.payment_status}
                // </span>
            ),
            sortable: true,
            width: "150px",
        },
        {
            name: "Action",
            sortable: false,
            selector: (row) => (
                <>
                    <span
                        onClick={() => {
                              handleViewParlour(row.parlour_request_id);
                        }}
                        className="eye-icon"
                    >
                        <i className="fas fa-eye"></i>
                    </span>

                </>
            ),
            width: "90px"
        },
    ];


    return ( <div className="container-fluid">
    <div className="row mt-4">
        <div className="col-12">
            <div className="h4 mb-4">
                <div className="d-flex justify-content-between">
                    <div className="d-flex flex-column justify-content-center">
                        <div className="d-flex breadcrum-navigation-title">
                            <div className="me-2">Shop Admin Requests</div>
                            <div className="me-2 text-muted">|</div>
                            <div className=" d-flex">
                                <div
                                    onClick={() => navigate("/home")}
                                    className="cursor-pointer custom-breadcrum-inactive me-2"
                                >
                                    Home
                                </div>
                                <div className="text-muted me-2">&gt;</div>
                                <div className="cursor-pointer custom-breadcrum-active me-2">
                                    Shop Admin Requests
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="react-table">
                <div className="table-outer">
                    <DataTable
                        id="main-table"
                        data={data}
                        columns={columns}
                        // expandableRowsComponent={MyExpander}
                        // expandableRows={true}
                        defaultSortField="id"
                        defaultSortAsc={true}
                        pagination
                        highlightOnHover
                        responsive={true}
                        className="myclass"
                        progressPending={loading}
                        persistTableHead={true}
                        paginationServer
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        progressComponent={<CustomTableLoader />}
                    />
                    {/* {loading ?<SiteLoader />:""} */}
                </div>
            </div>
        </div>
    </div>
</div>)
}
