import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApiTypes, AwsConstants } from "../../../constants";
import APICall from "../../../networking/AxiousServices";
import blank from "../../../Assets/Image/Default/blank.png";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import SiteLoader from "../../../Layout/Loader/SiteLoader";
import { Button , Modal} from "react-bootstrap";
import ProfileImage from "./ProfileImage";
import dateFormat from "dateformat";
import CustomTableLoader from "../../../Layout/Loader/CustomTableLoader";

export default function LikeMember({postId ,setPostId , likeModuleShow , setLikeModuleShow}) {
  
  const params = useParams();
  document.title = "Kalon | Likes";
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [like, setLike] = useState([]);
  let navigate = useNavigate();

  const fetchLikeMember = async () => {
    setLoading(true);
    let res = await APICall(
      ApiTypes.fetchLikeMember,
      null,
      `page=${page - 1}&size=${perPage}&post_id=${postId}`
    );
    if (res.status === 1) {
      res.data.list.forEach((listItem, index) => {
        listItem["index"] = index;
      });
      setTotalRows(res.data.totalItems);
      setLike(res.data.list);
      setLoading(false);
    } else if (res.status === 0) {
      toast.error(res.message ? res.message : "Something went wrong.");
    }
  };

  const handlePageChange = (page) => {
    setPage(page);
  };
  const handleClose = ()=>{
    setPostId(null);
    setLikeModuleShow(false)
  }
  const handlePost = (post_id) => {
    navigate(`/post/view/${post_id}`);
  };

  const handlePerRowsChange = async (newPerPage, pageGot) => {
    setPerPage(newPerPage);
    setPage(pageGot);
  };



  useEffect(() => {
    if(postId){
      fetchLikeMember();
    }else{
      setLike([])
    }
  }, [postId , page, perPage]);

  const columns = [
    {
      name: "Sr. No.",
      selector: (row) => row.index + 1,
      sortable: true,
      center: true,
      width: "100px"
    },
    {
      name: "Image",
      selector: (row) => <ProfileImage commentMemberDetails={row} />,
      sortable: true,
      center: true,
      width: "150px"
    },
    {
      name: "Name",
      selector: (row) => row?.display_name,
      sortable: true,
      center: true,
    },
    {
      name: "UserName",
      selector: (row) => row?.member_user_name,
      sortable: true,
      center: true,
    },

    {
      name: "Date",
      selector: (row) =>
        dateFormat(row.commentsDetails[0].updated_on, "yyyy/mm/dd"),
      sortable: true,
      center: true,
    }


  ];

  return (
    <>
    <Modal className="like-model" show={likeModuleShow} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title><i class="fa fa-solid fa-thumbs-up" aria-hidden="true"></i> Likes </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {/* <div className="my-2 h4 m-2 ps-1">
        <div className="d-flex justify-content-between pt-4">
          <div className="d-flex flex-column justify-content-center">
            <div className="d-flex breadcrum-navigation-title">
              <div className="me-2">Post Likes</div>
              <div className="me-5 text-muted">|</div>
              <div className="text-warning d-flex">
                <div
                  onClick={() => navigate("/home")}
                  className="cursor-pointer custom-breadcrum-inactive me-2"
                >
                  Home
                </div>
                <div className="text-muted me-2">&gt;</div>
                <div
                  onClick={() => navigate(-1)}
                  className="cursor-pointer custom-breadcrum-inactive me-2"
                >
                  Posts
                </div>
                <div className="text-muted me-2">&gt;</div>
                <div className="cursor-pointer text-muted custom-breadcrum-active me-2">
                  Post Likes
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="row table-outer m-2">
      
          <DataTable
            data={like}
            columns={columns}
            noHeader
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            defaultSortField="id"
            defaultSortAsc={true}
            pagination
            highlightOnHover
            progressPending={loading}
            progressComponent={<CustomTableLoader />}
            responsive={true}
          />
        
      </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose} variant="secondary" >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      
    </>
  );
}
