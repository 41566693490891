import { Constants } from "../constants";
//Production
const BaseURL = Constants.BaseURL;

const ApiTypes = {
  //Auth
  login: BaseURL + "login",
  //Categories
  fetchCategories: BaseURL + "get_all_category",
  changeCategoryStatus: BaseURL + "active_category",
  fetchActiveCategories: BaseURL + "get_active_category",
  addCategory: BaseURL + "add_category",
  editCategory: BaseURL + "update_category",
  deleteCategory: BaseURL + "delete_category",
  getCategory: BaseURL + "get_category_by_id",
  removeCategoryImage: BaseURL + "remove_category_image",
  updatePassword :  BaseURL + "changePassword",
  //Services
  fetchServices: BaseURL + "get_all_services",
  changeServiceStatus: BaseURL + "active_service",
  deleteService: BaseURL + "delete_service",
  fetchActiveServices: BaseURL + "get_active_services",
  addService: BaseURL + "add_service",
  getService: BaseURL + "get_service_by_id",
  editService: BaseURL + "update_service",
  removeServiceImage: BaseURL + "remove_service_image",
  //Packages
  changePackageStatus: BaseURL + "active_package",
  fetchActivePackages: BaseURL + "get_active_packages",
  fetchPackages: BaseURL + "get_all_package",
  deletePackage: BaseURL + "delete_package",
  addPackage: BaseURL + "add_package",
  removePackageImage: BaseURL + "remove_package_image",
  fetchPackage: BaseURL + "get_package_by_id",
  updatePackage: BaseURL + "update_package",
  //Shop Admins
  // addParlour: BaseURL + "add_parlour",
  addParlour: BaseURL + "add_parlour_new",

  fetchShopAdmins: BaseURL + "get_all_parlour",
  changeParlourStatus: BaseURL + "active_parlour",
  deleteParlour: BaseURL + "delete_parlour",
  fetchParlour: BaseURL + "get_parlour_by_id",
  // updateParlour: BaseURL + "update_parlour",
  updateParlour: BaseURL + "update_parlour_new",
  removeParlourImage: BaseURL + "remove_parlour_image",

  fetchShopAdmimServices : BaseURL + "get_services_by_parlour",
  fetchParlourPackages : BaseURL + "get_package_by_parlour",

  //Admins
  fetchRoles: BaseURL + "get-admin-roles",
  addAdmin: BaseURL + "add-admin",
  fetchAdmins: BaseURL + "fetch-admins",
  changeAdminStatus: BaseURL + "active-admin",
  deleteAdmin: BaseURL + "delete-admin",
  fetchAdmin: BaseURL + "get-admin-by-id",
  updateAdmin: BaseURL + "update-admin",
  checkAdminToken: BaseURL + "check-admin-token",
  setAdminPassword: BaseURL + "set-admin-password",
  sendForgotLink: BaseURL + "send-forgot-link",
  //Orders
  fetchOrders: BaseURL + "get-order-list",
  orderView: BaseURL + "get-order-details",

  //Posts
  fetchPosts: BaseURL + "get_all_post",
  fetchViewPosts: BaseURL + "get_post_by_id",
  fetchComment: BaseURL + "get_comments_by_post_id",
  fetchLikeMember: BaseURL + "get_member_list_who_like_post",
  changePostStatus: BaseURL + "activePost",

  //Hashtag
  fetchHashTag: BaseURL + "get_all_hashtags",

  //Customers
  fetchCustomer: BaseURL + "get_customer_list",
  viewCustomer: BaseURL + "get_customer_by_id",
  changeCustomerStatus: BaseURL + "activeCustomer",

  //Banners
  addBanner: BaseURL + "addBanners",
  updateBanner : BaseURL + "updateBanner",
  fetchBanners : BaseURL + "getBannersByScreen",
  getBanner :  BaseURL + "getBannerById",
  changeBannerStatus : BaseURL + "activeInActiveBanner",
  deleteBanner :  BaseURL + "removeBanner",
  viewPosts: BaseURL + "get_post_by_memberid",

  //Dashboard
  getDashboardCount: BaseURL + "get_dashboard_count",
  dashboardGraphData : BaseURL + 'dashboardGraphData',
  getOrderListByDate : BaseURL + 'getOrderListByDate',

  //News And Blogs
  getNewsAndBlogs : BaseURL + 'get_all_news',
  changeNewsStatus : BaseURL + 'active_inactive_news',

  //Service Requests

  getServiceRequestList : BaseURL + 'getServiceRequests',
  getServiceRequestDetail : BaseURL + 'getServiceRequestDetail',
  removeRequestServiceImage : BaseURL + 'removeRequestServiceImage',
  rejectServiceRequest :  BaseURL + 'updateServiceRequestStatus',


  //Service Requests

  getParlourRequestList : BaseURL + 'getParlourRequestByStatus',
  getParlourRequestDetails : BaseURL + 'getParlourRequestDetail',
  parlourRequestStatusUpdate :  BaseURL + 'updateParlourRequestStatus',
};
export default ApiTypes;
