import React, { useEffect, useState } from "react";
import { ApiTypes, AwsConstants } from "../../constants";
import { Credentials, S3 } from "aws-sdk";
import APICall from "../../networking/AxiousServices";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import "./Customer.css";
import SiteLoader from "../../Layout/Loader/SiteLoader";
import SwitchButton from "../../Layout/Components/SwitchButton";
import blank from "../../Assets/Image/Default/blank.png";
import { Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import placeholder from "../../Assets/Image/Icon/placeholder.png"
import CustomTableLoader from "../../Layout/Loader/CustomTableLoader";

export default function ViewCustomer() {
  const [customers, setCustomer] = useState([]);
  const [loading, setLoading] = useState(false);
  let { customer_id } = useParams();
  const [customerPost, setCustomerPost] = useState([]);
  const [totalRows, setTotalRows] = useState(null);
  const [tableLoading, setTableLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [imgSrc, setImgSrc] = useState();
  let member_id = null;
  let navigate = useNavigate();

  const access = new Credentials({
    accessKeyId: AwsConstants.accessKeyId,
    secretAccessKey: AwsConstants.secretAccessKey,
  });

  const handlePost = (post_id) => {
    navigate(`/post/view/${post_id}`);
  };

  const addDefaultSrc = () => {
    setImgSrc(placeholder)
  }
  const s3 = new S3({
    credentials: access,
    region: AwsConstants.region,
    signatureVersion: "v4",
  });

  const getImageUrls = async (response) => {
    let newArray = [];
    let url;
    if (response.length) {
      for (let i = 0; i < response.length; i++) {
        if (response[i].postMediaDetails) {
          url = await s3.getSignedUrlPromise("getObject", {
            Bucket: AwsConstants.Bucket,
            Key: `Home/SocialMedia/Post/${response[i].post_id}/${response[i].postMediaDetails[0]?.media_name}`,
            Expires: AwsConstants.signedUrlExpireSeconds,
          });
          response[i]["imageUrl"] = url;
        }
      }
      return response;
    } else if (response.customer_id) {
      url = await s3.getSignedUrlPromise("getObject", {
        Bucket: AwsConstants.Bucket,
        Key: `Home/Customers/${response.customer_id}/${response.profile_pic}`,
        Expires: AwsConstants.signedUrlExpireSeconds,
      });
      setImgSrc(url);
      response["imageUrl"] = url;
      newArray.push(response);
    }
    return newArray;
  };

  const fetchPosts = async () => {
    const data = {
      member_id: member_id,
    };
    setTableLoading(true)
    let res = await APICall(
      ApiTypes.viewPosts,
      data,
      `page=${page - 1}&size=${perPage}`
    );

    if (res.status === 1) {
      res.data.list.forEach((listItem, index) => {
        if (page - 1) {
          listItem["index"] = index + ((page - 1) * perPage);
        } else {
          listItem["index"] = index
        }
      });
      setTotalRows(res.data.totalItems);
      let newArray = await getImageUrls(res.data.list);
      setCustomerPost(newArray);
      setLoading(false);
      setTableLoading(false)
    } else if (res.status === 0) {
      toast.error(res.message ? res.message : "Something went wrong.");
      setLoading(false);
      setTableLoading(false)
    }
  };

  const fetchCustomers = async () => {
    setLoading(true);
    const data = {
      customer_id: customer_id,
    };
    let res = await APICall(ApiTypes.viewCustomer, data);
    if (res.status === 1) {
      let newArray = await getImageUrls(res.data);
      setCustomer(newArray);
      if (
        res.data.socialMemberDetails &&
        res.data.socialMemberDetails.member_id
      ) {
        member_id = res.data.socialMemberDetails.member_id;
        fetchPosts();
      }
      else {
        setLoading(false);
      }
    } else if (res.status === 0) {
      toast.error(res.message ? res.message : "Something went wrong.");
      setLoading(false);
    }

  };

  const renderLocation = (value) => {
    let str = value.address_1 +
      ", " +
      (value?.address_2 ? value.address_2 + ", " : "") +
      (value?.city ? value.city + ", " : "") +
      (value.state ? value.state + ", " : "") +
      (value.address_3 ? value.address_3 + ", " : "");
    str = str.trim();
    str = str.slice(0, -1)
    return str;
  }

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage, pageGot) => {
    setPerPage(newPerPage);
    setPage(pageGot);
  };
  useEffect(() => {
    if (member_id) {
      fetchPosts();
    }
  }, [page, perPage]);
  const columns = [
    {
      name: "Sr. No.",
      selector: (row) => row.index + 1,
      sortable: true,
      width: "100px",
    },
    {
      name: "Image",
      selector: (row) =>
        row.imageUrl ? (
          <img className="custom-image" src={row.imageUrl} onError={(e) => { e.target.setAttribute('src', placeholder) }} alt=""></img>
        ) : (
          <img className="custom-image" src={blank} alt=""></img>
        ),
      sortable: true,
      width: "100px",
    },
    {
      name: "Post By",
      selector: (row) => row?.postMemberProfile?.display_name,
      sortable: true,
    },
    {
      name: "Title",
      selector: (row) => row.post_heading,
      sortable: true,
    },
    {
      name: "Reaction",
      selector: (row) => row.total_reactions,
      sortable: true,
      width: "110px",
    },
    {
      name: "Comment",
      selector: (row) => row.total_comments,
      sortable: true,
      width: "110px",
    },
    {
      name: "Is Active",
      selector: (row) => <SwitchButton isActive={row.is_active} row={row} />,
      sortable: true,
      width: "90px",
    },
    {
      name: "Action",
      sortable: false,
      selector: (row) => (
        <>
          <Button
            style={{ marginRight: "4px" }}
            variant="primary"
            onClick={() => {
              handlePost(row.post_id);
            }}
          >
            <i className="fas fa-eye"></i>
          </Button>
          {/* <Button height="24px" width="24px" variant="danger">
            <i className="fa fa-trash" aria-hidden="true"></i>
          </Button> */}
        </>
      ),
      width: "90px",
    },
  ];

  useEffect(() => {
    fetchCustomers();
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row mt-4">
          <div className="col-12">
            <div className="h4 mb-4">
              <div className="d-flex justify-content-between">
                <div className="d-flex flex-column justify-content-center">
                  <div className="d-flex breadcrum-navigation-title">
                    <div className="me-2">Customer</div>
                    <div className="me-2 text-muted">|</div>
                    <div className="d-flex">
                      <div
                        onClick={() => navigate("/home")}
                        className="cursor-pointer custom-breadcrum-inactive me-2"
                      >
                        Home
                      </div>
                      <div className="text-muted me-2">&gt;</div>
                      <div
                        onClick={() => navigate("/customers")}
                        className="cursor-pointer custom-breadcrum-inactive me-2"
                      >
                        Customers
                      </div>
                      <div className="text-muted me-2">&gt;</div>
                      <div className="cursor-pointer custom-breadcrum-active me-2">
                        Customer
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {customers.length > 0 ? (
              customers.map((value, index) => (
                <div key={index}>
                  <div className="py-3 px-3 my-4 bg-body">
                    <div className="row">
                      <div className="col-lg-4 col-md-6 col-sm-12 mb-3 mb-lg-0">
                        <div className="image">
                          <img
                            src={imgSrc}
                            onError={() => addDefaultSrc()}
                            alt=""
                            className="customer-view-image"
                          ></img>
                        </div>
                        <div className="customerName">
                          <p className="mb-0">{value.full_name}</p>
                        </div>
                        <div>
                          {/* <label className="fw-bold">Address: </label> */}
                          {<i class="fa-sharp fa-solid fa-location-dot"></i>}
                          <span className="mb-0 ms-2">
                            {value.address_1 ? renderLocation(value) : "-"}

                          </span>
                        </div>
                        {/* <div className="social-followers d-flex justify-content-center gap-2">
                      <div>
                        <button className="btn btn-primary h-auto w-100">
                          <p className="mb-0"> Followers </p>
                        
                        </button>
                      </div>
                      <div>
                        <button className="btn btn-primary h-auto w-100">
                          <p className="mb-0">  Followings </p>
                       
                        </button>
                      </div>
                    </div> */}
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-12 mb-3 mb-lg-0">
                        <dl className='row mb-1'>
                          <dt className="mb-1 col-md-4 col-sm-8 col-lg-6 font-600">Social Media Id:</dt>
                          <dd className="mb-1 col-sm-12 col-md-8 col-lg-6 text-muted">
                            {value.socialMemberDetails !== null
                              ? value.socialMemberDetails.member_user_name
                              : "-"}
                          </dd>
                        </dl>

                        <dl className='row mb-1'>
                          <dt className="mb-1 col-md-4 col-sm-8 col-lg-6 font-600">Mobile No:</dt>
                          <dd className="mb-1 col-sm-12 col-md-8 col-lg-6 text-muted">
                            {value.mobile_no}
                          </dd>
                        </dl>

                        <dl className='row mb-1'>
                          <dt className="mb-1 col-md-4 col-sm-8 col-lg-6 font-600">Email Address:</dt>
                          <dd className="mb-1 col-sm-12 col-md-8 col-lg-6 text-muted">
                            {value.email_id ? value.email_id : "-"}
                          </dd>
                        </dl>

                        <dl className='row mb-1'>
                          <dt className="mb-1 col-md-4 col-sm-8 col-lg-6 font-600">Gender:</dt>
                          <dd className="mb-1 col-sm-12 col-md-8 col-lg-6 text-muted">
                            {value.gender ? value.gender : "-"}
                          </dd>
                        </dl>
                      </div>

                      <div className="col-lg-4 col-md-6 col-sm-12 mb-3 mb-lg-0">
                        <div >


                          <dl className='row mb-1'>
                            <dt className="mb-1 col-md-4 col-sm-8 col-lg-6 font-600">Member Info:</dt>
                            <dd className="mb-1 col-sm-12 col-md-8 col-lg-6 text-muted">
                              {value.member_info ? value.member_info : "-"}
                            </dd>
                          </dl>

                          <dl className='row mb-1'>
                            <dt className="mb-1 col-md-4 col-sm-8 col-lg-6 font-600">Followers:</dt>
                            <dd className="mb-1 col-sm-12 col-md-8 col-lg-6 text-muted">
                              {value.socialMemberDetails !== null
                                ? value.socialMemberDetails.total_followers
                                : ""}
                            </dd>
                          </dl>

                          <dl className='row mb-1'>
                            <dt className="mb-1 col-md-4 col-sm-8 col-lg-6 font-600">Followings:</dt>
                            <dd className="mb-1 col-sm-12 col-md-8 col-lg-6 text-muted">
                              {value.socialMemberDetails !== null
                                ? value.socialMemberDetails.total_following
                                : ""}
                            </dd>
                          </dl>


                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="my-4 bg-body">
                    <div className="table-outer react-table">
                      {/* {!loading ? ( */}
                      <DataTable
                        data={customerPost}
                        persistTableHead={true}
                        columns={columns}
                        paginationServer
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        defaultSortField="id"
                        defaultSortAsc={true}
                        pagination
                        progressPending={tableLoading}
                        progressComponent={<CustomTableLoader />}
                        highlightOnHover
                        responsive={true}
                      />
                      {/* ) : (
                    <SiteLoader />
                  )} */}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <SiteLoader />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
