import { ApiTypes } from "../constants";
import store from "../store/configureStore";

export default function getRequestBody(
  APITYPE,
  requestData = null,
  queryparam = null,
  cancelToken = null
) {
  const getStoreData = store.getState().data;
  const postApis = [
    ApiTypes.login,
    ApiTypes.changeCategoryStatus,
    ApiTypes.addCategory,
    ApiTypes.editCategory,
    ApiTypes.deleteCategory,
    ApiTypes.changeServiceStatus,
    ApiTypes.addService,
    ApiTypes.deleteService,
    ApiTypes.editService,
    ApiTypes.removeCategoryImage,
    ApiTypes.removeServiceImage,
    ApiTypes.changePackageStatus,
    ApiTypes.deletePackage,
    ApiTypes.addPackage,
    ApiTypes.removePackageImage,
    ApiTypes.changeParlourStatus,
    ApiTypes.updatePackage,
    ApiTypes.deleteParlour,
    ApiTypes.addParlour,
    ApiTypes.updateParlour,
    ApiTypes.removeParlourImage,
    ApiTypes.addAdmin,
    ApiTypes.changeAdminStatus,
    ApiTypes.deleteAdmin,
    ApiTypes.updateAdmin,
    ApiTypes.checkAdminToken,
    ApiTypes.setAdminPassword,
    ApiTypes.sendForgotLink,
    ApiTypes.fetchOrders,
    ApiTypes.fetchCustomer,
    ApiTypes.viewCustomer,
    ApiTypes.viewPosts,
    ApiTypes.fetchCategories,
    ApiTypes.changeCustomerStatus,
    ApiTypes.fetchPosts,
    ApiTypes.fetchShopAdmins,
    ApiTypes.changePostStatus,
    ApiTypes.addBanner,
    ApiTypes.fetchBanners,
    ApiTypes.updateBanner,
    ApiTypes.changeBannerStatus,
    ApiTypes.deleteBanner,
    ApiTypes.updatePassword,
    ApiTypes.dashboardGraphData,
    ApiTypes.getOrderListByDate,
    ApiTypes.getNewsAndBlogs,
    ApiTypes.changeNewsStatus,
    ApiTypes.getServiceRequestList,
    ApiTypes.getServiceRequestDetail,
    ApiTypes.removeRequestServiceImage,
    ApiTypes.rejectServiceRequest,
    ApiTypes.fetchShopAdmimServices,
    ApiTypes.fetchParlourPackages,
    ApiTypes.getParlourRequestList,
    ApiTypes.getParlourRequestDetails,
    ApiTypes.parlourRequestStatusUpdate,
  ];
  const getApis = [
    ApiTypes.fetchActiveCategories,
    ApiTypes.getCategory,
    ApiTypes.fetchServices,
    ApiTypes.fetchActiveServices,
    ApiTypes.getService,
    ApiTypes.fetchPackages,
    ApiTypes.fetchActivePackages,
    ApiTypes.fetchPackage,
    ApiTypes.fetchParlour,
    ApiTypes.fetchRoles,
    ApiTypes.fetchAdmins,
    ApiTypes.fetchAdmin,
    ApiTypes.orderView,
    ApiTypes.fetchViewPosts,
    ApiTypes.fetchComment,
    ApiTypes.fetchHashTag,
    ApiTypes.fetchLikeMember,
    ApiTypes.getDashboardCount,
    ApiTypes.getBanner
  ];
  const getWithTokenApis = [];
  
  if (postApis.includes(APITYPE)) {
    return {
      url: queryparam ? APITYPE + "?" + queryparam : APITYPE,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "post",
      data: requestData,
      cancelToken: cancelToken.token,
    };
  } else if (getApis.includes(APITYPE)) {
    return {
      url: queryparam ? APITYPE + "?" + queryparam : APITYPE,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "get",
      // data: requestData
    };
  } else if (getWithTokenApis.includes(APITYPE)) {
    return {
      url: APITYPE,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getStoreData.userToken}`,
      },
      method: "get",
    };
  }
}
