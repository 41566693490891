import React from 'react'
import ComingSoon from '../Layout/Components/ComingSoon'

export default function Transaction() {
  document.title = "Kalon | Transaction";
  return (
    <div className="container">
      <ComingSoon />
    </div>
  )
}
