import React, { useEffect, useRef, useState } from "react";
import SiteLoader from "../../Layout/Loader/SiteLoader";
import DataTable from "react-data-table-component";
import APICall from "../../networking/AxiousServices";
import { ApiTypes } from "../../constants";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import {
  ORDER_STATUS,
  PAYMENT_MODE,
  PAYMENT_STATUS,
} from "../../constants/Enumeration.js";
import { MultiSelect } from "react-multi-select-component";
import { useNavigate } from "react-router-dom";
import { Badges } from "../../Layout/Components/Badges";
import CustomTableLoader from "../../Layout/Loader/CustomTableLoader";

export default function Orders() {
  document.title = "Kalon | Orders";
  let navigate = useNavigate();
  const [selectedOrderStatus, setSelectedOrderStatus] = useState([]);
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState([]);
  const [selectedPaymentMode, setSelectedPaymentMode] = useState([]);

  const [selectedOrderOptions, setSelectedOrderOptions] = useState([]);
  const [selectedPaymentOptions, setSelectedPaymentOptions] = useState([]);
  const [selectedPaymentModeOptions, setSelectedPaymentModeOptions] = useState(
    []
  );
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(null);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const textInput = useRef(null);
  const [resetCount, setResetCount] = useState(0);
  const handleOrderCategory = (order_id) => {
    navigate(`/order/view/${order_id}`);
  };

  const resetOrders = () => {
    setSelectedOrderStatus([]);
    setSelectedPaymentStatus([]);
    setSelectedPaymentMode([]);
    textInput.current.value = "";
    setResetCount(resetCount + 1);
  };

  const fetchOrders = async () => {
    setLoading(true);
    const bodyData = {
      search_keyword: textInput.current.value ? textInput.current.value : "",
      order_status: selectedOrderStatus
        ? selectedOrderStatus.map((value, index) => {
          return value.value;
        })
        : [],
      payment_mode: selectedPaymentMode
        ? selectedPaymentMode.map((value, index) => {
          return value.value;
        })
        : [],
      payment_status: selectedPaymentStatus
        ? selectedPaymentStatus.map((value, index) => {
          return value.value;
        })
        : [],
    };
    let res = await APICall(
      ApiTypes.fetchOrders,
      bodyData,
      `page=${page - 1}&size=${perPage}`
    );
    if (res.status === 1) {
      res.data.list.forEach((listItem, index) => {
        if (page - 1) {
          listItem["index"] = index + ((page - 1) * perPage);
        } else {
          listItem["index"] = index
        }
      });
      setTotalRows(res.data.totalItems);
      setOrders(res.data.list);
      setLoading(false);
    }
  };

  const handlePageChange = (page) => {
    setPage(page);
  };
  const handlePerRowsChange = async (newPerPage, pageGot) => {
    setPerPage(newPerPage);
    setPage(pageGot);
  };

  useEffect(() => {
    var orderStatusOptions = [];
    var paymentModeOptions = [];
    var paymentStatusOptions = [];
    Object.keys(ORDER_STATUS).map((value, index) => {
      return orderStatusOptions.push({
        label: value,
        value: value,
      });
    });
    setSelectedOrderOptions(orderStatusOptions);

    Object.keys(PAYMENT_MODE).map((value, index) => {
      return paymentModeOptions.push({
        label: value,
        value: value,
      });
    });
    setSelectedPaymentModeOptions(paymentModeOptions);

    Object.keys(PAYMENT_STATUS).map((value, index) => {
      return paymentStatusOptions.push({
        label: value,
        value: value,
      });
    });
    setSelectedPaymentOptions(paymentStatusOptions);

    fetchOrders();
  }, [page, perPage, resetCount]);

  let statusColors = {
    PENDING: "#999999",
    CONFIRMED: "#93C47D",
    PROCESSING: "#073763",
    PROCESSED: "#FF9900",
    COMPLETED: "#38761D",
    CANCELLED: "#CC0000",
    REJECTED: "#660000",
    FAILURE: "#CC0000",
    SUCCESS: "#38761D",
  };
  const columns = [
    {
      name: "Sr. No.",
      selector: (row) => row.index + 1,
      sortable: true,
      width: "100px",
    },
    {
      name: "Parlour Name",
      selector: (row) => row.parlour_details__data.parlour_name,
      sortable: true,
    },
    {
      name: "Customer",
      selector: (row) =>
        row.customer_details_data ? row.customer_details_data.full_name : "",
      sortable: true,
    },
    {
      name: "Order No.",
      selector: (row) => row.order_no,
      sortable: true,
      width: "130px",
    },
    {
      name: "Order Date",
      selector: (row) => row.timeslot_date,
      sortable: true,
      width: "120px",
    },
    {
      name: "Amount",
      selector: (row) => row.final_amount,
      sortable: true,
      width: "120px",
    },
    {
      name: "Order Status",
      selector: (row) => (
        Badges.OrderStatusBadges(row.order_status)
      ),
      sortable: true,
      width: "130px",
    },
    {
      name: "Payment Mode",
      selector: (row) => row.payment_mode,
      sortable: true,
      width: "150px",
    },
    {
      name: "Payment Status",
      selector: (row) => (
        Badges.PayMentStatusBadges(row.payment_status)
        // <span
        //   className="payment-status"
        //   style={{ background: `${statusColors[row.payment_status]}` }}
        // >
        //   {row.payment_status}
        // </span>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "Action",
      sortable: false,
      selector: (row) => (
        <span
          onClick={() => {
            handleOrderCategory(row.order_id);
          }}
          className="eye-icon"
        >
          <i className="fas fa-eye"></i>
        </span>
      ),
      width: "90px",
    },
  ];

  return (
    <div className="container-fluid">
      <div className="row mt-4">
        <div className="col-12">
          <div className="h4 mb-4">
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-column justify-content-center">
                <div className="d-flex breadcrum-navigation-title">
                  <div className="me-2">Orders</div>
                  <div className="me-2 text-muted">|</div>
                  <div className=" d-flex">
                    <div
                      onClick={() => navigate("/home")}
                      className="cursor-pointer custom-breadcrum-inactive me-2"
                    >
                      Home
                    </div>
                    <div className="text-muted me-2">&gt;</div>
                    <div className="cursor-pointer custom-breadcrum-active me-2">
                      Orders
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-4">
            <div className="mb-4">
              <div className="row">
                <div className="col-md-12 col-lg-4 col-xl-4">
                  <div className="row">
                    <div className="col-12 col-lg-9">
                      <input
                        ref={textInput}
                        onInput={fetchOrders}
                        type="search"
                        className="form-control"
                        placeholder="Search..."
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-8 col-xl-8 mt-3 mt-lg-0">
                  <div className="d-flex justify-content-end">
                    <MultiSelect
                      className="w-50"
                      options={selectedOrderOptions}
                      value={selectedOrderStatus}
                      onChange={setSelectedOrderStatus}
                      labelledBy="order"
                      valueRenderer={(selected, _options) => {
                        return selected.length !== 0
                          ? "Selected item " + selected.length
                          : "Order Status";
                      }}
                    />

                    <MultiSelect
                      className="w-50  mx-2"
                      options={selectedPaymentOptions}
                      value={selectedPaymentStatus}
                      onChange={setSelectedPaymentStatus}
                      labelledBy="Select"
                      valueRenderer={(selected, _options) => {
                        return selected.length !== 0
                          ? "Selected item " + selected.length
                          : "Payment Options";
                      }}
                    />

                    <MultiSelect
                      className="w-50"
                      options={selectedPaymentModeOptions}
                      value={selectedPaymentMode}
                      onChange={setSelectedPaymentMode}
                      labelledBy="Select"
                      valueRenderer={(selected, _options) => {
                        return selected.length !== 0
                          ? "Selected item " + selected.length
                          : "Payment Mode";
                      }}
                    />
                    <div className="d-flex ms-2">
                      <Button color="primary" className="me-2" onClick={fetchOrders}>
                        <i className="fa-solid fa-check"></i>
                      </Button>

                      <Button
                        color="primary"
                        onClick={() => {
                          resetOrders();
                        }}
                      >
                        <i className="fa fa-refresh" aria-hidden="true"></i>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex ms-4"></div>
          <div className="table-outer react-table">
            {/* {!loading ? ( */}
            <DataTable
              data={orders}
              columns={columns}
              noHeader
              paginationServer
              persistTableHead={true}
              filter={false}
              print={false}
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              defaultSortField="id"
              defaultSortAsc={true}
              pagination
              progressPending={loading}
              highlightOnHover
              progressComponent={<CustomTableLoader />}
              responsive={true}
            />
            {/* ) : (
          <SiteLoader />
        )} */}
          </div>
        </div>
      </div>
    </div>
  );
};
