import { Credentials, S3 } from "aws-sdk";
import React, { useEffect, useState } from "react";
import { AwsConstants } from "../../../constants";
import blank from "../../../Assets/Image/Default/blank.png";

export default function ProfileImage({ commentMemberDetails }) {
  const [imageUrl, setImageUrl] = useState("");

  const access = new Credentials({
    accessKeyId: AwsConstants.accessKeyId,
    secretAccessKey: AwsConstants.secretAccessKey,
  });

  const s3 = new S3({
    credentials: access,
    region: AwsConstants.region,
    signatureVersion: "v4",
  });

  useEffect(() => {
    getCommentCustomerImageUrl(commentMemberDetails);
  }, []);

  const getCommentCustomerImageUrl = async (commentMemberDetails) => {
    try {
      if (commentMemberDetails.customer_id) {
        const url = await s3.getSignedUrlPromise("getObject", {
          Bucket: AwsConstants.Bucket,
          Key: `Home/Customers/${commentMemberDetails.customer_id}/${commentMemberDetails.customerMasterDetails.profile_pic}`,
          Expires: AwsConstants.signedUrlExpireSeconds,
        });
        setImageUrl(url);
      } else if (commentMemberDetails.parlour_id) {
        const url = await s3.getSignedUrlPromise("getObject", {
          Bucket: AwsConstants.Bucket,
          Key: `Home/Parlours/${commentMemberDetails.parlour_id}/Profile/${commentMemberDetails.parlorsMasterDetails.person_profile_pic}`,
          Expires: AwsConstants.signedUrlExpireSeconds,
        });
        setImageUrl(url);
      } else {
        setImageUrl(blank);
      }
    } catch {
      setImageUrl(blank);
    }
  };

  return <img className="custom-image" src={imageUrl} alt=""></img>;
}
