import React from "react";
import dateFormat from "dateformat";
import ProfileImage from "./ProfileImage";
import { useNavigate } from "react-router-dom";

function PostDetail({ likeList, postData }) {
  let navigate = useNavigate();

  return (
    <>
      <div className="col-lg-4 col-xs-10 mx-auto gap-2 bg-white pt-3 pb-3 border-second-top">
        <div className="d-flex flex-column gap-2">
          <div className="d-flex gap-2 align-items-center mt-2">
            {postData.postMemberProfile?.customerMasterDetails ? (
              <div>
                <ProfileImage
                  commentMemberDetails={postData?.postMemberProfile}
                />
              </div>
            ) : (
              ""
            )}

            <div className="font-600">{postData?.postMemberProfile?.display_name}</div>
          </div>
          <div className="d-flex gap-3">
            <div className="d-flex gap-2 align-items-center">
              <i className="fa-solid fa-calendar-day"></i>
              <div>{dateFormat(postData?.updated_on, "mmm d, yyyy")}</div>
            </div>
            <div className="d-flex gap-2 align-items-center">
              <i className="fa-solid fa-clock"></i>
              <div>{dateFormat(postData?.updated_on, " h:MM TT")}</div>
            </div>
          </div>

          <div className="d-flex gap-2 align-items-center">
            <button
              type="button"
              className="btn btn-primary w-50 text-start"
              disabled={postData?.total_reactions === 0}
              onClick={() => likeList(postData.post_id)}
            >
              <i className="fa-solid fa-thumbs-up pe-2"></i>{" "}
              {postData?.total_reactions}
            </button>

            <button type="button"
              disabled={postData?.total_comments === 0}
              className="btn btn-primary w-50 text-start">
              <i className="fa-solid fa-comment pe-2"></i>
              {postData?.total_comments}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default PostDetail;
