import React, { useEffect, useState } from "react";
import SiteLoader from "../../Layout/Loader/SiteLoader";
import DataTable from "react-data-table-component";
import "react-data-table-component-extensions/dist/index.css";
import APICall from "../../networking/AxiousServices";
import { ApiTypes, AwsConstants } from "../../constants";
import { Button } from "react-bootstrap";
import SwitchButton from "../../Layout/Components/SwitchButton";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import S3 from "aws-sdk/clients/s3";
import { Credentials } from "aws-sdk";
import Swal from "sweetalert2";
import blank from "../../Assets/Image/Default/blank.png";
import CustomTableLoader from "../../Layout/Loader/CustomTableLoader";
import { SERVICE_TYPE } from "../../constants/Enumeration";

export default function ServiceComponent({ data = null, APIRoute }) {

    let navigate = useNavigate();
    const [services, setServices] = useState([]);
    const [loading, setLoading] = useState(false);

    const access = new Credentials({
        accessKeyId: AwsConstants.accessKeyId,
        secretAccessKey: AwsConstants.secretAccessKey,
    });

    const s3 = new S3({
        credentials: access,
        region: AwsConstants.region,
        signatureVersion: "v4",
    });

    const chageStatus = async (value, row) => {
        // setServices((prevState) => prevState.map((mapItem) => ({
        //   ...mapItem,
        //   refServices: mapItem.refServices.map((mapItemInner) => {
        //     if (row.category_service_id == mapItemInner.category_service_id) {
        //       return {
        //         ...mapItemInner,
        //         is_active: value
        //       }

        //     } else {
        //       return {
        //         ...mapItemInner,
        //         is_active: false
        //       };
        //     }
        //   })
        // })))
        if (row.parlour_id !== null && row.package_name && !value) {
            Swal.fire({
                title: "Service cannot be disabled.",
                text: `Please unbind all services from packages ${row.package_name}!`,
                icon: "warning",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Cancel",
            })
        } else {
            let data = {
                category_service_id: row.category_service_id,
                is_active: value,
            };
            let res = await APICall(ApiTypes.changeServiceStatus, data);
            if (res.status == 1) {
                if (value == false) {
                    toast.success("Service is disabled successfully.");
                } else {
                    toast.success("Service is enabled successfully.");
                }
                fetchServices();
            }
        }

    };

    const getImageUrls = async (response) => {
        let newArray = [];
        for (let i = 0; i < response.length; i++) {
            // let refServices = response[i].refServices;
            // if (refServices[0].categoryServicePictureDetails) {
            //   if (refServices[0].categoryServicePictureDetails.length != 0) {
            //     const url = await s3.getSignedUrlPromise("getObject", {
            //       Bucket: AwsConstants.Bucket,
            //       Key: `Home/Services/${refServices[0].category_service_id}/${refServices[0].categoryServicePictureDetails[0].display_pic}`,
            //       Expires: AwsConstants.signedUrlExpireSeconds,
            //     });
            //     refServices[0]["imageUrl"] = url;
            //   } else {
            //     refServices[0]["imageUrl"] = null;
            //   }
            // } else {
            //   refServices[0]["imageUrl"] = null;
            // }
            // refServices[0]["index"] = i;


            if (response[i].categoryServicePictureDetails) {
                if (response[i].categoryServicePictureDetails.length != 0) {
                    const url = await s3.getSignedUrlPromise("getObject", {
                        Bucket: AwsConstants.Bucket,
                        Key: `Home/Services/${response[i].category_service_id}/${response[i].categoryServicePictureDetails[0].display_pic}`,
                        Expires: AwsConstants.signedUrlExpireSeconds,
                    });
                    response[i]['imageUrl'] = url;
                }
                else {
                    response[i]['imageUrl'] = null;
                }
            }
            else {
                response[i]['imageUrl'] = null;
            }
            response[i]["index"] = i;

            // for (let j = 0; j < refServices.length; j++) {

            // }

            newArray.push(response[i]);
        }
        return newArray;
    };

    const fetchServices = async () => {
        setLoading(true);
        let res = null;
        if (data) {
            res = await APICall(APIRoute, data);
        } else {
            res = await APICall(APIRoute);
        }
        if (res.status == 1) {
            let newArray = await getImageUrls(res.data);
            setServices(newArray);
            setLoading(false);
        }
        else if (res.status === 0) {
            // toast.error(res.message ? res.message : "Something went wrong.");
            setLoading(false)
        }
    };
    const handleEditService = (ser_id) => {
        navigate(`/edit/${ser_id}/service`);
    };

    const handleDeleteService = (ser_id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                let res = await APICall(ApiTypes.deleteService, {
                    category_service_id: ser_id,
                });
                if (res) {
                    if (res.status == 1) {
                        toast.success("Service deleted successfully.");
                        fetchServices();
                    }
                }
            }
        });
    };

    useEffect(() => {
        fetchServices();
    }, []);

    // const InnerColumns = [
    //   {
    //     name: "Sr. No.",
    //     selector: (row) => row.index + 1,
    //     sortable: true,
    //     width: "90px",
    //   },
    //   {
    //     name: "Service Name",
    //     selector: (row) => row.service_name,
    //     sortable: true,
    //     width: "200px",
    //   },
    //   {
    //     name: "Category",
    //     selector: (row) => row.categoryDetails.category_name,
    //     sortable: true,
    //   },
    //   {
    //     name: "Service Details",
    //     selector: (row) => row.service_details,
    //     sortable: true,
    //   },
    //   {
    //     name: "Price",
    //     selector: (row) => (
    //       <span>
    //         {" "}
    //         <i className="fa fa-inr" aria-hidden="true"></i> {row.service_price}
    //       </span>
    //     ),
    //     sortable: true,
    //     width: "100px",
    //   },
    //   {
    //     name: "Discount",
    //     selector: (row) => (
    //       <span>
    //         <i className="fa fa-inr" aria-hidden="true"></i>{" "}
    //         {row.service_discount}{" "}
    //       </span>
    //     ),
    //     sortable: true,
    //     width: "100px",
    //   },
    //   {
    //     name: "Time (Min)",
    //     selector: (row) => row.time_in_min,
    //     sortable: true,
    //     width: "130px",
    //   },
    //   {
    //     name: "Image",
    //     selector: (row) =>
    //       row.imageUrl ? (
    //         <img className="custom-image" src={row.imageUrl}></img>
    //       ) : (
    //         <img className="custom-image" src={blank}></img>
    //       ),
    //     sortable: true,
    //     width: "100px",
    //   },
    //   {
    //     name: "Status",
    //     selector: (row) => (
    //       <SwitchButton
    //         isActive={row.is_active}
    //         chageStatus={chageStatus}
    //         row={row}
    //       />
    //     ),
    //     sortable: true,
    //     width: "100px",
    //   },
    //   {
    //     name: "Action",
    //     sortable: false,
    //     selector: (row) => (
    //       <>
    //         <Button
    //           style={{ marginRight: "4px" }}
    //           onClick={() => {
    //             handleEditService(row.category_service_id);
    //           }}
    //           variant="primary"
    //         >
    //           <i className="fas fa-edit"></i>
    //         </Button>
    //         <Button
    //           onClick={() => {
    //             handleDeleteService(row.category_service_id);
    //           }}
    //           height="24px"
    //           width="24px"
    //           variant="danger"
    //         >
    //           <i className="fa fa-trash" aria-hidden="true"></i>
    //         </Button>
    //       </>
    //     ),
    //   },
    // ];
    const columns = [
        {
            name: "Sr. No.",
            selector: (row) => row.index + 1,
            sortable: true,
            width: "90px",
        },
        {
            name: "Service Name",
            selector: (row) => row.service_name,
            sortable: true,
        },
        {
            name: "Service Details",
            selector: (row) => row.service_details,
            sortable: true,
        },
        {
            name: "Service Type",
            selector: (row) => SERVICE_TYPE[row.category_service_type],
            sortable: true,
        },
        {
            name: "Category Name",
            selector: (row) => row.categoryDetails.category_name ? row.categoryDetails.category_name : "",
            sortable: true,
        },
        {
            name: "Price",
            selector: row => <span> <i className="fa fa-inr" aria-hidden="true"></i> {row.service_price}</span>,
            sortable: true,
            width: "130px"
        },
        {
            name: "Discount",
            selector: row => <span><i className="fa fa-inr" aria-hidden="true"></i> {row.service_discount} </span>,
            sortable: true,
            width: "130px"
        },
        {
            name: "Time (Min)",
            selector: row => row.time_in_min,
            sortable: true,
            width: "130px"
        },
        {
            name: "Image",
            selector: (row) => row.imageUrl ? <img className="custom-image" src={row.imageUrl}></img> : <img className="custom-image" src={blank}></img>,
            sortable: true,
            width: "100px"
        },
        {
            name: "Status",
            selector: row => <SwitchButton isActive={row.is_active} chageStatus={chageStatus} row={row} />,
            sortable: true,
            width: "90px"
        },
        {
            name: "Action",
            sortable: false,
            omit : ApiTypes.fetchShopAdmimServices === APIRoute ? true : false,
            width: "90px",
            selector: row => <span>{row.is_active ? <span onClick={() => { handleEditService(row.category_service_id) }} className="edit-icon"><i className="fas fa-edit"></i></span> : ""}
                <span onClick={() => { handleDeleteService(row.category_service_id) }} className="trash-icon ms-2"><i className="fa fa-trash" aria-hidden="true"></i></span></span>,
        }
    ];
    // const MyExpander = (props) => (
    //   <div>
    //     <DataTable
    //       data={props.data.refServices}
    //       columns={InnerColumns}
    //       theme="dark"
    //       defaultSortField="id"
    //       defaultSortAsc={true}
    //       highlightOnHover
    //       responsive={true}
    //     />
    //   </div>
    // );


    return (<DataTable
        id="main-table"
        data={services}
        columns={columns}
        // expandableRowsComponent={MyExpander}
        // expandableRows={true}
        defaultSortField="id"
        defaultSortAsc={true}
        pagination
        highlightOnHover
        responsive={true}
        className="myclass"
        progressPending={loading}
        persistTableHead={true}
        progressComponent={<CustomTableLoader />}
    />)
}
