import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";
import getRequestBody from "./GetRequestBody";

let cancelTokensHashMap = new Map();
export default async function APICall(
  APITYPE,
  requestData = null,
  queryparam = null
) {
  try {
    if (cancelTokensHashMap.has(APITYPE)) {
      cancelTokensHashMap.get(APITYPE).cancel();
      cancelTokensHashMap.delete(APITYPE);
    }
    let cancelToken = axios.CancelToken.source();
    cancelTokensHashMap.set(APITYPE, cancelToken);

    const response = await axios(
      getRequestBody(APITYPE, requestData, queryparam, cancelToken)
    );

    return response.data;
  } catch (error) {
    if (error.response) {
      if (error.response.status == 500) toast.error("Internal server error.");
      else if (error.response.status == 400) {
        toast.error("Please check form validation.");
      } else if (error.response.status == 404) {
        toast.error("Not found");
      } else if (error.response.status == 401) {
        toast.error("You are unauthorized.");
      } else if (error.response.status == 403) {
        toast.error("Access denied for this request.");
      }
    }
    console.log("error", error);
    toast.error(error);
    return false;
  }
}
