import React, { useState } from 'react'

export default function InputTextPass({ validation, title, id, value, onChange, placeholder }) {

    const [passHide, setPassHide] = useState(true);

    return (
        <div className="row mt-4 change-password-row">
            <label
                className="col-4"
                htmlFor={id}
            >
                {title} <span className="validation">*</span>
            </label>
            <div className="col-8 ">
                <div className="position-relative">
                    <input
                        className="form-control"
                        type={passHide ? "password" : "text"}
                        value={value}
                        onChange={(e) => {
                            if (e.target.value) {
                                onChange(e.target.value)
                            } else {
                                onChange("")
                            }
                        }}
                        id={id}
                        placeholder={placeholder}
                    />
                    {passHide ? (
                        <i
                            onClick={() => setPassHide(false)}
                            className="fa fa-eye eye-custom"
                            aria-hidden="true"
                        ></i>
                    ) : (
                        <i
                            onClick={() => setPassHide(true)}
                            className="fa fa-eye-slash eye-custom"
                            aria-hidden="true"
                        ></i>
                    )}
                </div>
                {validation != "" ? (
                    <div className="validation">{validation}</div>
                ) : (
                    ""
                )}
            </div>
        </div>
    );
};
