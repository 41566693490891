import React, { useEffect, useState } from "react";
import SiteLoader from "../../Layout/Loader/SiteLoader";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import APICall from "../../networking/AxiousServices";
import { ApiTypes, AwsConstants } from "../../constants";
import { Button } from "react-bootstrap";
import SwitchButton from "../../Layout/Components/SwitchButton";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import S3 from "aws-sdk/clients/s3";
import { Credentials } from "aws-sdk";
import Swal from "sweetalert2";
import blank from "../../Assets/Image/Default/blank.png";
import CustomTableLoader from "../../Layout/Loader/CustomTableLoader";
export default function ShopAdminPackages({ parlour_id }) {

    let navigate = useNavigate();

    const [totalRows, setTotalRows] = useState(null);
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1);

    const [packages, setPackages] = useState([]);
    const [loading, setLoading] = useState(false);

    const access = new Credentials({
        accessKeyId: AwsConstants.accessKeyId,
        secretAccessKey: AwsConstants.secretAccessKey,
    });

    const s3 = new S3({
        credentials: access,
        region: AwsConstants.region,
        signatureVersion: "v4",
    });

    const chageStatus = async (value, row) => {
        // setPackages((prevState) => prevState.map((mapItem) => {
        //   if (row.package_id == mapItem.package_id) {
        //     return {
        //       ...mapItem,
        //       is_active: value
        //     }
        //   }
        //   return mapItem
        // }))
        let data = { package_id: row.package_id, is_active: value };
        let res = await APICall(ApiTypes.changePackageStatus, data);
        if (res.status == 1) {
            if (value == false) {
                toast.success("Package is disabled successfully.");
            } else {
                toast.success("Package is enabled successfully.");
            }
        }
        fetchPackages();
    };

    const handlePageChange = (page) => {
        setPage(page);
    };
    const handlePerRowsChange = async (newPerPage, pageGot) => {
        setPerPage(newPerPage);
        setPage(pageGot);
    };

    useEffect(() => {
        fetchPackages();
    }, [page, perPage]);

    const getImageUrls = async (response) => {
        let newArray = [];
        for (let i = 0; i < response.length; i++) {

            if (response[i].packagePicturesDetails) {
                if (response[i].packagePicturesDetails.length != 0) {
                    const url = await s3.getSignedUrlPromise("getObject", {
                        Bucket: AwsConstants.Bucket,
                        Key: `Home/Packages/${response[i].package_id}/${response[i].packagePicturesDetails[0].display_pic}`,
                        Expires: AwsConstants.signedUrlExpireSeconds,
                    });
                    response[i]['imageUrl'] = url;
                }
                else {
                    response[i]['imageUrl'] = null;
                }
            }
            else {
                response[i]['imageUrl'] = null;
            }
            if (page - 1) {
                response[i]["index"] = i + ((page - 1) * perPage);
            } else {
                response[i]["index"] = i
            }

            // let refPackages = response[i].refPackages;
            // // for (let j = 0; j < refPackages.length; j++) {
            //   if (refPackages[0].packagePictures) {
            //     if (refPackages[0].packagePictures.length != 0) {
            //       const url = await s3.getSignedUrlPromise("getObject", {
            //         Bucket: AwsConstants.Bucket,
            //         Key: `Home/Packages/${refPackages[0].package_id}/${refPackages[0].packagePictures[0].display_pic}`,
            //         Expires: AwsConstants.signedUrlExpireSeconds,
            //       });
            //       refPackages[0]["imageUrl"] = url;
            //     } else {
            //       refPackages[0]["imageUrl"] = null;
            //     }
            //   } else {
            //     refPackages[0]["imageUrl"] = null;
            //   }
            //   refPackages[0]["index"] = i;
            // // }

            newArray.push(response[i]);

        }


        return newArray;
    };

    const fetchPackages = async () => {
        setLoading(true);
        let res = await APICall(ApiTypes.fetchParlourPackages, {
            parlour_id: parlour_id
        });
        if (res.status == 1) {
            let newArray = await getImageUrls(res.data.list);
            setTotalRows(res.data.totalItems);
            setPackages(newArray);
            setLoading(false);
        }

    };
    const handleEditPackage = (pac_id) => {
        navigate(`/edit/${pac_id}/package`);
    };

    const handleDeletePackage = (pack_id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                let res = await APICall(ApiTypes.deletePackage, {
                    package_id: pack_id,
                });
                if (res) {
                    if (res.status == 1) {
                        toast.success("Package deleted successfully.");

                        fetchPackages();
                    }
                }
            }
        });
    };

    useEffect(() => {
        fetchPackages();
    }, []);

    // const InnerColumns = [
    //   {
    //     name: "Sr. No.",
    //     selector: (row) => row.index + 1,
    //     sortable: true,
    //     width: "90px",
    //   },
    //   {
    //     name: "Package Name",
    //     selector: (row) => row.package_name,
    //     sortable: true,
    //   },
    //   {
    //     name: "Package Details",
    //     selector: (row) => row.details,
    //     sortable: true,
    //   },
    //   {
    //     name: "Package Type",
    //     selector: (row) =>
    //       row.is_multi_day ? <span>Bridal</span> : <span>Normal</span>,
    //     sortable: true,
    //   },
    //   {
    //     name: "Package Price",
    //     selector: (row) => (
    //       <span>
    //         <i className="fa fa-inr" aria-hidden="true"></i> {row.package_price}{" "}
    //       </span>
    //     ),
    //     sortable: true,
    //   },
    //   {
    //     name: "Package Discount",
    //     selector: (row) => (
    //       <span>
    //         {" "}
    //         <i className="fa fa-inr" aria-hidden="true"></i>{" "}
    //         {row.package_discount}
    //       </span>
    //     ),
    //     sortable: true,
    //   },
    //   // {
    //   //   name: "Package Time (Min)",
    //   //   selector: row => row.time_in_min,
    //   //   sortable: true,
    //   // },
    //   {
    //     name: "Image",
    //     selector: (row) =>
    //       row.imageUrl ? (
    //         <img className="custom-image" src={row.imageUrl}></img>
    //       ) : (
    //         <img className="custom-image" src={blank}></img>
    //       ),
    //     sortable: true,
    //   },
    //   {
    //     name: "Status",
    //     selector: (row) => (
    //       <SwitchButton
    //         isActive={row.is_active}
    //         chageStatus={chageStatus}
    //         row={row}
    //       />
    //     ),
    //     sortable: true,
    //   },
    //   {
    //     name: "Action",
    //     sortable: false,
    //     selector: (row) => (
    //       <>
    //         <Button
    //           style={{ marginRight: "4px" }}
    //           onClick={() => {
    //             handleEditPackage(row.package_id);
    //           }}
    //           variant="primary"
    //         >
    //           <i className="fas fa-edit"></i>
    //         </Button>
    //         <Button
    //           onClick={() => {
    //             handleDeletePackage(row.package_id);
    //           }}
    //           height="24px"
    //           width="24px"
    //           variant="danger"
    //         >
    //           <i className="fa fa-trash" aria-hidden="true"></i>
    //         </Button>
    //       </>
    //     ),
    //   },
    // ];

    const columns = [
        {
            name: "Sr. No.",
            selector: (row) => row.index + 1,
            sortable: true,
            width: "100px",
        },
        {
            name: "Package Name",
            selector: (row) => row.package_name,
            sortable: true,
        },
        {
            name: "Package Details",
            selector: (row) => row.details,
            sortable: true,
            width: "400px",
        },
        {
            name: "Package Type",
            selector: row => row.is_multi_day ? <span>Bridal</span> : <span>Normal</span>,
            sortable: true,
            width: "150px",
        },
        {
            name: "Package Price",
            selector: row => <span><i className="fa fa-inr" aria-hidden="true"></i> {row.package_price} </span>,
            sortable: true,
            width: "140px",
        },
        {
            name: "Package Discount",
            selector: row => <span> <i className="fa fa-inr" aria-hidden="true"></i> {row.package_discount}</span>,
            sortable: true,
            width: "170px",
        },
        {
            name: "Package Time (Min)",
            selector: row => row.time_in_min,
            sortable: true,
            width: "200px",
        },
        {
            name: "Image",
            selector: (row) => row.imageUrl ? <img className="custom-image" src={row.imageUrl}></img> : <img className="custom-image" src={blank}></img>,
            sortable: true,
            width: "100px",
        },
        {
            name: "Status",
            selector: row => <SwitchButton isActive={row.is_active} chageStatus={chageStatus} row={row} />,
            sortable: true,
            width: "90px",
        },
        {
            name: "Action",
            sortable: false,
            selector: row => <>{row.is_active ? <span onClick={() => { handleEditPackage(row.package_id) }} className="edit-icon" ><i className="fas fa-edit"></i></span> : ""}

                {/* <Button onClick={() => { handleDeletePackage(row.package_id) }} height="24px" width="24px" variant="danger" ><i className="fa fa-trash" aria-hidden="true"></i></Button> */}
            </>,
            width: "90px",
        }
    ];

    // const MyExpander = (props) => (
    //   <div>
    //     <DataTable
    //       data={props.data.refPackages}
    //       columns={InnerColumns}
    //       persistTableHead={true}
    //       theme="dark"
    //       defaultSortField="id"
    //       defaultSortAsc={true}
    //       highlightOnHover
    //       responsive={true}
    //     />
    //   </div>
    // );

    return (

        <div className="py-1 my-4 m-auto bg-body rounded">
            <div className="table-outer react-table">
                <DataTable
                    data={packages}
                    columns={columns}
                    noHeader
                    persistTableHead={true}
                    defaultSortField="id"
                    defaultSortAsc={true}
                    pagination
                    highlightOnHover
                    responsive={true}
                    progressPending={loading}
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    progressComponent={<CustomTableLoader />}
                />
            </div>
        </div>)
}
