import React, { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { ApiTypes, AwsConstants } from "../../../constants";
import APICall from "../../../networking/AxiousServices";
import SiteLoader from "../../../Layout/Loader/SiteLoader";
import S3 from "aws-sdk/clients/s3";
import { Credentials } from "aws-sdk";
import InfiniteScroll from "react-infinite-scroll-component";
import PostDiscription from "./PostDiscription";
import PostDetail from "./PostDetail";
import Loader from "./Loader";
import CommentModule from "./comment/CommentModule";
import LikeMember from "./likeMember";

function ViewPost() {
  document.title = "Kalon | Post";
  const [postData, setPostData] = useState({});
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [imageUrls, setImageUrls] = useState([]);
  const [postId, setPostId] = useState(null);
  const [likeModuleShow, setLikeModuleShow] = useState(false);
  let navigate = useNavigate();

  const [hasMore, setHasMore] = useState(false);
  const access = new Credentials({
    accessKeyId: AwsConstants.accessKeyId,
    secretAccessKey: AwsConstants.secretAccessKey,
  });

  const s3 = new S3({
    credentials: access,
    region: AwsConstants.region,
    signatureVersion: "v4",
  });

  const getImageUrls = (response) => {
    let newArray = [];
    response?.postMediaDetails.forEach(async (value, index) => {
      const url = await s3.getSignedUrlPromise("getObject", {
        Bucket: AwsConstants.Bucket,
        Key: `Home/SocialMedia/Post/${value.post_id}/${value.media_name}`,
        Expires: AwsConstants.signedUrlExpireSeconds,
      });
      newArray = [...newArray, url];
      setImageUrls(newArray);
    });
  };

  let { post_id } = useParams();

  const fetchViewPost = async () => {
    let response = await APICall(
      ApiTypes.fetchViewPosts,
      null,
      `post_id=${post_id}`
    );
    if (response.status === 1) {
      setPostData(response.data);
      getImageUrls(response.data);
    } else if (response.status === 0 || response.status === undefined) {
    }
  };

  const fetchComment = async () => {
    let commentData = await APICall(
      ApiTypes.fetchComment,
      null,
      `page=${page}&size=${perPage}&post_id=${post_id} `
    );

    if (commentData.status === 1 && commentData.data !== undefined) {
      let result = comment.concat(commentData.data.list);
      setComment(result);
      if (commentData.data.currentPage + 1 === commentData.data.totalPages) {
        setHasMore(false);
      } else if (commentData.data.totalItems > 0) {
        setHasMore(true);
      }
    } else if (comment.status === 0) {
    }
  };

  useEffect(() => {
    fetchViewPost();
  }, []);

  useEffect(() => {
    fetchComment();
  }, [page]);

  const likeList = (post_id) => {
    setPostId(post_id)
    setLikeModuleShow(true)
    // navigate(`/post/likes/${post_id}`);
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row mt-4">
          <div className="col-12">
            <div className="h4 mb-4">
              <div className="d-flex justify-content-between">
                <div className="d-flex flex-column justify-content-center">
                  <div className="d-flex breadcrum-navigation-title">
                    <div className="me-2">Post</div>
                    <div className="me-2 text-muted">|</div>
                    <div className="d-flex">
                      <div
                        onClick={() => navigate("/home")}
                        className="cursor-pointer custom-breadcrum-inactive me-2"
                      >
                        Home
                      </div>
                      <div className="text-muted me-2">&gt;</div>
                      <div
                        onClick={() => navigate("/posts")}
                        className="cursor-pointer custom-breadcrum-inactive me-2"
                      >
                        Posts
                      </div>
                      <div className="text-muted me-2">&gt;</div>
                      <div className="cursor-pointer custom-breadcrum-active me-2">
                        Post
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-lg-8 col-xs-10 border-first-right bg-white pt-3 pb-3">
                  <PostDiscription imageUrls={imageUrls} postData={postData} />

                  <div className="d-flex gap-2 justify-content-start m-auto">
                    {postData.postHashTagDetails
                      ? postData?.postHashTagDetails.map((value, index) => {
                        return (
                          <button
                            key={index}
                            type="button"
                            className="btn btn-primary tag"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Tooltip on top"
                          >
                            #{value.hashtagDetails.hashtag_name}
                          </button>
                        );
                      })
                      : ""}
                  </div>
                  <hr style={{ margin: "10px auto" }} />
                  <div
                    className={` m-auto ${comment.length ? "scrollBox" : ""}`}
                    id="scrollableDiv"
                    style={{
                      height: "350px",
                      minHeight: "350px",
                      overflow: "scroll",
                    }}
                  >
                    {/* <div className="d-flex align-items-center justify-content-center gap-2">
                        <div column>
                          <img className="custom-image" src={blank} alt=""></img>
                        </div>
                        <Col>
                          <InputGroup>
                            <FormControl
                              className="bg-light"
                              placeholder="Type a Comment"
                              autoComplete="off"
                              aria-label="comment"
                              aria-describedby="basic-addon2"
                            />
                            <Button variant="primary" className="w-auto">
                              Comment
                            </Button>
                          </InputGroup>
                        </Col>
                      </div> 
                    */}

                    <InfiniteScroll
                      dataLength={comment.length} //This is important field to render the next data
                      next={() => setPage(page + 1)}
                      hasMore={hasMore}
                      loader={<Loader />}
                      scrollableTarget="scrollableDiv"
                    >
                      {comment.length > 0
                        ? comment.map((value, index) => (
                          <CommentModule key={index} value={value} />
                        ))
                        : <div className="text-center p-2 h6">Comments Not Found</div>}
                    </InfiniteScroll>
                  </div>
                </div>

                <PostDetail likeList={likeList} postData={postData} />
              </div>
            </div>

          </div>
        </div>
      </div>
      <LikeMember postId={postId} setPostId={setPostId} likeModuleShow={likeModuleShow} setLikeModuleShow={setLikeModuleShow} />
      {loading ? <SiteLoader /> : ""}
    </>
  );
}

export default ViewPost;
