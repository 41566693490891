import React from "react";
import { Carousel } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import blank from "../../../Assets/Image/Default/blank.png";

export default function PostDiscription({ imageUrls, postData }) {

  return (
    <>
      <div
        className={`m-auto ${imageUrls.length === 0 ? "d-flex justify-content-center align-items-center" : ""}  `}
        style={{ minHeight: "350px", height: "350px" }}
      >
        <Carousel interval={null}>
          {imageUrls.length > 0
            ? imageUrls.map((value, index) => {
              return (
                <Carousel.Item key={index}>
                  <LazyLoadImage
                    style={{
                      minHeight: "340px",
                      height: "340px",
                      objectFit: "cover",
                      objectPosition: "center",
                      borderRadius: "5px"
                    }}
                    src={value}
                    onError={(e) => {
                      e.target.setAttribute('src', blank)
                    }}
                    effect="blur"
                    alt="First slide"
                    width="100%"
                    threshold={100}
                    placeholderSrc="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAXcAAACGCAMAAAARpzrEAAAAKlBMVEX//f/w7vD//v/9/f349vj7+fv18/Xx7/H5+Pn29fbt6+3w7/Dz8vP69/pSR62tAAAEH0lEQVR4nO3d0XqjIBAFYByBSY19/9etmDQaowkgMhHOf7H7bbqx5pQiDipKwfcikt6DChFSz8hoy3daG+m9Kd7QuKk1um8WrDZo9scylpuGl8EP0SP5Axk7RMwrsQ+vor85jF5v6/cX+Vd6/8pkXvr1187G/T/0NwnR2Ng/Ya2Qe1KkPVJnFzxiT4eU/Rz7eMC9WuSeCvnFfqeld7ccdPGP/dbHw25Dazceh9RH7A230rtcBvo8gHxmJXdW8HunFtC530j2NMUUSCmkl7nh5w248mU2PxehnFKjwF5mbPDzNtf+rFd0jsIlFIqIfM5TFx+ceV6dDP992cF9L11AXxM0dJ8+vJXK3Snh1C2idx/HkkaqvXMhJxARzd2Z9/AXbq5p093WFdHch08Q2ViZ5kNpbXUuJRxU9+S+OIWhfKTCSsmn/LtO6MyxjNh35G6kAigieYo8rBZxdBMUn3uP3PeIHnv3Rfy+CyEVW1phRu57RLd3/rxt2IbcZSB3GchdRsSkx81h4/c6jtex4/duXpA0xmiTTvnXKxDpbn/uuk9Zgb+y5PUKeRCZ2Himmb7oTawbfoY/oqFkEF8H7qeNJM69qaD2Qynmm9LP8xWfu4purfMycHRtbQOL1Zhz4vVba97l0nT9fAuJL1vSRcxbf6Q3biR7p/t9bpFtOqqtobEP2vAGvyy+D0OblPOnlQQfPtXXLTrglEFVEvpYgw/J/OrutawnneNQeIOvYrxxvDZwIGir6YKPFVIscL1M+XWrXAJOOTsU3hMyjWddsiviqv/v4Xts7XFMTYl8r4NHa0+M2nHK7336to5JuKzIDSe3Y2fu3I1F0ntZICLdvyuR4Yh6EFJvHkJTxl1F32ve5t1zDG+nSlZXVLESMd59PW/1zL1u0bEfbbyByFweU0jaGFXKXUXfztW9yrqPCwAAAAAAAAAAAAAATsxvtoIwayoFuSfkPTsX9Xhg2OZ5jz/ZyOcTwDq/y9SJ8j66vXQuTL9uRnpPy+KfO7r3tJ7vZN/W2obR1yTCnffFu6QSPigJ/EKf0odEfAPHeVNKuKgRAAAAAI6E4aYMwqlqVvPcJX/ulZmqA6RM0mduwjv68aSq+PXKIBz/P1U58BmGsNP/o9kwz5fX9OBNtPesHhMjhjvIpZ/mo0hlWykYXqYBpc8nKrE2kgcAAAAAAIAVmG+Scf75VekEI5FqpYtFu1zMOaP3Xebga/HvGXMn1Z/9Pkp7xlWNCpjnO+VqAKef1+Zz5q7otsCBm4T6/CGX01ZhER3z7pMuw0CUdq3g7E7Z2sfR+7jIr7odneZ/qpVlhNXj6+tHs9mrKysJ37/eqs33TxvZfrv767GfJ12IYX7eNP571znLrncvv+rxbqpnbWIAADiFsCMhJDDm/jpagwyQu4w/a8J2S4cu4SkAAAAASUVORK5CYII="
                  />
                </Carousel.Item>
              )
            })
            : <span className="h6">No Image Found ! </span>}
        </Carousel>
      </div>
      {
        postData.post_heading ? (
          <p className="mt-3 font-600 m-auto">
            {postData?.post_heading}
          </p>
        ) : (
          ""
        )
      }

      {
        postData.post_text ? (
          <p id="passwordHelpBlock" muted className="m-auto text-muted">
            {postData?.post_text}
          </p>
        ) : (
          ""
        )
      }
    </>
  );
}
