export function addLoggedUser(data) {
  return { type: 'LOGGED_USER', payload: data };
}
export function isLoggedIn(data) {
  return { type: 'IS_LoggedIn', payload: data };
}
export function logoutUser(data){
  localStorage.removeItem("loggedUser");
  localStorage.removeItem("isLoggedIn");
  return { type: 'LOGGED_USER', payload: data };
}