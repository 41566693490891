import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import "react-data-table-component-extensions/dist/index.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ApiTypes, AwsConstants } from "../../constants";
import APICall from "../../networking/AxiousServices";
import blank from "../../Assets/Image/Default/blank.png";
import { useSelector } from "react-redux";
import { Credentials } from "aws-sdk";
import S3 from "aws-sdk/clients/s3";
import SiteLoader from "../../Layout/Loader/SiteLoader";
import { SERVICE_TYPE } from "../../constants/Enumeration";
import imageCompression from 'browser-image-compression';

export default function EditService() {
  document.title = "Kalon | Edit Service";
  let { category_service_id } = useParams();
  let navigate = useNavigate();
  const loggedUserData = useSelector((state) => state.data);
  const inputFile = useRef(null);
  const [service, setService] = useState("");
  // const [activeServices, setActiveServices] = useState([]);
  const [categories, setCategories] = useState([]);
  const [serviceDetails, setServiceDetails] = useState("");

  const [serviceEr, setServiceEr] = useState("");

  const [catEr, setCatEr] = useState("");
  const [price, setPrice] = useState("");
  const [priceEr, setPriceEr] = useState("");
  const [discount, setDiscount] = useState("");
  const [discountEr, setDiscountEr] = useState("");
  const [time, setTime] = useState("");
  const [timeEr, setTimeEr] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [serEr, setSerEr] = useState("");
  const [serDlsEr, setSerDlsEr] = useState("");
  const [fileEr, setFileEr] = useState("");
  // const [imageUrl, setImageUrl] = useState(null);

  // const [activeService, setActiveService] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);

  const [serviceType, setServiceType] = useState(null);

  const access = new Credentials({
    accessKeyId: AwsConstants.accessKeyId,
    secretAccessKey: AwsConstants.secretAccessKey,
  });
  const s3 = new S3({
    credentials: access,
    region: AwsConstants.region,
    signatureVersion: "v4",
  });

  const handleService = (e) => {
    setService(e.target.value);
  };
  const handleServiceDetails = (e) => {
    setServiceDetails(e.target.value);
  };
  const handlePrice = (e) => {
    if (e.target.value > 0) {
      setPrice(e.target.value);
    } else {
      setPrice("");
    }
  };
  const handleDiscount = (e) => {
    if (e.target.value > 0) {
      setDiscount(e.target.value);
    } else {
      setDiscount("");
    }
  };
  const handleTime = (e) => {
    if (e.target.value > 0) {
      setTime(e.target.value);
    } else {
      setTime("");
    }
  };
  // const handleActiveService = (e) => {
  //   if (e.target.value == 0) {
  //     setActiveService(null)
  //   }
  //   else {
  //     setActiveService(parseInt(e.target.value))
  //   }
  // }

  const handleCategory = (e) => {
    if (e.target.value == 0) {
      setSelectedCategory(null);
    } else {
      setSelectedCategory(e.target.value);
    }
  };

  const handleFile = (e) => {
    if (e.target.files[0].name.match(/\.(jpg|jpeg|png)$/)) {
      setFileEr("");
      setSelectedFile(e.target.files[0]);
      setFiles([...files, e.target.files[0]]);
      inputFile.current.value = null;
    } else {
      setFileEr("Only jpg, jpeg and png files are supported.");
      setTimeout(() => {
        setFileEr("");
      }, 3000);
      inputFile.current.value = null;
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let fileNewArray = []
    for (let index = 0; index < files.length; index++) {
      let fileInner = files[index];
      if(!fileInner){
        fileNewArray = [...fileNewArray , fileInner]
      }else{
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true
        }
        try {
          const compressedFile = await imageCompression(fileInner, options);
          let fileNew = new File([compressedFile], fileInner.name);
          fileNewArray = [...fileNewArray, fileNew]
        } catch (error) {
          console.log(error);
        }
      }
     
    }
    if (service == "") {
      setSerEr("Service field is required.");
    } else {
      if (service.length <= 3) {
        setSerEr("Minimum 4 characters is required.");
      } else {
        setSerEr("");
        if (selectedCategory) {
          setCatEr("");
          if (serviceDetails != "") {
            setSerDlsEr("");


            if (serviceType) {
              setServiceEr("")
              if (price != "") {
                if (!isNaN(price)) {
                  setPriceEr("");
                  if (discount != "") {
                    if (!isNaN(discount)) {
                      if (parseInt(discount) < parseInt(price)) {
                        setDiscountEr("");
                        if (time != "") {
                          if (!isNaN(time)) {
                            setTimeEr("");
                            if (fileNewArray.length != 0) {
                              var formData = new FormData();
                              fileNewArray.forEach((fileInner) => {
                                if (fileInner) {
                                  formData.append("file", fileInner);
                                }
                              });
                              if (!formData.get("file")) {
                                formData.append("file", null);
                              }
                              formData.append(
                                "category_service_id",
                                category_service_id
                              );
                              formData.append("category_id", selectedCategory);
                              formData.append("service_name", service.trim());
                              formData.append(
                                "service_details",
                                serviceDetails.trim()
                              );
                              formData.append("service_price", price.trim());
                              formData.append(
                                "service_discount",
                                discount.trim()
                              );
                              formData.append("time_in_min", time);
                              // if (activeService) {
                              //   formData.append('ref_category_service_id', activeService);
                              // }
                              formData.append(
                                "updated_by",
                                loggedUserData.userLoginData.user_id
                              );
                              formData.append("is_active", true);
                              setLoading(true);
                              debugger
                              let res = await APICall(
                                ApiTypes.editService,
                                formData
                              );
                              if (res != false) {
                                setLoading(false);
                                toast.success("Service updated successfully");
                                navigate("/services");
                              } else {
                                setLoading(false);
                              }
                            } else {
                              setFileEr("");
                              var formData = new FormData();
                              formData.append("file", null);
                              formData.append(
                                "category_service_id",
                                category_service_id
                              );
                              formData.append("category_id", selectedCategory);
                              formData.append("service_name", service.trim());
                              formData.append(
                                "service_details",
                                serviceDetails.trim()
                              );
                              formData.append("category_service_type", serviceType);
                              formData.append("service_price", price.trim());
                              formData.append(
                                "service_discount",
                                discount.trim()
                              );
                              formData.append("time_in_min", time);
                              // if (activeService) {
                              //   formData.append('ref_category_service_id', activeService);
                              // }
                              formData.append(
                                "updated_by",
                                loggedUserData.userLoginData.user_id
                              );
                              formData.append("is_active", true);
                              setLoading(true);
                              let res = await APICall(
                                ApiTypes.editService,
                                formData
                              );
                              if (res != false) {
                                setLoading(false);
                                toast.success("Service updated successfully");
                                navigate("/services");
                              } else {
                                setLoading(false);
                              }
                            }
                          } else {
                            setTimeEr("Please only enter numeric value.");
                          }
                        } else {
                          setTimeEr("Time field is required.");
                        }
                      } else {
                        setDiscountEr("Discount must be less than price.");
                      }
                    } else {
                      setDiscountEr("Please only enter numeric value.");
                    }
                  } else {
                    setDiscountEr("Discount field is required.");
                  }
                } else {
                  setPriceEr("Please only enter numeric value.");
                }
              } else {
                setPriceEr("Price field is required.");
              }
            } else {
              setServiceEr("Please select service type.")
            }
          } else {
            setSerDlsEr("Details field is required.");
          }
        } else {
          setCatEr("Please select one category.");
        }
      }
    }
  };

  useEffect(() => {
    if (selectedFile) {
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.addEventListener(
        "load",
        function () {
          setImageUrls([
            ...imageUrls,
            { url: reader.result, category_service_picture_id: null },
          ]);
          // setImageUrls([...imageUrls, reader.result]);
        },
        false
      );
    }
  }, [selectedFile]);

  // const fetchActiceServices = async () => {
  //   setLoading(true);
  //   let res = await APICall(ApiTypes.fetchActiveServices);
  //   if (res.status == 1) {
  //     setLoading(false);
  //     setActiveServices(res.data)
  //   }
  //   else {
  //     setLoading(false);
  //   }
  // }
  const fetchCategories = async () => {
    setLoading(true);
    const bodyData = {
      search_keyword: "",
    };
    let res = await APICall(ApiTypes.fetchCategories, bodyData);
    if (res.status == 1) {
      setLoading(false);
      setCategories(res.data);
    } else {
      setLoading(false);
    }
  };
  const getImageUrls = (response) => {
    let newArray = [];
    if (response.categoryServicePictureDetails) {
      if (response.categoryServicePictureDetails.length != 0) {
        let iUrls = [];
        let newfiles = [];
        response.categoryServicePictureDetails.forEach(async (picture) => {
          const url = await s3.getSignedUrlPromise("getObject", {
            Bucket: AwsConstants.Bucket,
            Key: `Home/Services/${response.category_service_id}/${picture.display_pic}`,
            Expires: AwsConstants.signedUrlExpireSeconds,
          });
          iUrls.push({
            url: url,
            category_service_picture_id: picture.category_service_picture_id,
          });
          newfiles = [...newfiles, false];
          setFiles(newfiles);
        });
        setImageUrls(iUrls);
        response["imageUrls"] = iUrls;
      } else {
        response["imageUrl"] = null;
        setImageUrls([]);
      }
    } else {
      response["imageUrl"] = null;
      setImageUrls([]);
    }
    newArray.push(response);
    return newArray;
  };

  const handleRemoveImage = async (
    index,
    imageUrl,
    category_service_picture_id
  ) => {
    setLoading(true);
    files.splice(index, 1);
    let FilesNew = [...files];
    setFiles(FilesNew);
    if (!category_service_picture_id) {
      imageUrls.splice(index, 1);
      let imageNew = [...imageUrls];
      setImageUrls(imageNew);
      setLoading(false);
    } else {
      let data = { category_service_picture_id: category_service_picture_id };
      let res = await APICall(ApiTypes.removeServiceImage, data);
      if (res.status == 1) {
        toast.success("Service picture deleted successfully.");
        fetchService();
      }
      setLoading(false);
    }
  };

  const fetchService = async () => {
    setLoading(true);
    let res = await APICall(
      ApiTypes.getService,
      null,
      `category_service_id=${category_service_id}`
    );

    if (res.status == 1) {
      let newArray = await getImageUrls(res.data);
      setService(newArray[0].service_name);
      setServiceDetails(
        newArray[0].service_details ? newArray[0].service_details : ""
      );
      setPrice(newArray[0].service_price);
      setDiscount(newArray[0].service_discount);
      setTime(newArray[0].time_in_min);
      

      setServiceType(newArray[0].category_service_type)
      // if (newArray[0].ref_category_service_id) {
      //   setActiveService(parseInt(newArray[0].ref_category_service_id))
      // }
      // else {
      //   setActiveService(null);
      // }

      setSelectedCategory(newArray[0].category_id);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    // fetchActiceServices();
    fetchCategories();
    fetchService();
  }, []);

  return (
    <div className="container-fluid">
      <div className="row mt-4">
        <div className="col-12">
          <div className="h4 mb-4">
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-column justify-content-center">
                <div className="d-flex breadcrum-navigation-title">
                  <div className="me-2">Edit Service</div>
                  <div className="me-2 text-muted">|</div>
                  <div className=" d-flex">
                    <div onClick={() => navigate('/home')} className="cursor-pointer custom-breadcrum-inactive me-2">Home</div>
                    <div className="text-muted me-2">&gt;</div>
                    <div onClick={() => navigate('/services')} className="cursor-pointer custom-breadcrum-inactive me-2">Services</div>
                    <div className="text-muted me-2">&gt;</div>
                    <div className="cursor-pointer  custom-breadcrum-active me-2">Edit Service</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ background: "#fff" }}>
            <Form className="py-4 px-3"
              onSubmit={(e) => {
                handleSubmit(e);
              }}
            >
              <div className="row ">
                <label
                  className="col-md-4 col-sm-2 col-lg-3 col-xl-2"
                  htmlFor="service"
                >
                  Service <span className="validation">*</span>
                </label>
                <div className="col-md-8 col-sm-8 col-lg-9 col-xl-8 ">
                  <input
                    type="text"
                    value={service}
                    onChange={(e) => {
                      handleService(e);
                    }}
                    className="form-control"
                    id="service"
                    placeholder="Enter service"
                  />
                  {serEr != "" ? (
                    <span className="validation">*{serEr}</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row mt-4">
                <label
                  className="col-md-4 col-sm-2 col-lg-3 col-xl-2"
                  htmlFor="main-service"
                >
                  Category <span className="validation">*</span>
                </label>
                <div className="col-md-8 col-sm-8 col-lg-9 col-xl-8 ">
                  <Form.Select
                    onChange={(e) => {
                      handleCategory(e);
                    }}
                    id="main-service"
                    aria-label="Default select example"
                  >
                    <option value="0">Select category</option>
                    {categories.length != 0
                      ? categories.map((cat) => {
                        return (
                          <option
                            selected={
                              selectedCategory == cat.category_id ? true : false
                            }
                            key={cat.category_id}
                            value={cat.category_id}
                          >
                            {cat.category_name}
                          </option>
                        );
                      })
                      : ""}
                  </Form.Select>
                  {catEr != "" ? (
                    <span className="validation">*{catEr}</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {/* <div className="d-flex mt-4">
                  <label className="col-md-2 col-sm-2 col-lg-2 col-xl-2" htmlFor="main-service">Parent Service</label>
                  <div className='col-md-8 col-sm-8 col-lg-8 col-xl-8 '>
                    <Form.Select onChange={(e) => { handleActiveService(e) }} id="main-service" aria-label="Default select example">
                      <option value="0">Select main service</option>
                      {activeServices.length != 0 ? activeServices.map((activeSer) => {
                        return <option selected={activeService == activeSer.category_service_id ? true : false} key={activeSer.category_service_id} value={activeSer.category_service_id}>{activeSer.service_name}</option>
                      }) : ""}
                    </Form.Select>
                  </div>
                </div> */}
              <div className="row mt-4">
                <label
                  className="col-md-4 col-sm-2 col-lg-3 col-xl-2"
                  htmlFor="serviceDetails"
                >
                  Service Details <span className="validation">*</span>
                </label>
                <div className="col-md-8 col-sm-8 col-lg-9 col-xl-8 ">
                  <textarea
                    value={serviceDetails}
                    onChange={(e) => {
                      handleServiceDetails(e);
                    }}
                    className="form-control"
                    id="serviceDetails"
                    rows="3"
                  ></textarea>
                  {serDlsEr != "" ? (
                    <span className="validation">*{serDlsEr}</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="row mt-4">
                <label
                  className="col-md-4 col-sm-2 col-lg-3 col-xl-2"
                  htmlFor="serviceDetails"
                >
                  Service Type <span className="validation">*</span>
                </label>
                <div className="col-md-8 col-sm-8 col-lg-9 col-xl-8 ">
                  <Form.Select
                    onChange={(e) => {
                      setServiceType(e.target.value ? e.target.value : null)
                    }}
                    id="main-service"
                    aria-label="Default select example"
                  >
                    <option selected={serviceType ? false : true} value="">Select service type</option>
                    {Object.keys(SERVICE_TYPE).length != 0
                      ? Object.keys(SERVICE_TYPE).map((ser_key, index) => {
                        return (
                          <option key={index} selected={serviceType === ser_key ? true : false} value={ser_key}>
                            {SERVICE_TYPE[ser_key]}
                          </option>
                        );
                      })
                      : ""}
                  </Form.Select>
                  {serviceEr != "" ? (
                    <span className="validation">*{serviceEr}</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>


              <div className="row mt-4">
                <label
                  className="col-md-4 col-sm-2 col-lg-3 col-xl-2"
                  htmlFor="price"
                >
                  <i className="fa fa-inr" aria-hidden="true"></i> Price{" "}
                  <span className="validation">*</span>{" "}
                </label>
                <div className="col-md-8 col-sm-8 col-lg-9 col-xl-8 ">
                  <input
                    type="number"
                    onWheelCapture={(e) => {
                      e.target.blur();
                    }}
                    value={price}
                    onChange={(e) => {
                      handlePrice(e);
                    }}
                    className="form-control"
                    id="price"
                    placeholder="Enter price"
                  />
                  {priceEr != "" ? (
                    <span className="validation">*{priceEr}</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row mt-4">
                <label
                  className="col-md-4 col-sm-2 col-lg-3 col-xl-2"
                  htmlFor="discount"
                >
                  <i className="fa fa-inr" aria-hidden="true"></i> Discount{" "}
                  <span className="validation">*</span>{" "}
                </label>
                <div className="col-md-8 col-sm-8 col-lg-9 col-xl-8 ">
                  <input
                    type="number"
                    onWheelCapture={(e) => {
                      e.target.blur();
                    }}
                    value={discount}
                    onChange={(e) => {
                      handleDiscount(e);
                    }}
                    className="form-control"
                    id="discount"
                    placeholder="Enter price"
                  />
                  {discountEr != "" ? (
                    <span className="validation">*{discountEr}</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row mt-4">
                <label
                  className="col-md-4 col-sm-2 col-lg-3 col-xl-2"
                  htmlFor="time"
                >
                  Time <span style={{ fontWeight: 500 }}>(Min)</span>{" "}
                  <span className="validation">*</span>
                </label>
                <div className="col-md-8 col-sm-8 col-lg-9 col-xl-8 ">
                  <input
                    type="number"
                    onWheelCapture={(e) => {
                      e.target.blur();
                    }}
                    value={time}
                    onChange={(e) => {
                      handleTime(e);
                    }}
                    className="form-control"
                    id="time"
                    placeholder="Enter time"
                  />
                  {timeEr != "" ? (
                    <span className="validation">*{timeEr}</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row mt-4">
                <label
                  className="col-md-4 col-sm-2 col-lg-3 col-xl-2"
                  htmlFor="categoryImage"
                >
                  {" "}
                  Service Image{" "}
                </label>
                <div className="col-md-8 col-sm-8 col-lg-9 col-xl-8 ">
                  <div className="mt-2 gridrow">
                    {imageUrls.length != 0
                      ? imageUrls.map((img, index) => {
                        return (
                          <div key={index} className="d-flex flex-column">
                            <img
                              className="image-category"
                              widht="100px"
                              height="100px"
                              src={img.url}
                            />
                            <button
                              type="button"
                              onClick={() => {
                                handleRemoveImage(
                                  index,
                                  img.url,
                                  img.category_service_picture_id
                                );
                              }}
                              className="btn btn-primary"
                            >
                              {" "}
                              Remove Image{" "}
                            </button>
                          </div>
                        );
                      })
                      : ""}
                    <div>
                      <div className="position-relative d-flex flex-column justify-content-center">
                        <img
                          style={{ opacity: "0.5" }}
                          className="image-category"
                          widht="100px"
                          height="100px"
                          src={blank}
                        />
                        <div
                          onClick={() => {
                            inputFile.current.click();
                          }}
                          className="upload-icon"
                        >
                          <i className="fa fa-plus-circle" aria-hidden="true"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <input
                    ref={inputFile}
                    onChange={(e) => {
                      handleFile(e);
                    }}
                    accept="image/*"
                    type="file"
                    className="mx-2 d-none form-control-file"
                    id="categoryImage"
                  />
                  {fileEr != "" ? (
                    <span className="validation">*{fileEr}</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row">
                <label
                  className="col-md-4 col-sm-2 col-lg-3 col-xl-2"
                ></label>
                <div className="mt-4 col-md-8 col-sm-8 col-lg-9 col-xl-8 createbtn">
                  <button type="submit" className="btn btn-primary w-auto mb-2">
                    Update
                  </button>
                </div>
              </div>
            </Form>
            {loading ? <SiteLoader /> : ""}
          </div>
        </div>
      </div>
    </div>
  );
}
