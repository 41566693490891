import React from 'react'
import "./SiteLoader.css";
import SiteLoaderImg from "../../Assets/Image/Icon/site-loading.svg"
export default function SiteLoader() {
  return (
    <div className='loader-div'>
      {/* <div className="spinner-grow text-warning m-auto" role="status">
      </div> */}
      <img className='img-site-loader' src={SiteLoaderImg}/>
    </div>
  )
}
