const ORDER_STATUS = Object.freeze({
  PENDING: "PENDING",
  CONFIRMED: "CONFIRMED",
  PROCESSING: "PROCESSING",
  PROCESSED: "PROCESSED",
  COMPLETED: "COMPLETED",
  CANCELLED: "CANCELLED",
  REJECTED: "REJECTED",
});

const PAYMENT_STATUS = Object.freeze({
  PENDING: "PENDING",
  FAILURE: "FAILURE",
  SUCCESS: "SUCCESS",
  PROCESSING: "PROCESSING",
});

const PAYMENT_MODE = Object.freeze({
  ONLINE: "ONLINE",
  OFFLINE: "OFFLINE",
});

const RES_STATUS_CODE = Object.freeze({
  STATUS_CODE_200_SUCCESS: 200,
  STATUS_CODE_500_INTERNAL_SERVER_ERROR: 500,
  STATUS_CODE_404_NOT_FOUND: 404,
  STATUS_CODE_400_BACKEND_VALIDATION: 400,
  STATUS_CODE_401_UNAUTHORIZES: 401,
  STATUS_CODE_403_FORBIDDEN: 403,
});

const TIME_TYPE = {
  MINUTE: 'Minutes',
  HOUR: 'Hours',
  DAY: 'Days',
  WEEK: 'Weeks',
  MONTH: 'Months'
}

const BANNER_SCREEN = {
  DASHBOARD: 'Dashboard'
}
const SERVICE_TYPE_ENUM = {
  PUBLIC: 'PUBLIC',
  PRIVATE:'PRIVATE'
}
const SERVICE_TYPE = {
  PUBLIC: 'Public',
  PRIVATE:'Private'
}

const SERVICE_REQUEST_STATUS = {
  PENDING: 'PENDING',
  REJECTED:'REJECTED',
  COMPLETED :"COMPLETED"
}
const PARLOUR_REQUEST_STATUS = {
  PENDING: 'PENDING',
  REJECTED:'REJECTED',
  ACCEPTED :"ACCEPTED"
}

export { ORDER_STATUS, PAYMENT_STATUS, RES_STATUS_CODE, PAYMENT_MODE, TIME_TYPE ,BANNER_SCREEN ,SERVICE_TYPE ,SERVICE_TYPE_ENUM,SERVICE_REQUEST_STATUS ,PARLOUR_REQUEST_STATUS};
