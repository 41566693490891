import React from 'react'
import { useState } from 'react';
import { Modal, Button ,Spinner} from "react-bootstrap";
import { ApiTypes } from '../../constants';
import APICall from '../../networking/AxiousServices';
import { PARLOUR_REQUEST_STATUS } from '../../constants/Enumeration';
import { useNavigate } from 'react-router-dom';

export default function RejectModalParlour({req_parlour_id , showModalReject , setShowModalReject ,handleAcceptReq ,dangerLoading ,setDangerLoading }) {

    const navigate = useNavigate();

    const [comment, setComment] = useState("");
    // const [loading, setLoading] = useState(false);

    const handleCommentInput = (e) => {
        setComment(e.target.value ? e.target.value : "")
    }

    const handleClose = () => {
        setShowModalReject(false)
        setComment("")
    }

    const handleReject = async (e) => {
        // setLoading(true)
        e.preventDefault();
        setDangerLoading(true)
        handleAcceptReq(PARLOUR_REQUEST_STATUS.REJECTED , comment)
    }


  return (<Modal show={showModalReject} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>Reject Shop Admin Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className='container-fluid'>
                <div className="row">
                    <div className='col-12 my-4'>
                        <input
                            type="texts"
                            value={comment}
                            onChange={(e) => {
                                handleCommentInput(e)
                            }}
                            className="form-control"
                            id="comment"
                            placeholder="Enter Comment"
                        />
                    </div>
                </div>
            </div>


        </Modal.Body>
        <Modal.Footer>
            {!dangerLoading ? <>  <Button type="button" variant="secondary" onClick={handleClose}>
                Close
            </Button>
                <Button type="button" variant="primary" onClick={(e) => { handleReject(e) }}>
                    Reject
                </Button></> : <Button type="button" variant="primary">
                <Spinner
                    style={{ height: "20px", width: "20px" }}
                    className="p-1"
                    animation="border"
                    variant="warning"
                />
            </Button>}
        </Modal.Footer>
    </Modal>)
}
