import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import "react-data-table-component-extensions/dist/index.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ApiTypes } from "../../constants";
import APICall from "../../networking/AxiousServices";
import { useSelector } from "react-redux";
import SiteLoader from "../../Layout/Loader/SiteLoader";

export default function AddUser() {
  document.title = "Kalon | Add User";
  let navigate = useNavigate();
  const loggedUserData = useSelector((state) => state.data);
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  //Form Values
  const [formValues, setFormValues] = useState({
    display_name: "",
    user_name: "",
    // password: "",
    // confirm_password: "",
    role_id: "",
    updated_by: loggedUserData.userLoginData.user_id,
    is_active: true,
    user_type: "web_user",
    host_origin: window.location.origin,
  });
  const [nameEr, setNameEr] = useState("");
  const [emailEr, setEmailEr] = useState("");
  // const [passEr, setPassEr] = useState("");
  // const [confirmEr, setPassEr] = useState(null);
  const [roleEr, setRoleEr] = useState("");
  // const [passHide, setPassHide] = useState(true);
  // const [comPasHide, setComPasHide] = useState(true);
  const handleUserName = (value) => {
    setFormValues((prevState) => ({
      ...prevState,
      display_name: value,
    }));
  };
  const handleEmail = (value) => {
    setFormValues((prevState) => ({
      ...prevState,
      user_name: value,
    }));
  };

  const handleRole = (value) => {
    setFormValues((prevState) => ({
      ...prevState,
      role_id: value,
    }));
  };
  const handleIsActive = (value) => {
    setFormValues((prevState) => ({
      ...prevState,
      is_active: parseInt(value) == 1 ? false : true,
    }));
  };
  // const handlePassword = (value) => {
  //     if (value != "") {
  //         if (value != formValues.confirm_password) {
  //             setConfirmEr(true)
  //         } else {
  //             setConfirmEr(false)
  //         }
  //     } else {
  //         setConfirmEr(null)
  //     }
  //     setFormValues((prevState) => (
  //         {
  //             ...prevState,
  //             password: value
  //         }));
  // }
  // const handleConfirmPassword = (value) => {
  //     if (value != "") {
  //         if (value != formValues.password) {
  //             setConfirmEr(true)
  //         } else {
  //             setConfirmEr(false)
  //         }
  //     } else {
  //         setConfirmEr(null)
  //     }
  //     setFormValues((prevState) => (
  //         {
  //             ...prevState,
  //             confirm_password: value
  //         }));
  // }
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formValues.user_name != "") {
      if (
        formValues.user_name.match(
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
      ) {
        setEmailEr("");
        if (formValues.display_name) {
          setNameEr("");
          if (formValues.role_id != "") {
            setRoleEr("");
            // if (formValues.password && formValues.confirm_password) {
            // setPassEr("");
            setLoading(true);
            let res = await APICall(ApiTypes.addAdmin, formValues);
            if (res.status == 1) {
              toast.success("User added successfully");
              navigate("/users");
              setLoading(false);
            } else {
              toast.error("Something went wrong");
              setLoading(false);
            }
            // }
            // else {
            //     setPassEr("Password and confirm password field is required.")
            // }
          } else {
            setRoleEr("Please select role.");
          }
        } else {
          setNameEr("Display Name field is required.");
        }
      } else {
        setEmailEr("Please enter valid email id.");
      }
    } else {
      setEmailEr("Email field is required.");
    }
  };
  const fetchAllRoles = async () => {
    setLoading(true);
    let res = await APICall(ApiTypes.fetchRoles);
    if (res.status == 1) {
      setRoles(res.data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchAllRoles();
  }, []);
  return (
    <div className="container-fluid">
      <div className="row mt-4">
        <div className="col-12">
          <div className="h4 mb-4">
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-column justify-content-center">
                <div className="d-flex breadcrum-navigation-title">
                  <div className="me-2">Add User</div>
                  <div className="me-2 text-muted">|</div>
                  <div className="d-flex">
                    <div
                      onClick={() => navigate("/home")}
                      className="cursor-pointer custom-breadcrum-inactive me-2"
                    >
                      Home
                    </div>
                    <div className="text-muted me-2">&gt;</div>
                    <div
                      onClick={() => navigate("/users")}
                      className="cursor-pointer custom-breadcrum-inactive me-2"
                    >
                      Users
                    </div>
                    <div className="text-muted me-2">&gt;</div>
                    <div className="cursor-pointer  custom-breadcrum-active me-2">
                      Add User
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ background: "#fff" }}>
            <Form
              onSubmit={(e) => {
                handleSubmit(e);
              }}
              className="py-4 px-3"
            >
              <div className="row ">
                <label
                  className="col-md-4 col-sm-2 col-lg-3 col-xl-2"
                  htmlFor="email"
                >
                  Email <span className="validation">*</span>
                </label>
                <div className="col-md-8 col-sm-8 col-lg-9 col-xl-8 ">
                  <input
                    type="email"
                    value={formValues.user_name}
                    onChange={(e) => {
                      handleEmail(e.target.value);
                    }}
                    className="form-control"
                    id="email"
                    placeholder="Enter email"
                  />
                  {emailEr != "" ? (
                    <span className="validation">*{emailEr}</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row mt-4">
                <label
                  className="col-md-4 col-sm-2 col-lg-3 col-xl-2"
                  htmlFor="username"
                >
                  Display Name <span className="validation">*</span>
                </label>
                <div className="col-md-8 col-sm-8 col-lg-9 col-xl-8 ">
                  <input
                    type="text"
                    value={formValues.display_name}
                    onChange={(e) => {
                      handleUserName(e.target.value);
                    }}
                    className="form-control"
                    id="username"
                    placeholder="Enter username"
                  />
                  {nameEr != "" ? (
                    <span className="validation">*{nameEr}</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              {/* <div className="d-flex mt-4 ">
                            <label className="col-md-2 col-sm-2 col-lg-2 col-xl-2" htmlFor="Username">Username</label>
                            <div className='col-md-8 col-sm-8 col-lg-8 col-xl-8 '>
                                <input type="text" value={formValues.user_name} onChange={(e) => { handleUserName(e.target.value) }} className="form-control" id="Username" placeholder="Enter username" />
                                {nameEr != "" ? <span className='validation'>*{nameEr}</span> : ""}
                            </div>
                        </div> */}

              <div className="row mt-4">
                <label
                  className="col-md-4 col-sm-2 col-lg-3 col-xl-2"
                  htmlFor="role"
                >
                  Role <span className="validation">*</span>
                </label>
                <div className="col-md-8 col-sm-8 col-lg-9 col-xl-8 ">
                  <Form.Select
                    onChange={(e) => {
                      handleRole(e.target.value);
                    }}
                    id="role"
                    aria-label="Default select example"
                  >
                    <option value="0">Select admin role</option>
                    {roles.length != 0
                      ? roles.map((role) => {
                        return (
                          <option key={role.role_id} value={role.role_id}>
                            {role.role_name}
                          </option>
                        );
                      })
                      : ""}
                  </Form.Select>
                  {roleEr != "" ? (
                    <span className="validation">*{roleEr}</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row mt-4">
                <label
                  className="col-md-4 col-sm-2 col-lg-3 col-xl-2"
                  htmlFor="is-active"
                >
                  Is Active
                </label>
                <div className="col-md-8 col-sm-8 col-lg-9 col-xl-8 ">
                  <input
                    checked={formValues.is_active}
                    onChange={(e) => {
                      handleIsActive(e.target.value);
                    }}
                    value={formValues.is_active ? 1 : 0}
                    type="checkbox"
                    id="is-active"
                    className="form-check-input"
                  />
                </div>
              </div>
              {/* <div className="d-flex mt-4">
                            <label className="col-md-2 col-sm-2 col-lg-2 col-xl-2" htmlFor="password">Password <span className='validation'>*</span></label>
                            <div className='col-md-8 col-sm-8 col-lg-8 col-xl-8 position-relative'>
                                <input type={passHide ? "password" :"text"} value={formValues.password} onChange={(e) => { handlePassword(e.target.value) }} className="form-control" id="password" placeholder="Enter password" />
                                {passHide ?
                                    <i onClick={()=> setPassHide(false)} className="fa fa-eye eye-custom" aria-hidden="true"></i> :
                                    <i onClick={()=> setPassHide(true)} className="fa fa-eye-slash eye-custom" aria-hidden="true"></i>
                                }
                            </div>
                        </div>
                        <div className="d-flex mt-4">
                            <label className="col-md-2 col-sm-2 col-lg-2 col-xl-2" htmlFor="confirm-password">Confirm Password <span className='validation'>*</span></label>
                            <div className='col-md-8 col-sm-8 col-lg-8 col-xl-8 position-relative '>
                                <input type={comPasHide ? "password" :"text"} value={formValues.confirm_password} onChange={(e) => { handleConfirmPassword(e.target.value) }} className="form-control" id="confirm_password" placeholder="Enter confirm password" />
                                {comPasHide ?
                                    <i onClick={()=> setComPasHide(false)}className="fa fa-eye eye-custom" aria-hidden="true"></i>
                                    : <i onClick={()=> setComPasHide(true)} className="fa fa-eye-slash eye-custom" aria-hidden="true"></i>}
                                {confirmEr != null ? <div className={`validation ${!confirmEr ? 'text-success' : ""}`}>{confirmEr ? "Password does not match." : "Password matched."}</div> : ""}
                                {passEr != "" ? <div className='validation'>*{passEr}</div> : ""}
                            </div>
                        </div> */}
              <div className="row">
                <label className="col-md-4 col-sm-2 col-lg-3 col-xl-2"></label>
                <div className="mt-4 col-md-8 col-sm-8 col-lg-9 col-xl-8 createbtn">
                  <button type="submit" className="btn btn-primary w-auto mb-2">
                    Create
                  </button>
                </div>
              </div>
            </Form>
            {loading ? <SiteLoader /> : ""}
          </div>
        </div>
      </div>
    </div>
  );
}
