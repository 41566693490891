import React, { useEffect, useState } from "react";
import { ApiTypes, AwsConstants } from "../../constants";
import ServiceComponent from "../../Layout/Components/ServiceComponent";


export default function ShopAdminServices({parlour_id}) {

    return (<div className="py-1 my-4 m-auto bg-body rounded">
        <div className="table-outer react-table">
         <ServiceComponent APIRoute={ApiTypes.fetchShopAdmimServices} data={{ parlour_id : parlour_id }} />
            {/* {loading ?<SiteLoader />:""} */}
        </div>
    </div>)
}
