import React from 'react'
import ComingSoon from '../Layout/Components/ComingSoon'

export default function Notification() {
  document.title = "Kalon | Notifications";

  return (
    <div className="container">
      <ComingSoon />
    </div>
  )
}
