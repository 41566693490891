import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import "react-data-table-component-extensions/dist/index.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ApiTypes } from "../../constants";
import APICall from "../../networking/AxiousServices";
import blank from "../../Assets/Image/Default/blank.png";
import { useSelector } from "react-redux";
import SiteLoader from "../../Layout/Loader/SiteLoader";
import imageCompression from 'browser-image-compression';

export default function AddCategory() {
  document.title = "Kalon | Add Category";
  let navigate = useNavigate();
  const loggedUserData = useSelector((state) => state.data);
  const inputFile = useRef(null);
  const [category, setCategory] = useState("");
  // const [activeCategories, setActiveCategories] = useState([]);
  const [categoryDetails, setCategoryDetails] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [catEr, setCatEr] = useState("");
  const [catDlsEr, setCatDlsEr] = useState("");
  const [fileEr, setFileEr] = useState("");
  const [imageUrls, setImageUrls] = useState([]);
  const [files, setFiles] = useState([]);
  // const [activeCategory, setActiveCategory] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleCategory = (e) => {
    setCategory(e.target.value);
  };
  const handleCategoryDetails = (e) => {
    setCategoryDetails(e.target.value);
  };
  // const handleActiveCategory = (e) => {
  //   if (e.target.value == 0) {
  //     setActiveCategory(null)
  //   }
  //   else {
  //     setActiveCategory(parseInt(e.target.value))
  //   }
  // }
  const handleFile = (e) => {
    if (e.target.files[0].name.match(/\.(jpg|jpeg|png)$/)) {
      setFileEr("");
      setSelectedFile(e.target.files[0]);
      setFiles([...files, e.target.files[0]]);
      inputFile.current.value = null;
    } else {
      setFileEr("Only jpg, jpeg and png files are supported.");
      setTimeout(() => {
        setFileEr("");
      }, 3000);
      inputFile.current.value = null;
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let fileNewArray = []
    for (let index = 0; index < files.length; index++) {
      let fileInner = files[index];
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      }
      try {
        const compressedFile = await imageCompression(fileInner, options);
        let fileNew = new File([compressedFile], fileInner.name);
        fileNewArray = [...fileNewArray, fileNew]
      } catch (error) {
        console.log(error);
      }
    }

    if (category === "") {
      setCatEr("Category field is required.");
    } else {
      if (category.length <= 3) {
        setCatEr("Minimum 4 characters is required.");
      } else {
        setCatEr("");
        if (categoryDetails !== "") {
          setCatDlsEr("");
          if (fileNewArray.length === 0) {
            setFileEr("Select image for the category.");
          } else {
            setFileEr("");
            var formData = new FormData();
            fileNewArray.forEach((fileInner) => {
              formData.append("file", fileInner);
            });
            formData.append("category_name", category.trim());
            formData.append("details", categoryDetails.trim());
            // if(activeCategory){
            //   formData.append('ref_category_id', activeCategory);
            // }

            formData.append("updated_by", loggedUserData.userLoginData.user_id);
            formData.append("is_active", true);
            setLoading(true);
            let res = await APICall(ApiTypes.addCategory, formData);
            if (res === false) {
              setLoading(false);
            } else {
              if (res.status === 1) {
                setLoading(false);

                toast.success("Category added successfully");
                navigate("/categories");
              } else {
                toast.error(res.message);
              }
            }
          }
        } else {
          setCatDlsEr("Details field is required.");
        }
      }
    }
  };

  useEffect(() => {
    if (selectedFile) {
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.addEventListener(
        "load",
        function () {
          setImageUrls([...imageUrls, reader.result]);
        },
        false
      );
      setSelectedFile(null);
    }
  }, [selectedFile]);

  // const fetchActiceCategories = async () => {
  //   setLoading(true)
  //   let res = await APICall(ApiTypes.fetchActiveCategories);
  //   if (res.status == 1) {
  //     setActiveCategories(res.data);
  //     setLoading(false);
  //   }
  //   else{
  //     setLoading(false)
  //   }
  // }
  const handleRemoveImage = (index) => {
    imageUrls.splice(index, 1);
    files.splice(index, 1);
    let FilesNew = [...files];
    let imageNew = [...imageUrls];
    setImageUrls(imageNew);
    setFiles(FilesNew);
  };

  useEffect(() => {
    // fetchActiceCategories();
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row mt-4">
          <div className="col-12">
            <div className="h4 mb-4">
              <div className="d-flex justify-content-between">
                <div className="d-flex flex-column justify-content-center">
                  <div className="d-flex breadcrum-navigation-title">
                    <div className="me-2">Add Category</div>
                    <div className="me-2 text-muted">|</div>
                    <div className="d-flex">
                      <div onClick={() => navigate('/home')} className="cursor-pointer custom-breadcrum-inactive me-2">Home</div>
                      <div className="text-muted me-2">&gt;</div>
                      <div onClick={() => navigate('/categories')} className="cursor-pointer custom-breadcrum-inactive me-2">Categories</div>
                      <div className="text-muted me-2">&gt;</div>
                      <div className="cursor-pointer custom-breadcrum-active me-2">Add Category</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ background: "#fff" }}>
              <Form className="py-4 px-3" onSubmit={(e) => handleSubmit(e)}>
                <div className="row">
                  <label
                    className="col-md-4 col-sm-2 col-lg-3 col-xl-2"
                    htmlFor="category"
                  >
                    Category <span className="validation">*</span>
                  </label>
                  <div className="col-md-8 col-sm-8 col-lg-9 col-xl-8 ">
                    <input
                      className="form-control"
                      type="text"
                      value={category}
                      onChange={(e) => {
                        handleCategory(e);
                      }}
                      id="category"
                      placeholder="Enter category"
                    />
                    {catEr != "" ? (
                      <div className="validation">*{catEr}</div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {/* <div className="d-flex mt-4">
                    <label className="col-md-2 col-sm-2 col-lg-2 col-xl-2" htmlFor="category">Parent Category</label>
                    <div className='col-md-8 col-sm-8 col-lg-8 col-xl-8 '>
                    <Form.Select className="col-md-8 col-sm-8 col-lg-8 col-xl-8 form-control" onChange={(e) => { handleActiveCategory(e) }} id="category" aria-label="Default select example">
                        <option value="0">Select main category</option>
                        {activeCategories.length != 0 ? activeCategories.map((activeCat, index) => {
                          return <option key={index} value={activeCat.category_id}>{activeCat.category_name}</option>
                        }) : ""}
                      </Form.Select>
                    </div>
                  </div> */}
                <div className="row mt-4">
                  <label
                    className="col-md-4 col-sm-2 col-lg-3 col-xl-2"
                    htmlFor="categoryDetails"
                  >
                    Category Details <span className="validation">*</span>
                  </label>
                  <div className="col-md-8 col-sm-8 col-lg-9 col-xl-8 ">
                    <textarea
                      className="col-md-8 col-sm-8 col-lg-8 col-xl-8 form-control"
                      value={categoryDetails}
                      onChange={(e) => {
                        handleCategoryDetails(e);
                      }}
                      id="categoryDetails"
                      rows="3"
                    ></textarea>
                    {catDlsEr !== "" ? (
                      <span className="validation">*{catDlsEr}</span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="row mt-4">
                  <label
                    className="col-md-4 col-sm-2 col-lg-3 col-xl-2"
                    htmlFor="categoryImage"
                  >
                    Category Image <span className="validation">*</span>{" "}
                  </label>
                  <div className="col-md-8 col-sm-8 col-lg-9 col-xl-8 ">
                    <div className="gridrow">
                      {imageUrls.length != 0
                        ? imageUrls.map((imgUrl, index) => {
                          return (
                            <div key={index} className="d-flex flex-column">
                              <img
                                className="image-category"
                                width="100px"
                                height="100px"
                                src={imgUrl}
                                alt=""
                              />
                              <button
                                type="button"
                                onClick={() => {
                                  handleRemoveImage(index);
                                }}
                                className="btn btn-primary"
                              >
                                {" "}
                                Remove Image{" "}
                              </button>
                            </div>
                          );
                        })
                        : ""}
                      <div>
                        <div className="position-relative d-flex flex-column justify-content-center">
                          <img
                            style={{ opacity: "0.5" }}
                            className="image-category"
                            width="100px"
                            height="100px"
                            src={blank}
                            alt=""
                          />
                          <div
                            onClick={() => {
                              inputFile.current.click();
                            }}
                            className="upload-icon"
                          >
                            <i
                              className="fa fa-plus-circle"
                              aria-hidden="true"
                            ></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <input
                      ref={inputFile}
                      onChange={(e) => {
                        handleFile(e);
                      }}
                      accept="image/*"
                      type="file"
                      className="mx-2 d-none form-control-file"
                      id="categoryImage"
                    />
                    {fileEr != "" ? (
                      <span className="validation">*{fileEr}</span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="row">
                  <label
                    className="col-md-4 col-sm-2 col-lg-3 col-xl-2"
                  ></label>
                  <div className="mt-4 col-md-8 col-sm-10 col-lg-9 col-xl-8 createbtn">
                    <button type="submit" className="btn btn-primary w-auto mb-2">
                      Create
                    </button>
                  </div>
                </div>
              </Form>
              {loading ? <SiteLoader /> : ""}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
