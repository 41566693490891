import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import "react-data-table-component-extensions/dist/index.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ApiTypes, AwsConstants } from "../../constants";
import APICall from "../../networking/AxiousServices";
import blank from "../../Assets/Image/Default/blank.png";
import { useSelector } from "react-redux";
import SiteLoader from "../../Layout/Loader/SiteLoader";
import { useParams } from "react-router-dom";
import S3 from "aws-sdk/clients/s3";
import { Credentials } from "aws-sdk";
import { TIME_TYPE } from "../../constants/Enumeration";
import imageCompression from 'browser-image-compression';

export default function EditPackage() {
  document.title = "Kalon | Edit Package";
  const access = new Credentials({
    accessKeyId: AwsConstants.accessKeyId,
    secretAccessKey: AwsConstants.secretAccessKey,
  });

  const s3 = new S3({
    credentials: access,
    region: AwsConstants.region,
    signatureVersion: "v4",
  });

  let { package_id } = useParams();
  let navigate = useNavigate();
  const loggedUserData = useSelector((state) => state.data);
  const inputFile = useRef(null);
  //Search Refs
  const searchServiceRef = useRef(null);
  const searchAddedServiceRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [imageUrls, setImageUrls] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  //Mains arrays
  const [activeServices, setActiveServices] = useState([]);
  const [addedServices, setAddedServices] = useState([]);
  //States when search
  const [searchService, setSearchService] = useState(false);
  const [searchAddedService, setSearchAddedService] = useState(false);
  const [searchedServices, setSearchedServices] = useState([]);
  const [searchedAddedServices, setSearchedAddedServices] = useState([]);


  //Form Values
  const [formValues, setFormValues] = useState({
    package_name: "",
    details: "",
    package_price: "",
    package_discount: "",
    select_Time: "",
    time_in_min: "",
    files: [],
    isBridal: false,
  });
  //Validations Errors
  const [packEr, setPackEr] = useState("");
  const [packDlsEr, setPackDlsEr] = useState("");
  const [priceEr, setPriceEr] = useState("");
  const [discountEr, setDiscountEr] = useState("");
  const [timeEr, setTimeEr] = useState("");
  const [fileEr, setFileEr] = useState("");
  const [addedSerEr, setAddedSerEr] = useState("");
  const [dropdown, setDropDown] = useState("");


  //Handle form controls
  const handlePackage = (value) => {
    setFormValues((prevState) => ({
      ...prevState,
      package_name: value,
    }));
  };

  const setChange = (event) => {
    if (event.target.value) {
      setDropDown(event.target.value)
    } else {
      setDropDown('')
    }
  }


  const handlePackageDetails = (value) => {
    setFormValues((prevState) => ({
      ...prevState,
      details: value,
    }));
  };
  const handlePrice = (value) => {
    if (value > 0) {
      setFormValues((prevState) => ({
        ...prevState,
        package_price: value,
      }));
    } else {
      setFormValues((prevState) => ({
        ...prevState,
        package_price: "",
      }));
    }
  };

  const handleDiscount = (value) => {
    if (value > 0) {
      setFormValues((prevState) => ({
        ...prevState,
        package_discount: value,
      }));
    } else {
      setFormValues((prevState) => ({
        ...prevState,
        package_discount: "",
      }));
    }
  };
  const handleTime = (value) => {
    if (value > 0) {
      setFormValues((prevState) => ({
        ...prevState,
        time_in_min: value,
      }));
    } else {
      setFormValues((prevState) => ({
        ...prevState,
        time_in_min: "",
      }));
    }
  };
  const handleFile = (file) => {
    if (file.name.match(/\.(jpg|jpeg|png)$/)) {
      setFileEr("");
      setSelectedFile(file);
      setFormValues((prevState) => ({
        ...prevState,
        files: [...formValues.files, file],
      }));
      inputFile.current.value = null;
    } else {
      setFileEr("Only jpg, jpeg and png files are supported.");
      setTimeout(() => {
        setFileEr("");
      }, 3000);
      inputFile.current.value = null;
    }
  };
  const handleIsOptional = (value, category_service_id) => {
    setAddedServices((prevState) =>
      prevState.map((preSer) => {
        if (preSer.category_service_id === category_service_id) {
          return {
            ...preSer,
            is_optional: parseInt(value) === 1 ? false : true,
          };
        }
        return preSer;
      })
    );
  };
  const handleSittingDays = (target, category_service_id, index) => {
    let value = target.value;
    if (value > 0) {
      if (formValues.isBridal) {
        setAddedServices((prevState) =>
          prevState.map((preSer) => {
            if (preSer.category_service_id === category_service_id) {
              return {
                ...preSer,
                day_sitting_no: parseInt(value),
              };
            }
            return preSer;
          })
        );
      } else {
        setAddedServices((prevState) =>
          prevState.map((preSer) => {
            if (preSer.category_service_id === category_service_id) {
              return {
                ...preSer,
                day_sitting_no: 1,
              };
            }
            return preSer;
          })
        );
      }
    } else {
      if (formValues.isBridal) {
        setAddedServices((prevState) =>
          prevState.map((preSer) => {
            if (preSer.category_service_id === category_service_id) {
              return {
                ...preSer,
                day_sitting_no: 0,
              };
            }
            return preSer;
          })
        );
      } else {
        setAddedServices((prevState) =>
          prevState.map((preSer) => {
            if (preSer.category_service_id == category_service_id) {
              return {
                ...preSer,
                day_sitting_no: 1,
              };
            }
            return preSer;
          })
        );
      }
    }

    setTimeout(() => {
      let SittingDays = document.getElementById(`sitting-days-${index}`);
      SittingDays.focus();
    }, 0);
  };

  const handleRemoveImage = async (index, imageUrl, package_picture_id) => {
    setLoading(true);
    formValues.files.splice(index, 1);
    let FilesNew = [...formValues.files];
    setFormValues((prevState) => ({
      ...prevState,
      files: FilesNew,
    }));
    if (!package_picture_id) {
      imageUrls.splice(index, 1);
      let imageNew = [...imageUrls];
      setImageUrls(imageNew);
      setLoading(false);
    } else {
      let data = { package_picture_id: package_picture_id };
      let res = await APICall(ApiTypes.removePackageImage, data);
      if (res.status == 1) {
        toast.success("Package picture deleted successfully.");
        fetchPackageDetails();
      }
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let fileNewArray = []
    for (let index = 0; index < formValues.files.length; index++) {
      let fileInner = formValues.files[index];
      if(!fileInner){
        fileNewArray = [...fileNewArray , fileInner]
      }else{
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true
        }
        try {
          const compressedFile = await imageCompression(fileInner, options);
          let fileNew = new File([compressedFile], fileInner.name);
          fileNewArray = [...fileNewArray, fileNew]
        } catch (error) {
          console.log(error);
        }
      }
     
    }
    if (formValues.package_name != "") {
      if (formValues.package_name.length > 3) {
        setPackEr("");
        if (formValues.details !== "") {
          setPackDlsEr("");
          if (fileNewArray.length > 0) {
            setFileEr("");
            if (addedServices.length != 0) {
              setAddedSerEr("");
              if (formValues.package_price != "") {
                if (!isNaN(formValues.package_price)) {
                  let TotalPrice = 0;
                  addedServices.forEach((adSer) => {
                    TotalPrice =
                      TotalPrice +
                      parseInt(adSer.service_price) -
                      parseInt(adSer.service_discount);
                  });
                  if (parseInt(formValues.package_price) >= TotalPrice) {
                    setPriceEr("");
                    if (formValues.package_discount != "") {
                      if (!isNaN(formValues.package_discount)) {
                        if (
                          parseInt(formValues.package_discount) <
                          parseInt(formValues.package_price)
                        ) {
                          setDiscountEr("");
                          // if (formValues.time_in_min != "") {
                          //   if (!isNaN(formValues.time_in_min)) {
                          //     setTimeEr("")
                          var formData = new FormData();
                          if (fileNewArray.length > 0) {
                            fileNewArray.forEach((fileInner) => {
                              if (fileInner) {
                                formData.append("file", fileInner);
                              }
                            });
                          } else {
                            formData.append("file", null);
                          }
                          if (!formData.get("file")) {
                            formData.append("file", null);
                          }
                          formData.append("package_id", package_id);
                          formData.append(
                            "package_name",
                            formValues.package_name
                          );
                          formData.append(
                            "package_price",
                            parseInt(formValues.package_price.trim())
                          );
                          formData.append("details", formValues.details.trim());
                          formData.append(
                            "package_discount",
                            parseInt(formValues.package_discount.trim())
                          );
                          if (formValues.time_in_min != "") {
                            formData.append(
                              "time_in_min",
                              parseInt(formValues.time_in_min)
                            );
                          } else {
                            formData.append("time_in_min", 0);
                          }
                          if (dropdown != "") {
                            formData.append(
                              "type_of_time",
                              dropdown
                            );
                          }
                          formData.append("is_active", true);
                          formData.append("is_highlight", true);
                          formData.append("is_user_can_modify", true);
                          formData.append("is_multi_day", formValues.isBridal);
                          formData.append(
                            "updated_by",
                            loggedUserData.userLoginData.user_id
                          );
                          formData.append("is_deleted", false);
                          formData.append(
                            "service_details",
                            JSON.stringify(addedServices)
                          );
                          setLoading(true);
                          let res = await APICall(
                            ApiTypes.updatePackage,
                            formData
                          );
                          if (res != false) {
                            toast.success("Package updated successfully");
                            navigate(-1);
                            setLoading(false);
                          } else {
                            setLoading(false);
                          }
                          //   }
                          //   else {
                          //     setTimeEr("Please only enter numeric value.")
                          //   }
                          // }
                          // else {
                          //   setTimeEr("Time field is required.")
                          // }
                        } else {
                          setDiscountEr("Discount must be less than price.");
                        }
                      } else {
                        setDiscountEr("Please only enter numeric value.");
                      }
                    } else {
                      setDiscountEr("Discount field is required.");
                    }
                  } else {
                    setPriceEr(
                      `Package price should be greater than or equal to total service price ₹ ${TotalPrice}.`
                    );
                  }
                } else {
                  setPriceEr("Please only enter numeric value.");
                }
              } else {
                setPriceEr("Price field is required.");
              }
            } else {
              setAddedSerEr("Minimum 1 service should be added.");
            }
          } else {
            setFileEr("Select image for the package.");
          }
        } else {
          setPackDlsEr("Details field is required.");
        }
      } else {
        setPackEr("Minimum 4 characters is required.");
      }
    } else {
      setPackEr("Package field is required.");
    }
  };

  //Add And Remove Service with and without search
  const handleAddService = (activeService, index) => {
    activeService["day_sitting_no"] = 1;
    activeService["is_optional"] = false;
    if (!searchService) {
      activeServices.splice(index, 1);
      let newActiveServices = [...activeServices];
      let newAddedServices = [...addedServices, activeService];
      setActiveServices(newActiveServices);
      setAddedServices(newAddedServices);
      let addedSelectDiv = document.getElementById("added-select-div");
      setTimeout(() => {
        addedSelectDiv.scrollTop = addedSelectDiv.scrollHeight;
      }, 100);
    } else {
      let newActiveServiceAfRe = activeServices.filter((actSer) => {
        if (actSer.category_service_id != activeService.category_service_id) {
          return actSer;
        }
      });
      setActiveServices(newActiveServiceAfRe);
      searchedServices.splice(index, 1);
      let newSeachedServices = [...searchedServices];
      setSearchedServices(newSeachedServices);
      let newAddedServices = [...addedServices, activeService];
      setAddedServices(newAddedServices);
      let addedSelectDiv = document.getElementById("added-select-div");
      setTimeout(() => {
        addedSelectDiv.scrollTop = addedSelectDiv.scrollHeight;
      }, 100);
    }
    searchAddedServiceRef.current.value = "";
    setSearchedAddedServices([]);
    setSearchAddedService(false);
  };
  const handleRemoveService = (addedService, index) => {
    if (!searchAddedService) {
      addedServices.splice(index, 1);
      let newAddedServices = [...addedServices];
      setAddedServices(newAddedServices);
      let newActiveServices = [...activeServices, addedService];
      setActiveServices(newActiveServices);
      let addSelectDiv = document.getElementById("add-select-div");
      setTimeout(() => {
        addSelectDiv.scrollTop = addSelectDiv.scrollHeight;
      }, 100);
    } else {
      let newAddedServiceAfRe = addedServices.filter((addedSer) => {
        if (addedSer.category_service_id != addedService.category_service_id) {
          return addedSer;
        }
      });
      setAddedServices(newAddedServiceAfRe);
      searchedAddedServices.splice(index, 1);
      let newSeachedAddedServices = [...searchedAddedServices];
      setSearchedAddedServices(newSeachedAddedServices);
      let newActiveServices = [...activeServices, addedService];
      setActiveServices(newActiveServices);
      let addedSelectDiv = document.getElementById("add-select-div");
      setTimeout(() => {
        addedSelectDiv.scrollTop = addedSelectDiv.scrollHeight;
      }, 100);
    }
    searchServiceRef.current.value = "";
    setSearchedServices([]);
    setSearchService(false);
  };

  //Handle searching of both active and selected services
  const handleSearchServices = (value) => {
    if (value.length > 0) {
      setSearchService(true);
      let filteredServices = activeServices.filter((activeService) => {
        let textTosearch = activeService.service_name.toLowerCase();
        let trimmedValue = value.trim();
        let valueToSearch = trimmedValue.toLowerCase();
        if (textTosearch.search(valueToSearch) >= 0) {
          return activeService;
        }
      });
      if (filteredServices.length == 0) {
        setSearchedServices([]);
      } else {
        setSearchedServices([...filteredServices]);
      }
    } else {
      setSearchService(false);
      setSearchedServices([]);
    }
  };
  const handleSearchAddedServices = (value) => {
    if (value.length > 0) {
      setSearchAddedService(true);
      let filteredServices = addedServices.filter((addedSer) => {
        let textTosearch = addedSer.service_name.toLowerCase();
        let trimmedValue = value.trim();
        let valueToSearch = trimmedValue.toLowerCase();
        if (textTosearch.search(valueToSearch) >= 0) {
          return addedSer;
        }
      });
      if (filteredServices.length == 0) {
        setSearchedAddedServices([]);
      } else {
        setSearchedAddedServices([...filteredServices]);
      }
    } else {
      setSearchAddedService(false);
      setSearchedAddedServices([]);
    }
  };

  const fetchActiveServices = async () => {
    setLoading(true);
    let res = await APICall(ApiTypes.fetchActiveServices);
    if (res.status == 1) {
      setActiveServices(res.data);
      setLoading(false);
    } else {
      setLoading(false);
    }
    return res.data;
  };
  useEffect(() => {
    if (selectedFile) {
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.addEventListener(
        "load",
        function () {
          setImageUrls([
            ...imageUrls,
            { url: reader.result, package_picture_id: null },
          ]);
          // setImageUrls([...imageUrls, reader.result]);
        },
        false
      );
    }
  }, [selectedFile]);

  const getImageUrls = async (response) => {
    let newArray = [];
    if (response.packagePictures) {
      if (response.packagePictures.length != 0) {
        let iUrls = [];
        let newfiles = [];
        for (let index = 0; index < response.packagePictures.length; index++) {
          const picture = response.packagePictures[index];
          const url = await s3.getSignedUrlPromise("getObject", {
            Bucket: AwsConstants.Bucket,
            Key: `Home/Packages/${response.package_id}/${picture.display_pic}`,
            Expires: AwsConstants.signedUrlExpireSeconds,
          });
          iUrls.push({
            url: url,
            package_picture_id: picture.package_picture_id,
          });
       
          newfiles = [...newfiles, false];
          // setFormValues((prevState) => ({
          //   ...prevState,
          //   files: newfiles,
          // }));
          response["newfiles"] = response["newfiles"] ? [...response["newfiles"] , false] :[false];
        }
        setImageUrls(iUrls);
        // setFiles(newfiles)
        response["imageUrls"] = iUrls;
      } else {
        response["imageUrl"] = null;
        setImageUrls([]);
      }
    } else {
      response["imageUrl"] = null;
      setImageUrls([]);
    }
    // newArray.push(response);
    newArray = [{...response}]
    return newArray;
  };

  const fetchPackageDetails = async (resActiveServices) => {
    setLoading(true);
    let res = await APICall(
      ApiTypes.fetchPackage,
      null,
      `package_id=${package_id}`
    );
    let data = res.data;
    if (res.status == 1) {
      let newArray = await getImageUrls(data);
      let newServices = [];
      newArray[0].PackageServiceMappingDetails.forEach((servicemap) => {
        if (servicemap.day_sitting_no) {
          servicemap.ServiceDetails["day_sitting_no"] = parseInt(
            servicemap.day_sitting_no
          );
        } else {
          servicemap.ServiceDetails["day_sitting_no"] = 1;
        }
        servicemap.ServiceDetails["is_optional"] = servicemap.is_optional;
        newServices.push(servicemap.ServiceDetails);
      });
      if (newServices.length > 0) {
        setAddedServices(newServices);
      }
      let newActiveServices = [];
      resActiveServices.forEach((resActiveService) => {
        let isAdded = false;
        newServices.forEach((newSerbig) => {
          if (
            parseInt(newSerbig.category_service_id) ==
            parseInt(resActiveService.category_service_id)
          ) {
            isAdded = true;
          }
        });
        if (!isAdded) {
          newActiveServices.push(resActiveService);
        }
        // if(isAvailable){
        //   return resActiveService;
        // }
      });
      setActiveServices(newActiveServices);
      setFormValues((prevState) => ({
        ...prevState,
        files : newArray[0].newfiles,
        package_name: newArray[0].package_name,
        details: newArray[0].details ? newArray[0].details : "",
        package_price: newArray[0].package_price
          ? newArray[0].package_price
          : "",
        package_discount: newArray[0].package_discount
          ? newArray[0].package_discount
          : "",
        time_in_min: newArray[0].time_in_min ? newArray[0].time_in_min : "",
        isBridal: newArray[0].is_multi_day ? true : false,
      }));
      if (newArray[0].type_of_time) {
        setDropDown(newArray[0].type_of_time)
      }

      setLoading(false);
    } else {
      setLoading(false);
    }
  };
  useEffect(() => {
    const getStart = async () => {
      let res = await fetchActiveServices();
      if (res) {
        fetchPackageDetails(res);
      }
    };
    getStart();
  }, []);

  const ServiceComponent = ({ sers }) => {
    if (sers.length > 0) {
      return sers.map((ser, index) => {
        return (
          <div key={index} className="col-12">
            <div
              draggable={true}
              onDrag={(e) => e.preventDefault()}
              onDragStart={(e) => {
                e.dataTransfer.setData(
                  "addServiceData",
                  JSON.stringify({ object: ser, index: index })
                );
              }}
              className="selection-service-div"
            >
              <div className="selection-service-values d-flex">
                <div className="d-flex justify-content-between servive-details">
                  <div>{ser.service_name}</div>
                  <div>
                    <i className="fa fa-inr" aria-hidden="true"></i>{" "}
                    {ser.service_price}
                  </div>
                </div>
                <div className="add-service-div">
                  <button
                    type="button"
                    onClick={() => {
                      handleAddService(ser, index);
                    }}
                    className="btn btn-primary"
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else {
      return <div className="empty-services">No records found</div>;
    }
  };

  const AddedServiceComponent = ({ addedsers }) => {
    if (addedsers.length > 0) {
      return addedsers.map((addedser, index) => {
        return (
          <div key={index} className="col-12">
            <div
              draggable={true}
              onDragStart={(e) => {
                e.dataTransfer.setData(
                  "removeServiceData",
                  JSON.stringify({ object: addedser, index: index })
                );
              }}
              className="selection-service-div"
            >
              <div className="selection-service-values d-flex">
                <div className="d-flex justify-content-between servive-details">
                  <div>{addedser.service_name}</div>
                  <div>
                    <i className="fa fa-inr" aria-hidden="true"></i>{" "}
                    {addedser.service_price}
                  </div>
                </div>
                <div className="add-service-div">
                  <button
                    type="button"
                    onClick={() => {
                      handleRemoveService(addedser, index);
                    }}
                    className="btn btn-primary"
                  >
                    Remove
                  </button>
                </div>
              </div>

              <div className="selection-service-values d-flex package-days-div">
                <div
                  className="d-flex"
                  style={{ padding: "1% 4% 1% 2%", width: "66%" }}
                >
                  <span className="w-50">Sitting Day : </span>
                  <input
                    readOnly={!formValues.isBridal}
                    onWheelCapture={(e) => {
                      e.target.blur();
                    }}
                    id={`sitting-days-${index}`}
                    onChange={(e) => {
                      handleSittingDays(
                        e.target,
                        addedser.category_service_id,
                        index
                      );
                    }}
                    value={addedser.day_sitting_no}
                    className="form-control"
                    type="number"
                  />
                </div>
                <div
                  className="d-flex"
                  style={{ padding: "1% 4% 1% 2%", width: "34%" }}
                >
                  <span>Is Optional :</span>
                  <div className="mx-2">
                    <input
                      checked={addedser.is_optional}
                      onChange={(e) => {
                        handleIsOptional(
                          e.target.value,
                          addedser.category_service_id
                        );
                      }}
                      value={addedser.is_optional ? 1 : 0}
                      type="checkbox"
                      className="form-check-input"
                    />
                  </div>
                </div>
              </div>
              {/* <div className='selection-service-values d-flex'>
                        <div className='d-flex w-50'>
                            <span>Sitting Day :  </span><input className='form-control' type="number" />
                        </div>
                        <div className='d-flex w-50'>
                            <span>Is Optional </span><FormCheck></FormCheck>
                        </div>
                    </div> */}
            </div>
          </div>
        );
      });
    } else {
      return (
        <div className="empty-services">
          {searchAddedService
            ? "No records found"
            : "You have not selected any service yet."}
        </div>
      );
    }
  };

  return (
    <div className="container-fluid">
      <div className="row mt-4">
        <div className="col-12">
          <div className="h4 mb-4">
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-column justify-content-center">
                <div className="d-flex breadcrum-navigation-title">
                  <div className="me-2">Edit Package</div>
                  <div className="me-2 text-muted">|</div>
                  <div className=" d-flex">
                    <div
                      onClick={() => navigate("/home")}
                      className="cursor-pointer custom-breadcrum-inactive me-2"
                    >
                      Home
                    </div>
                    <div className="text-muted me-2">&gt;</div>
                    <div
                      onClick={() => navigate("/packages")}
                      className="cursor-pointer custom-breadcrum-inactive me-2"
                    >
                      Packages
                    </div>
                    <div className="text-muted me-2">&gt;</div>
                    <div className="cursor-pointer custom-breadcrum-active me-2">
                      Edit Package
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ background: "#fff" }}>
            <Form
              onSubmit={(e) => {
                handleSubmit(e);
              }}
              className="py-4 px-3"
            >
              <div className="row ">
                <label
                  className="col-md-4 col-sm-2 col-lg-3 col-xl-2"
                  htmlFor="package"
                >
                  Package <span className="validation">*</span>
                </label>
                <div className="col-md-8 col-sm-8 col-lg-9 col-xl-8 ">
                  <input
                    type="text"
                    value={formValues.package_name}
                    onChange={(e) => {
                      handlePackage(e.target.value);
                    }}
                    className="form-control"
                    id="package"
                    placeholder="Enter package"
                  />
                  {packEr != "" ? (
                    <span className="validation">*{packEr}</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row mt-4">
                <label
                  className="col-md-4 col-sm-2 col-lg-3 col-xl-2"
                  htmlFor="package-details"
                >
                  Package Details <span className="validation">*</span>
                </label>
                <div className="col-md-8 col-sm-8 col-lg-9 col-xl-8 ">
                  <textarea
                    value={formValues.details}
                    onChange={(e) => {
                      handlePackageDetails(e.target.value);
                    }}
                    className="form-control"
                    id="package-details"
                    rows="3"
                  ></textarea>
                  {packDlsEr != "" ? (
                    <span className="validation">*{packDlsEr}</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              {/* <div className="d-flex mt-4">
                  <label
                    className="col-md-2 col-sm-2 col-lg-2 col-xl-2"
                    htmlFor="is-bridal"
                  >
                    Is Bridal Package{" "}
                  </label>
                  <div className="col-md-8 col-sm-8 col-lg-8 col-xl-8 ">
                    <input
                      checked={formValues.isBridal}
                      value={formValues.isBridal ? 1 : 0}
                      onChange={(e) => {
                        setFormValues((prevState) => ({
                          ...prevState,
                          isBridal: e.target.value == 0 ? true : false,
                        }));
                        if (e.target.value == 1) {
                          setAddedServices((prevState) =>
                            prevState.map((preSer) => {
                              return {
                                ...preSer,
                                day_sitting_no: 1,
                              };
                            })
                          );
                        }
                      }}
                      type="checkbox"
                      className="form-check-input"
                    />
                  </div>
                </div> */}
              <div className="row mt-4">
                <label
                  className="col-md-4 col-sm-2 col-lg-3 col-xl-2"
                  htmlFor="packageImage"
                >

                  Package Image
                </label>
                <div className="col-md-8 col-sm-8 col-lg-9 col-xl-8 ">
                  <div className="gridrow">
                    {imageUrls.length != 0
                      ? imageUrls.map((img, index) => {
                        return (
                          <div key={index} className="d-flex flex-column">
                            <img
                              className="image-category"
                              widht="100px"
                              height="100px"
                              src={img.url}
                              alt=""
                            />
                            <button
                              type="button"
                              onClick={() => {
                                handleRemoveImage(
                                  index,
                                  img.url,
                                  img.package_picture_id
                                );
                              }}
                              className="btn btn-primary"
                            >
                              {" "}
                              Remove Image{" "}
                            </button>
                          </div>
                        );
                      })
                      : ""}
                    <div>
                      <div className="position-relative d-flex flex-column justify-content-center">
                        <img
                          style={{ opacity: "0.5" }}
                          className="image-category"
                          widht="100px"
                          height="100px"
                          src={blank}
                          alt=""
                        />
                        <div
                          onClick={() => {
                            inputFile.current.click();
                          }}
                          className="upload-icon"
                        >
                          <i className="fa fa-plus-circle" aria-hidden="true"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <input
                    ref={inputFile}
                    onChange={(e) => {
                      handleFile(e.target.files[0]);
                    }}
                    accept="image/*"
                    type="file"
                    className="mx-2 d-none form-control-file"
                    id="categoryImage"
                  />
                  {fileEr != "" ? (
                    <span className="validation">*{fileEr}</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="px-3 col-sm-12 col-md-12 col-lg-12 col-xl-10">
                <div className="row mt-4 selection-div">
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6 selection-header-first">
                    <div className="selection-header mb-3">
                      Services{" "}
                    </div>
                    <input
                      type="text"
                      ref={searchServiceRef}
                      onChange={(e) => handleSearchServices(e.target.value)}
                      className="form-control"
                      placeholder="Search services"
                    ></input>
                    <div
                      onDragOver={(e) => e.preventDefault()}
                      onDrop={(e) => {
                        if (e.dataTransfer.getData("removeServiceData")) {
                          let data = JSON.parse(
                            e.dataTransfer.getData("removeServiceData")
                          );
                          handleRemoveService(data.object, data.index);
                        }
                      }}
                      id="add-select-div"
                      className="selection-services"
                    >
                      <div className="row">
                        {searchService ? (
                          <ServiceComponent sers={searchedServices} />
                        ) : (
                          <ServiceComponent sers={activeServices} />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6 selection-header-top">
                    <div className="selection-header mb-3">
                      Selected Services <span className="validation">*</span>
                    </div>
                    <input
                      type="text"
                      ref={searchAddedServiceRef}
                      onChange={(e) => handleSearchAddedServices(e.target.value)}
                      className="form-control"
                      placeholder="Search selected services"
                    ></input>
                    <div
                      onDragOver={(e) => e.preventDefault()}
                      onDrop={(e) => {
                        if (e.dataTransfer.getData("addServiceData")) {
                          let data = JSON.parse(
                            e.dataTransfer.getData("addServiceData")
                          );
                          handleAddService(data.object, data.index);
                        }
                      }}
                      id="added-select-div"
                      className="selection-services"
                    >
                      <div className="row">
                        {searchAddedService ? (
                          <AddedServiceComponent addedsers={searchedAddedServices} />
                        ) : (
                          <AddedServiceComponent addedsers={addedServices} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {addedSerEr != "" ? (
                <div className="validation text-center mt-2">*{addedSerEr}</div>
              ) : (
                ""
              )}
              <div className="row mt-4">
                <label
                  className="col-md-4 col-sm-2 col-lg-3 col-xl-2"
                  htmlFor="price"
                >
                  <i className="fa fa-inr" aria-hidden="true"></i> Price{" "}
                  <span className="validation">*</span>
                </label>
                <div className="col-md-8 col-sm-8 col-lg-9 col-xl-8 ">
                  <input
                    type="number"
                    onWheelCapture={(e) => {
                      e.target.blur();
                    }}
                    value={formValues.package_price}
                    onChange={(e) => {
                      handlePrice(e.target.value);
                    }}
                    className="form-control"
                    id="price"
                    placeholder="Enter price"
                  />
                  {priceEr != "" ? (
                    <span className="validation">*{priceEr}</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row mt-4">
                <label
                  className="col-md-4 col-sm-2 col-lg-3 col-xl-2"
                  htmlFor="discount"
                >
                  <i className="fa fa-inr" aria-hidden="true"></i> Discount{" "}
                  <span className="validation">*</span>
                </label>
                <div className="col-md-8 col-sm-8 col-lg-9 col-xl-8 ">
                  <input
                    type="number"
                    onWheelCapture={(e) => {
                      e.target.blur();
                    }}
                    value={formValues.package_discount}
                    onChange={(e) => {
                      handleDiscount(e.target.value);
                    }}
                    className="form-control"
                    id="discount"
                    placeholder="Enter price"
                  />
                  {discountEr != "" ? (
                    <span className="validation">*{discountEr}</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row mt-4">
                <label
                  className="col-md-4 col-sm-2 col-lg-3 col-xl-2"
                  htmlFor="time"
                >
                  Time Type
                </label>
                <div className="col-md-8 col-sm-8 col-lg-9 col-xl-8 ">
                  <select onChange={(e) => setChange(e)} className="form-select" aria-label="Default select example">
                    <option selected={dropdown == '' ? true : false} value="">Select Time</option>
                    {Object.keys(TIME_TYPE).map((time_enum, index) => {
                      return <option key={index} selected={dropdown == time_enum ? true : false} value={time_enum} >{TIME_TYPE[time_enum]}</option>
                    })}
                    {/* <option value="Hours" >Hours</option>
                  <option value="Minutes">Minutes</option>
                  <option value="Seconds">Seconds</option> */}
                  </select>
                </div>
              </div>

              {dropdown != "" ? <div className="row mt-4">
                <label
                  className="col-md-4 col-sm-2 col-lg-3 col-xl-2"
                  htmlFor="time"
                >
                  Time <span style={{ fontWeight: 500 }}>({TIME_TYPE[dropdown]})</span>
                </label>
                <div className="col-md-8 col-sm-8 col-lg-9 col-xl-8 ">
                  <input
                    type="number"
                    onWheelCapture={(e) => {
                      e.target.blur();
                    }}
                    value={formValues.time_in_min}
                    onChange={(e) => {
                      handleTime(e.target.value);
                    }}
                    className="form-control"
                    id="time"
                    placeholder="Enter time"
                  />
                  {timeEr != "" ? (
                    <span className="validation">*{timeEr}</span>
                  ) : (
                    ""
                  )}
                </div>
              </div> : ""}
              <div className="row">
                <label
                  className="col-md-4 col-sm-2 col-lg-3 col-xl-2"
                ></label>
                <div className="mt-4 col-md-8 col-sm-8 col-lg-9 col-xl-8 createbtn">
                  <button type="submit" className="btn btn-primary w-auto mb-2">
                    Update
                  </button>
                </div>
              </div>
            </Form>
            {loading ? <SiteLoader /> : ""}
          </div>
        </div>
      </div>
    </div>
  );
}
