
const BaseURL = process.env.REACT_APP_API_URL
const accessKeyId = process.env.REACT_APP_ACCESS_KEY
const secretAccessKey = process.env.REACT_APP_SECRET_ACCESS_KEY
const region = process.env.REACT_APP_REGION
const Bucket = process.env.REACT_APP_BUCKET
const signedUrlExpireSeconds = process.env.REACT_APP_SIGNED_URL_EXPIRY

const Constants = {
  BaseURL: BaseURL,
  accessKeyId: accessKeyId,
  secretAccessKey: secretAccessKey,
  region: region,
  Bucket: Bucket,
  signedUrlExpireSeconds: parseInt(signedUrlExpireSeconds),
};

export default Constants;