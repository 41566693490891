import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import "react-data-table-component-extensions/dist/index.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ApiTypes, AwsConstants } from "../../../constants";
import APICall from "../../../networking/AxiousServices";
import blank from "../../../Assets/Image/Default/blank.png";
import { useSelector } from "react-redux";
import SiteLoader from "../../../Layout/Loader/SiteLoader";
import S3 from "aws-sdk/clients/s3";
import { Credentials } from "aws-sdk";
import { useParams } from "react-router-dom";

export default function EditShopAdmin() {
    document.title = "Kalon | Edit Shop Admin";
    const access = new Credentials({
        accessKeyId: AwsConstants.accessKeyId,
        secretAccessKey: AwsConstants.secretAccessKey,
    });

    const s3 = new S3({
        credentials: access,
        region: AwsConstants.region,
        signatureVersion: "v4",
    });

    let { parlour_id } = useParams();

    let navigate = useNavigate();

    const loggedUserData = useSelector((state) => state.data);

    const [loading, setLoading] = useState(false);

    //Form Values
    const [formValues, setFormValues] = useState({
        parlour_name: "", //validation is required
        contactno_1: "", //validation is required
        email_id: "", //validation is required
        //validation is required
    });
    //Validations Errors
    const [parlourNameEr, setParlourNameEr] = useState("");
    const [emailIdEr, setEmailIdEr] = useState("");
    const [contactEr, setContactEr] = useState("");


    //Handle form inputs
    const handleParlourName = (value) => {
        setFormValues((prevState) => ({
            ...prevState,
            parlour_name: value,
        }));
    };

    const handleFirstContact = (value) => {
        if (value > 0) {
            setFormValues((prevState) => ({
                ...prevState,
                contactno_1: value,
            }));
        } else {
            setFormValues((prevState) => ({
                ...prevState,
                contactno_1: "",
            }));
        }
    };

    const handleEmail = (value) => {
        setFormValues((prevState) => ({
            ...prevState,
            email_id: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (formValues.parlour_name != "") {
            if (formValues.parlour_name.length > 3) {
                setParlourNameEr("");
                if (formValues.contactno_1) {
                    if (formValues.contactno_1.length >= 10) {
                        setContactEr("");
                        if (formValues.email_id != "") {
                            if (
                                formValues.email_id.match(
                                    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                )
                            ) {
                                setEmailIdEr("");
                                // var formData = new FormData();
                                // formValues.files.forEach((fileInner) => {
                                //     formData.append("file", fileInner);
                                // });
                                // formData.append("parlour_id", parlour_id);
                                // formData.append("parlour_name", formValues.parlour_name);
                                // formData.append(
                                //     "contactno_1",
                                //     formValues.contactno_1.trim()
                                // );

                                // formData.append(
                                //     "updated_by",
                                //     loggedUserData.userLoginData.user_id
                                // );
                                let data = {
                                    parlour_id: parlour_id,
                                    parlour_name: formValues.parlour_name.trim(),
                                    contactno_1: formValues.contactno_1.trim(),
                                    email_id: formValues.email_id.trim(),
                                    updated_by: loggedUserData.userLoginData.user_id
                                }
                                setLoading(true);
                                let res = await APICall(ApiTypes.updateParlour, data);
                                if (res != false) {
                                    toast.success("Parlour updated successfully");
                                    navigate("/shopadmins");
                                    setLoading(false);
                                } else {
                                    setLoading(false);
                                }
                            } else {
                                setEmailIdEr("Please enter valid email id.");
                            }
                        } else {
                            setEmailIdEr("Email field is required.");
                        }
                    } else {
                        setContactEr("Atleast 10 characters are required.");
                    }

                } else {
                    setContactEr("Contact 1 field is required.");
                }
            } else {
                setParlourNameEr("Minimum 4 characters is required.");
            }
        } else {
            setParlourNameEr("Parlour field is required.");
        }
    };

    const fetchParlour = async () => {
        setLoading(true);
        let res = await APICall(
            ApiTypes.fetchParlour,
            null,
            `parlour_id=${parlour_id}`
        );
        let data = res.data;
        if (res.status == 1) {
            let newArray = data[0];
            setFormValues((prevState) => ({
                ...prevState,
                parlour_name: newArray.parlour_name,
                contactno_1: newArray.contactno_1,
                email_id: newArray.email_id,
            }));
            setLoading(false);
            setLoading(false);
        } else {
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchParlour();
    }, []);
    return (
        <div className="container-fluid">
            <div className="row mt-4">
                <div className="col-12">
                    <div className="h4 mb-4">
                        <div className="d-flex justify-content-between">
                            <div className="d-flex flex-column justify-content-center">
                                <div className="d-flex breadcrum-navigation-title">
                                    <div className="me-2">Edit Shop Admin</div>
                                    <div className="me-2 text-muted">|</div>
                                    <div className="-warning d-flex">
                                        <div
                                            onClick={() => navigate("/home")}
                                            className="cursor-pointer custom-breadcrum-inactive me-2"
                                        >
                                            Home
                                        </div>
                                        <div className="text-muted me-2">&gt;</div>
                                        <div
                                            onClick={() => navigate("/shopadmins")}
                                            className="cursor-pointer custom-breadcrum-inactive me-2"
                                        >
                                            Shop Admins
                                        </div>
                                        <div className="text-muted me-2">&gt;</div>
                                        <div className="cursor-pointer custom-breadcrum-active me-2">
                                            Edit Shop Admin
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ background: "#fff" }}>
                        <Form
                            onSubmit={(e) => {
                                handleSubmit(e);
                            }}
                            className="py-4 px-3"
                        >
                            <div className="row ">
                                <label
                                    className="col-md-4 col-sm-2 col-lg-3 col-xl-2"
                                    htmlFor="parlour"
                                >
                                    Parlour <span className="validation">*</span>
                                </label>
                                <div className="col-md-8 col-sm-8 col-lg-9 col-xl-8 ">
                                    <input
                                        type="text"
                                        value={formValues.parlour_name}
                                        onChange={(e) => {
                                            handleParlourName(e.target.value);
                                        }}
                                        className="form-control"
                                        id="parlour"
                                        placeholder="Enter parlour"
                                    />
                                    {parlourNameEr != "" ? (
                                        <span className="validation">*{parlourNameEr}</span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>

                            <div className="row mt-4">
                                <label
                                    className="col-md-4 col-sm-2 col-lg-3 col-xl-2"
                                    htmlFor="contactno_1"
                                >
                                    Mobile No <span className="validation">*</span>
                                </label>
                                <div className="col-md-8 col-sm-8 col-lg-9 col-xl-8 ">
                                    <input
                                        type="number"
                                        onWheelCapture={(e) => {
                                            e.target.blur();
                                        }}
                                        value={formValues.contactno_1}
                                        onChange={(e) => {
                                            handleFirstContact(e.target.value);
                                        }}
                                        className="form-control"
                                        id="contactno_1"
                                        placeholder="Enter mobile number"
                                    />
                                    {contactEr != "" ? (
                                        <span className="validation">*{contactEr}</span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                            <div className="row mt-4">
                                <label
                                    className="col-md-4 col-sm-2 col-lg-3 col-xl-2"
                                    htmlFor="email_id"
                                >
                                    Email <span className="validation">*</span>
                                </label>
                                <div className="col-md-8 col-sm-8 col-lg-9 col-xl-8 ">
                                    <input
                                        type="email"
                                        value={formValues.email_id}
                                        onChange={(e) => {
                                            handleEmail(e.target.value);
                                        }}
                                        className="form-control"
                                        id="email_id"
                                        placeholder="Enter email"
                                    />
                                    {emailIdEr != "" ? (
                                        <span className="validation">*{emailIdEr}</span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>

                            <div className="row">
                                <label className="col-md-4 col-sm-2 col-lg-3 col-xl-2"></label>
                                <div className="mt-4 col-md-8 col-sm-8 col-lg-9 col-xl-8 createbtn">
                                    <button type="submit" className="btn btn-primary w-auto mb-2">
                                        Update
                                    </button>
                                </div>
                            </div>
                        </Form>
                        {loading ? <SiteLoader /> : ""}
                    </div>
                </div>
            </div>
        </div>
    );
}
