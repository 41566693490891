import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";

function Footer() {
  return (
    <footer id="footer">
      <div className="footer">
        <div className="left-side">
          <div className="copyright-class">
            <p className="m-0">Copyright © {(new Date().getFullYear())} Kalon</p>
          </div>
        </div>
        <div className="right-side">
          <div className="copyright-class">
            <p style={{ cursor :"pointer" }} onClick={()=>{window.open("https://www.softqubes.com",'__blank')}} className="m-0">Powered by Softqube Technologies Pvt. Ltd.</p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
