const initialState = {
  userLoginData: JSON.parse(localStorage.getItem("loggedUser")),
  isLoggedIn: localStorage.getItem("isLoggedIn") ? true : false,
};
const userDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGGED_USER":
      return {
        ...state,
        userLoginData: action.payload,
      };
    case "IS_LoggedIn":
      return {
        ...state,
        isLoggedIn: action.payload,
      };
    default:
      return state;
  }
};
export default userDataReducer;
