import React, { useEffect, useState, useRef } from "react";
import DataTable from "react-data-table-component";
import "react-data-table-component-extensions/dist/index.css";
import APICall from "../../../networking/AxiousServices";
import { ApiTypes, AwsConstants } from "../../../constants";
import { Button } from "react-bootstrap";
import SwitchButton from "../../../Layout/Components/SwitchButton";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import blank from "../../../Assets/Image/Default/blank.png";
import { Credentials, S3 } from "aws-sdk";
import CustomTableLoader from "../../../Layout/Loader/CustomTableLoader";

export default function Posts() {
  document.title = "Kalon | Posts";
  let navigate = useNavigate();

  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);

  const [totalRows, setTotalRows] = useState(null);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const textInput = useRef(null);

  const access = new Credentials({
    accessKeyId: AwsConstants.accessKeyId,
    secretAccessKey: AwsConstants.secretAccessKey,
  });

  const s3 = new S3({
    credentials: access,
    region: AwsConstants.region,
    signatureVersion: "v4",
  });

  const handlePost = (post_id) => {
    navigate(`/post/view/${post_id}`);
  };

  const resetOrders = () => { };
  const getImageUrls = async (response) => {
    let newArray = [];
    for (let i = 0; i < response.length; i++) {
      if (response[i].postMediaDetails) {
        if (response[i].postMediaDetails.length !== 0) {
          const url = await s3.getSignedUrlPromise("getObject", {
            Bucket: AwsConstants.Bucket,
            Key: `Home/SocialMedia/Post/${response[i].post_id}/${response[i].postMediaDetails[0].media_name}`,
            Expires: AwsConstants.signedUrlExpireSeconds,
          });
          response[i]["imageUrl"] = url;
        } else {
          response[i]["imageUrl"] = null;
        }
      } else {
        response[i]["imageUrl"] = null;
      }
      response[i]["index"] = i;
      newArray.push(response[i]);
    }
    return newArray;
  };

  const changeStatus = async (value, row) => {
    setOrders((prevState) =>
      prevState.map((mapItem) => {
        if (row.post_id === mapItem.post_id) {
          return {
            ...mapItem,
            is_active: value,
          };
        }
        return mapItem;
      })
    );
    let data = { post_id: row.post_id, is_active: value };
    let res = await APICall(ApiTypes.changePostStatus, data);
    if (res.status == 1) {
      if (value == false) {
        toast.success("post is disabled successfully.");
      } else {
        toast.success("post is enabled successfully.");
      }
    }
  };

  const fetchPosts = async () => {
    setLoading(true);
    const bodyData = {
      search_keyword: textInput.current.value ? textInput.current.value : "",
    };

    let res = await APICall(
      ApiTypes.fetchPosts,
      bodyData,
      `page=${page - 1}&size=${perPage}`
    );
    if (res.status === 1) {
      res.data.list.forEach((listItem, index) => {
        listItem["index"] = index;
      });
      setTotalRows(res.data.totalItems);

      setLoading(false);
      if (res.status === 1) {
        let newArray = await getImageUrls(res.data.list);
        setOrders(newArray);
        setLoading(false);
      }
    } else if (res.status === 0) {
      setLoading(false);
      toast.error(res.message ? res.message : "Something went wrong.");
    }
  };


  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage, pageGot) => {
    setPerPage(newPerPage);
    setPage(pageGot);
  };

  useEffect(() => {
    fetchPosts();
  }, [page, perPage]);

  const columns = [
    {
      name: "Sr. No.",
      selector: (row) => row.index + 1,
      sortable: true,
      width: "100px",
    },
    {
      name: "Image",
      width: "100px",
      selector: (row) =>
        row.imageUrl ? (
          <img className="custom-image" src={row.imageUrl} onError={(e) => { e.target.setAttribute('src', blank) }} alt="" ></img >
        ) : (
          <img className="custom-image" src={blank} alt=""></img>
        ),
    },
    {
      name: "Post By",
      selector: (row) => row?.postMemberProfile?.display_name,
      sortable: true,
    },
    {
      name: "Title",
      selector: (row) => row.post_heading,
      sortable: true,
    },
    {
      name: "Reaction",
      selector: (row) => row.total_reactions,
      sortable: true,
      width: "110px",
    },
    {
      name: "Comment",
      selector: (row) => row.total_comments,
      sortable: true,
      width: "110px",
    },
    {
      name: "Is Active",
      width: "100px",
      selector: (row) => (
        <SwitchButton
          isActive={row.is_active}
          chageStatus={changeStatus}
          row={row}
        />
      ),
      sortable: true,
    },
    {
      name: "Action",
      sortable: false,
      width: "90px",
      selector: (row) => (
        <>
          <span
            onClick={() => {
              handlePost(row.post_id);
            }}
            className="eye-icon"
          >
            <i className="fas fa-eye"></i>
          </span>
          {/* <Button height="24px" width="24px" variant="danger">
            <i className="fa fa-trash" aria-hidden="true"></i>
          </Button> */}
        </>
      ),
    },
  ];

  return (
    <div className="container-fluid">
      <div className="row mt-4">
        <div className="col-12">
          <div className="h4 mb-4">
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-column justify-content-center">
                <div className="d-flex breadcrum-navigation-title">
                  <div className="me-2">Posts</div>
                  <div className="me-2 text-muted">|</div>
                  <div className=" d-flex">
                    <div
                      onClick={() => navigate("/home")}
                      className="cursor-pointer custom-breadcrum-inactive me-2"
                    >
                      Home
                    </div>
                    <div className="text-muted me-2">&gt;</div>
                    <div className="cursor-pointer custom-breadcrum-active me-2">
                      Posts
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex gap-2 col-4 align-items-center mb-4">
            <input
              ref={textInput}
              onInput={fetchPosts}
              type="search"
              className="form-control "
              placeholder="Search..."
            />
          </div>
          <div className="table-outer react-table">
            <DataTable
              data={orders}
              columns={columns}
              persistTableHead={true}
              noHeader
              paginationServer
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              defaultSortField="id"
              defaultSortAsc={true}
              pagination
              highlightOnHover
              progressPending={loading}
              progressComponent={<CustomTableLoader />}
              responsive={true}
            />
          </div>
        </div>
      </div>
    </div>

  );
};
