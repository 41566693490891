import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import APICall from "../../networking/AxiousServices";
import { ApiTypes, AwsConstants } from "../../constants";
import S3 from "aws-sdk/clients/s3";
import { Credentials } from "aws-sdk";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import blank from "../../Assets/Image/Default/blank.png";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import SiteLoader from "../../Layout/Loader/SiteLoader";
import "react-data-table-component-extensions/dist/index.css";
import { BANNER_SCREEN } from "../../constants/Enumeration";
import imageCompression from 'browser-image-compression';

export default function EditBanner() {

    document.title = "Kalon | Edit Banner";

    let { banner_id } = useParams();
    let navigate = useNavigate();
    const loggedUserData = useSelector((state) => state.data);
    const inputFile = useRef(null);
    const [bannerTitle, setBannerTitle] = useState("");
    const [bannerDetails, setBannerDetails] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [bannerTitleEr, setBannerTitleEr] = useState("");
    const [bannerDesEr, setBannerDesEr] = useState("");
    const [fileEr, setFileEr] = useState("");
    // const [imageUrls, setImageUrls] = useState([]);
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [bannerScreen, setBannerScreen] = useState(null);
    const [bannerScreenEr, setBannerScreenEr] = useState("");
    const [bannerOrder, setBannerOrder] = useState(null);
    const [bannerOrderEr, setBannerOrderEr] = useState("");
    const [url, setUrl] = useState(null);


    const access = new Credentials({
        accessKeyId: AwsConstants.accessKeyId,
        secretAccessKey: AwsConstants.secretAccessKey,
    });

    const s3 = new S3({
        credentials: access,
        region: AwsConstants.region,
        signatureVersion: "v4",
    });


    const handleBannerTitle = (e) => {
        if (e.target.value) {
            setBannerTitle(e.target.value);
        } else {
            setBannerTitle("");
        }
    };

    const handleBannerDescription = (e) => {
        if (e.target.value) {
            setBannerDetails(e.target.value);
        } else {
            setBannerDetails("");
        }

    };

    const handleFile = (e) => {
        if (e.target.files[0].name.match(/\.(jpg|jpeg|png)$/)) {
            setFileEr("");
            setSelectedFile(e.target.files[0]);
            setFiles([...files, e.target.files[0]]);
            inputFile.current.value = null;
        } else {
            setFileEr("Only jpg, jpeg and png files are supported.");
            setTimeout(() => {
                setFileEr("");
            }, 3000);
            inputFile.current.value = null;
        }
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        let fileNewArray = []
        for (let index = 0; index < files.length; index++) {
            let fileInner = files[index];
            if(!fileInner){
              fileNewArray = [...fileNewArray , fileInner]
            }else{
              const options = {
                maxSizeMB: 1,
                maxWidthOrHeight: 1920,
                useWebWorker: true
              }
              try {
                const compressedFile = await imageCompression(fileInner, options);
                let fileNew = new File([compressedFile], fileInner.name);
                fileNewArray = [...fileNewArray, fileNew]
              } catch (error) {
                console.log(error);
              }
            }
           
          }
        if (bannerTitle === "") {
            setBannerTitleEr("Banner title field is required.");
        } else {
            if (bannerTitle.length <= 3) {
                setBannerTitleEr("Minimum 4 characters is required.");
            } else {
                setBannerTitleEr("");
                if (bannerScreen) {
                    setBannerScreenEr("")

                    if (bannerOrder) {
                        setBannerOrderEr("")
                        // if (files.length === 0) {
                        //     setFileEr("Select image for the banner.");
                        // } else {
                        //     setFileEr("");
                        var formData = new FormData();
                        fileNewArray.forEach((fileInner) => {
                            formData.append("banner_media", fileInner);
                        });
                        formData.append("banner_id", banner_id);
                        formData.append("banner_title", bannerTitle.trim());
                        formData.append("banner_screen", bannerScreen);
                        formData.append("banner_order", bannerOrder);
                        formData.append("banner_description", bannerDetails.trim());
                        formData.append("updated_by", loggedUserData.userLoginData.user_id);
                        formData.append("is_active", true);
                        setLoading(true);
                        let res = await APICall(ApiTypes.updateBanner, formData);
                        if (res === false) {
                            setLoading(false);
                        } else {
                            if (res.status === 1) {
                                setLoading(false);
                                toast.success("Banners updated successfully");
                                navigate("/banner");
                            } else {
                                setLoading(false);
                                toast.error(res.message);
                            }
                        }
                        // }

                    } else {
                        setBannerOrderEr("Banner order is required.")
                    }
                } else {
                    setBannerScreenEr("Select banner screen.")
                }
            }
        }
    };

    const handleBannerScreen = (e) => {
        if (e.target.value) {
            setBannerScreen(e.target.value)
        } else {
            setBannerScreen(null)
        }
    }

    const handleBannerOrder = (e) => {
        if (e.target.value) {
            setBannerOrder(parseInt(e.target.value))
        } else {
            setBannerOrder(null)
        }
    }

    useEffect(() => {
        if (selectedFile) {
            const reader = new FileReader();
            reader.readAsDataURL(selectedFile);
            reader.addEventListener(
                "load",
                function () {
                    setUrl(reader.result);
                },
                false
            );
            setSelectedFile(null);
        }
    }, [selectedFile]);

    const getImageUrls = async (response) => {
        const url = await s3.getSignedUrlPromise("getObject", {
            Bucket: AwsConstants.Bucket,
            Key: `Home/Banners/${response.banner_id}/${response.banner_media}`,
            Expires: AwsConstants.signedUrlExpireSeconds,
        });
        let newJson = {
            ...response,
            banner_url: url
        }
        return newJson;
    };

    // const handleRemoveImage = async (index, imageUrl, banner_picture_id) => {
    //     setLoading(true);
    //     files.splice(index, 1);
    //     let FilesNew = [...files];
    //     setFiles(FilesNew);
    //     if (!banner_picture_id) {
    //         imageUrls.splice(index, 1);
    //         let imageNew = [...imageUrls];
    //         setImageUrls(imageNew);
    //         setLoading(false);
    //     } else {
    //         let data = { banner_picture_id: banner_picture_id };
    //         let res = await APICall(ApiTypes.removeBannerImage, data);
    //         if (res.status == 1) {
    //             toast.success("Banner picture deleted successfully.");
    //             fetchBanner();
    //         }
    //         setLoading(false);
    //     }
    // };
    const fetchBanner = async () => {
        setLoading(true);
        let res = await APICall(
            ApiTypes.getBanner,
            null,
            `banner_id=${banner_id}`
        );
        if (res.status === 1) {
            let newJson = await getImageUrls(res.data);
            setBannerTitle(newJson.banner_title)
            setBannerDetails(newJson.banner_description)
            setBannerScreen(newJson.banner_screen)
            setBannerOrder(newJson.banner_order)
            setLoading(false);
            setUrl(newJson.banner_url)
        }
    };

    useEffect(() => {
        setLoading(true);
        fetchBanner();
        setLoading(false);
    }, []);

    return (
        <div className="container-fluid">
            <div className="row mt-4">
                <div className="col-12">
                    <div className="h4 mb-4">
                        <div className="d-flex justify-content-between">
                            <div className="d-flex flex-column justify-content-center">
                                <div className="d-flex breadcrum-navigation-title">
                                    <div className="me-2">Edit Banner</div>
                                    <div className="me-2 text-muted">|</div>
                                    <div className="d-flex">
                                        <div onClick={() => navigate('/home')} className="cursor-pointer custom-breadcrum-inactive me-2">Home</div>
                                        <div className="text-muted me-2">&gt;</div>
                                        <div onClick={() => navigate('/banner')} className="cursor-pointer custom-breadcrum-inactive me-2">Banner</div>
                                        <div className="text-muted me-2">&gt;</div>
                                        <div className="cursor-pointer custom-breadcrum-active me-2">Edit Banner</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-4" style={{ background: "#fff" }}>
                        <Form className="py-4 px-3" onSubmit={(e) => handleSubmit(e)}>
                            <div className="row">
                                <label
                                    className="col-md-4 col-sm-2 col-lg-3 col-xl-2"
                                    htmlFor="banner-title"
                                >
                                    Banner Title<span className="validation">*</span>
                                </label>
                                <div className="col-md-8 col-sm-8 col-lg-9 col-xl-8 ">
                                    <input
                                        className="form-control"
                                        type="text"
                                        value={bannerTitle}
                                        onChange={(e) => {
                                            handleBannerTitle(e);
                                        }}
                                        id="banner-title"
                                        placeholder="Enter banner"
                                    />
                                    {bannerTitleEr != "" ? (
                                        <div className="validation">*{bannerTitleEr}</div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                            <div className="row mt-4">
                                <label
                                    className="col-md-4 col-sm-2 col-lg-3 col-xl-2"
                                    htmlFor="banner-details"
                                >
                                    Banner Details
                                </label>
                                <div className="col-md-8 col-sm-8 col-lg-9 col-xl-8 ">
                                    <textarea
                                        placeholder="Enter banner details"
                                        className="col-md-8 col-sm-8 col-lg-9 col-xl-8 form-control"
                                        value={bannerDetails}
                                        onChange={(e) => {
                                            handleBannerDescription(e);
                                        }}
                                        id="banner-details"
                                        rows="3"
                                    ></textarea>
                                    {bannerDesEr !== "" ? (
                                        <span className="validation">*{bannerDesEr}</span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                            <div className="row mt-4">
                                <label
                                    className="col-md-4 col-sm-2 col-lg-3 col-xl-2"
                                    htmlFor="banner-details"
                                >
                                    Banner Screen <span className="validation">*</span>
                                </label>
                                <div className="col-md-8 col-sm-8 col-lg-9 col-xl-8 ">
                                    <select className="form-select" onChange={(e) => handleBannerScreen(e)} aria-label="Default select example">
                                        <option selected={bannerScreen === null ? true : false} >Select</option>
                                        {Object.keys(BANNER_SCREEN).map((banner_screen, index) => {
                                            return (
                                                <option key={index} selected={banner_screen === bannerScreen ? true : false} value={banner_screen}>
                                                    {BANNER_SCREEN[banner_screen]}
                                                </option>
                                            )
                                        })}
                                    </select>
                                    {bannerScreenEr !== "" ? (
                                        <span className="validation">*{bannerScreenEr}</span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                            <div className="row mt-4">
                                <label
                                    className="col-md-4 col-sm-2 col-lg-3 col-xl-2"
                                    htmlFor="banner-order"
                                >
                                    Banner Order <span className="validation">*</span>
                                </label>
                                <div className="col-md-8 col-sm-8 col-lg-9 col-xl-8 ">
                                    <input
                                        className="form-control"
                                        type="number"
                                        value={bannerOrder ? bannerOrder : ""}
                                        onChange={(e) => {
                                            handleBannerOrder(e);
                                        }}
                                        id="banner-order"
                                        placeholder="Enter banner order"
                                    />
                                    {bannerOrderEr != "" ? (
                                        <div className="validation">*{bannerOrderEr}</div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                            <div className="row mt-4">
                                <label
                                    className="col-md-4 col-sm-2 col-lg-3 col-xl-2"
                                    htmlFor="banner-image"
                                >
                                    Banner Image <span className="validation">*</span>{" "}
                                </label>
                                <div className="col-md-8 col-sm-8 col-lg-9 col-xl-8 ">
                                    <div className="mt-2 gridrow">
                                        {url ?
                                            <div className="d-flex flex-column">
                                                <img
                                                    className="image-category"
                                                    width="100px"
                                                    height="100px"
                                                    src={url}
                                                    alt=""
                                                />
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        inputFile.current.click();
                                                    }}
                                                    className="btn mb-1 btn-primary"
                                                >
                                                    Change Banner
                                                </button>
                                            </div>
                                            : <div>
                                                <div className="position-relative d-flex flex-column justify-content-center">
                                                    <img
                                                        style={{ opacity: "0.5" }}
                                                        className="image-category"
                                                        width="100px"
                                                        height="100px"
                                                        src={blank}
                                                        alt=""
                                                    />
                                                    <div
                                                        onClick={() => {
                                                            inputFile.current.click();
                                                        }}
                                                        className="upload-icon"
                                                    >
                                                        <i
                                                            className="fa fa-plus-circle"
                                                            aria-hidden="true"
                                                        ></i>
                                                    </div>
                                                </div>
                                            </div>}

                                    </div>
                                    <input
                                        ref={inputFile}
                                        onChange={(e) => {
                                            handleFile(e);
                                        }}
                                        accept="image/*"
                                        type="file"
                                        className="mx-2 d-none form-control-file"
                                        id="image-banner"
                                    />
                                    {fileEr != "" ? (
                                        <span className="validation">*{fileEr}</span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                            <div className="row">
                                <label className="col-md-4 col-sm-2 col-lg-3 col-xl-2"></label>
                                <div className="mt-4 col-md-8 col-sm-8 col-lg-9 col-xl-8 createbtn">
                                    <button type="submit" className="btn btn-primary w-auto mb-2">
                                        Update
                                    </button>
                                </div>
                            </div>
                        </Form>
                        {loading ? <SiteLoader /> : ""}
                    </div>
                </div>
            </div>
        </div>
    );
};
