import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import APICall from "../../networking/AxiousServices";
import { ApiTypes, AwsConstants } from "../../constants";
import S3 from "aws-sdk/clients/s3";
import { Credentials } from "aws-sdk";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import blank from "../../Assets/Image/Default/blank.png";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import SiteLoader from "../../Layout/Loader/SiteLoader";
import imageCompression from 'browser-image-compression';

export default function EditCategory() {
  document.title = "Kalon | Edit Category";
  const inputFile = useRef(null);
  const loggedUserData = useSelector((state) => state.data);
  let navigate = useNavigate();
  let { category_id } = useParams();
  const [category, setCategory] = useState("");
  const [categoryDetails, setCategoryDetails] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [catEr, setCatEr] = useState("");
  const [catDlsEr, setCatDlsEr] = useState("");
  const [fileEr, setFileEr] = useState("");
  const [imageUrls, setImageUrls] = useState([]);
  // const [activeCategory, setActiveCategory] = useState(null);
  // const [activeCategories, setActiveCategories] = useState([]);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const access = new Credentials({
    accessKeyId: AwsConstants.accessKeyId,
    secretAccessKey: AwsConstants.secretAccessKey,
  });

  const s3 = new S3({
    credentials: access,
    region: AwsConstants.region,
    signatureVersion: "v4",
  });

  const getImageUrls = async (response) => {
    let newArray = [];
    if (response.mst_category_pictures) {
      if (response.mst_category_pictures.length != 0) {
        let iUrls = [];
        let newfiles = [];
        response.mst_category_pictures.forEach(async (picture) => {
          const url = await s3.getSignedUrlPromise("getObject", {
            Bucket: AwsConstants.Bucket,
            Key: `Home/Categories/${response.category_id}/${picture.display_pic}`,
            Expires: AwsConstants.signedUrlExpireSeconds,
          });
          iUrls.push({
            url: url,
            category_picture_id: picture.category_picture_id,
          });
          newfiles = [...newfiles, false];
          setFiles(newfiles);
        });
        setImageUrls(iUrls);
        response["imageUrls"] = iUrls;
      } else {
        response["imageUrls"] = null;
        setImageUrls([]);
      }
    } else {
      response["imageUrl"] = null;
      setImageUrls([]);
    }

    newArray.push(response);
    return newArray;
  };
  const handleCategory = (e) => {
    setCategory(e.target.value);
  };
  const handleCategoryDetails = (e) => {
    setCategoryDetails(e.target.value);
  };
  const handleFile = (e) => {
    if (e.target.files[0].name.match(/\.(jpg|jpeg|png)$/)) {
      setFileEr("");
      setSelectedFile(e.target.files[0]);
      setFiles([...files, e.target.files[0]]);
      inputFile.current.value = null;
    } else {
      setFileEr("Only jpg, jpeg and png files are supported.");
      setTimeout(() => {
        setFileEr("");
      }, 3000);
      inputFile.current.value = null;
    }
  };

  // const handleActiveCategory = (e) => {
  //     if (e.target.value == 0) {
  //         setActiveCategory(null)
  //     }
  //     else {
  //         setActiveCategory(parseInt(e.target.value))
  //     }
  // }

  const handleSubmit = async (e) => {
    e.preventDefault();
    let fileNewArray = []
    for (let index = 0; index < files.length; index++) {
      let fileInner = files[index];
      if(!fileInner){
        fileNewArray = [...fileNewArray , fileInner]
      }else{
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true
        }
        try {
          const compressedFile = await imageCompression(fileInner, options);
          let fileNew = new File([compressedFile], fileInner.name);
          fileNewArray = [...fileNewArray, fileNew]
        } catch (error) {
          console.log(error);
        }
      }
     
    }
    if (category == "") {
      setCatEr("Category field is required.");
    } else {
      if (category.length <= 3) {
        setCatEr("Minimum 4 characters is required.");
      } else {
        setCatEr("");
        if (categoryDetails !== "") {
          setCatDlsEr("");
          if (fileNewArray.length !== 0) {
            setFileEr("");
            var formData = new FormData();
            fileNewArray.forEach((fileInner) => {
              if (fileInner) {
                formData.append("file", fileInner);
              }
            });
            formData.append("category_id", category_id);
            formData.append("category_name", category.trim());
            formData.append("details", categoryDetails.trim());
            // if (activeCategory) {
            //     formData.append('ref_category_id', activeCategory);
            // }
            formData.append("updated_by", loggedUserData.userLoginData.user_id);
            formData.append("is_active", true);
            setLoading(true);
            let res = await APICall(ApiTypes.editCategory, formData);
            if (res == false) {
              setLoading(false);
            } else {
              setLoading(false);
              toast.success("Category updated successfully");
              navigate("/categories");
            }
          } else {
            setFileEr("");
            var formData = new FormData();
            formData.append("category_id", category_id);
            formData.append("file", null);
            formData.append("category_name", category.trim());
            formData.append("details", categoryDetails.trim());
            // if(activeCategory){
            //     formData.append('ref_category_id', activeCategory);
            // }
            formData.append("updated_by", loggedUserData.userLoginData.user_id);
            formData.append("is_active", true);
            let res = await APICall(ApiTypes.editCategory, formData);
            if (res != false) {
              toast.success("Category updated successfully");
              navigate("/categories");
            }
          }
        } else {
          setCatDlsEr("Details field is required.");
        }
      }
    }
  };

  const fetchCategory = async () => {
    setLoading(true);
    let res = await APICall(
      ApiTypes.getCategory,
      null,
      `category_id=${category_id}`
    );
    if (res.status === 1) {
      let newArray = await getImageUrls(res.data);
      setCategory(newArray[0].category_name);
      setCategoryDetails(newArray[0].details ? newArray[0].details : "");
      // if (newArray[0].ref_category_id) {
      //     setActiveCategory(parseInt(newArray[0].ref_category_id))
      // }
      // else {
      //     setActiveCategory(null)
      // }
      setLoading(false);
    }
  };

  // const fetchActiceCategories = async () => {
  //     let res = await APICall(ApiTypes.fetchActiveCategories);
  //     if (res.status == 1) {
  //         setActiveCategories(res.data)
  //     }
  // }

  const handleRemoveImage = async (index, imageUrl, category_picture_id) => {
    setLoading(true);
    files.splice(index, 1);
    let FilesNew = [...files];
    setFiles(FilesNew);
    if (!category_picture_id) {
      imageUrls.splice(index, 1);
      let imageNew = [...imageUrls];
      setImageUrls(imageNew);
      setLoading(false);
    } else {
      let data = { category_picture_id: category_picture_id };
      let res = await APICall(ApiTypes.removeCategoryImage, data);
      if (res.status == 1) {
        toast.success("Category picture deleted successfully.");
        fetchCategory();
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchCategory();
    // fetchActiceCategories();
    setLoading(false);
  }, []);

  useEffect(() => {
    if (selectedFile) {
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.addEventListener(
        "load",
        function () {
          setImageUrls([
            ...imageUrls,
            { url: reader.result, category_picture_id: null },
          ]);
        },
        false
      );
      setSelectedFile(null);
    }
  }, [selectedFile]);

  return (
    <div className="container-fluid">
      <div className="row mt-4">
        <div className="col-12">
          <div className="h4 mb-4">
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-column justify-content-center">
                <div className="d-flex breadcrum-navigation-title">
                  <div className="me-2">Edit Category</div>
                  <div className="me-2 text-muted">|</div>
                  <div className="d-flex">
                    <div onClick={() => navigate('/home')} className="cursor-pointer custom-breadcrum-inactive me-2">Home</div>
                    <div className="text-muted me-2">&gt;</div>
                    <div onClick={() => navigate('/categories')} className="cursor-pointer custom-breadcrum-inactive me-2">Categories</div>
                    <div className="text-muted me-2">&gt;</div>
                    <div className="cursor-pointer custom-breadcrum-active me-2">Edit Category</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ background: "#fff" }}>
            <Form className="py-4 px-3" onSubmit={(e) => handleSubmit(e)}>
              <div className="row">
                <label
                  className="col-md-4 col-sm-2 col-lg-3 col-xl-2"
                  htmlFor="category"
                >
                  Category <span className="validation">*</span>
                </label>
                <div className="col-md-8 col-sm-8 col-lg-9 col-xl-8 ">
                  <input
                    type="text"
                    value={category}
                    onChange={(e) => {
                      handleCategory(e);
                    }}
                    className="form-control"
                    id="category"
                    placeholder="Enter category"
                  />
                  {catEr !== "" ? (
                    <span className="validation">*{catEr}</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {/* <div className="d-flex mt-4">
                                <label className="col-md-2 col-sm-2 col-lg-2 col-xl-2" htmlFor="category">Parent Category </label>
                                <div className='col-md-8 col-sm-8 col-lg-8 col-xl-8 '>
                                    <Form.Select onChange={(e) => { handleActiveCategory(e) }} id="category" aria-label="Default select example">
                                        <option value="0">Select main category</option>
                                        {activeCategories.length != 0 ? activeCategories.map((activeCat, index) => {
                                            return <option key={index} selected={activeCategory == activeCat.category_id ? true : false} value={activeCat.category_id}>{activeCat.category_name}</option>
                                        }) : ""}
                                    </Form.Select>
                                </div>
                            </div> */}
              <div className="row mt-4">
                <label
                  className="col-md-4 col-sm-2 col-lg-3 col-xl-2"
                  htmlFor="categoryDetails"
                >
                  Category Details <span className="validation">*</span>
                </label>
                <div className="col-md-8 col-sm-8 col-lg-9 col-xl-8 ">
                  <textarea
                    value={categoryDetails}
                    onChange={(e) => {
                      handleCategoryDetails(e);
                    }}
                    className="form-control"
                    id="categoryDetails"
                    rows="3"
                  ></textarea>
                  {catDlsEr != "" ? (
                    <span className="validation">*{catDlsEr}</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row mt-4">
                <label
                  className="col-md-4 col-sm-2 col-lg-3 col-xl-2"
                  htmlFor="categoryImage"
                >
                  {" "}
                  Category Image{" "}
                </label>
                <div className="col-md-8 col-sm-8 col-lg-9 col-xl-8 ">
                  <div className="gridrow">
                    {imageUrls.length != 0
                      ? imageUrls.map((img, index) => {
                        return (
                          <div key={index} className="d-flex flex-column">
                            <img
                              className="image-category"
                              widht="100px"
                              height="100px"
                              src={img.url}
                            />
                            <button
                              type="button"
                              onClick={() => {
                                handleRemoveImage(
                                  index,
                                  img.url,
                                  img.category_picture_id
                                );
                              }}
                              className="btn btn-primary"
                            >
                              {" "}
                              Remove Image{" "}
                            </button>
                          </div>
                        );
                      })
                      : ""}
                    <div>
                      <div className="position-relative d-flex flex-column justify-content-center">
                        <img
                          style={{ opacity: "0.5" }}
                          className="image-category"
                          widht="100px"
                          height="100px"
                          src={blank}
                        />
                        <div
                          onClick={() => {
                            inputFile.current.click();
                          }}
                          className="upload-icon"
                        >
                          <i className="fa fa-plus-circle" aria-hidden="true"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <input
                    ref={inputFile}
                    onChange={(e) => {
                      handleFile(e);
                    }}
                    accept="image/*"
                    type="file"
                    className="mx-2 d-none form-control-file"
                    id="categoryImage"
                  />
                  {fileEr != "" ? (
                    <span className="validation">*{fileEr}</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row">
                <label
                  className="col-md-4 col-sm-2 col-lg-3 col-xl-2"
                ></label>
                <div className="mt-4 col-md-8 col-sm-8 col-lg-9 col-xl-8 createbtn">
                  <button type="submit" className="btn btn-primary w-auto mb-2">
                    Update
                  </button>
                </div>
              </div>
            </Form>
            {loading ? <SiteLoader /> : ""}
          </div>
        </div>
      </div>
    </div>
  );
}
