import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApiTypes, AwsConstants } from "../../constants";
import APICall from "../../networking/AxiousServices";
import { Credentials } from "aws-sdk";
import S3 from "aws-sdk/clients/s3";
import { Button, Carousel } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import SiteLoader from "../../Layout/Loader/SiteLoader";
import placeholder from "../../Assets/Image/Icon/placeholder.png";
import moment from "moment";
import blank from "../../Assets/Image/Default/blank.png";
import SwitchButton from "../../Layout/Components/SwitchButton";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import { debug } from "util";
import CustomTableLoader from "../../Layout/Loader/CustomTableLoader";
import { Tabs, Tab } from "react-bootstrap";
import ShopAdminServices from "./ShopAdminServices";
import ShopAdminPackages from "./ShopAdminPackages";

export default function ViewShopAdmin() {
  document.title = "Kalon | Shop Admin";
  let navigate = useNavigate();
  const [shopAdmins, setShopAdmins] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imgSrc, setImgSrc] = useState();
  const [postImgSrc, setPostImgSrc] = useState();

  const [tableLoading, setTableLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(null);
  const [customerPost, setCustomerPost] = useState([]);
  const [memberId, setMemberId] = useState(null);
  // let member_id = null;
  let { parlour_id } = useParams();

  const access = new Credentials({
    accessKeyId: AwsConstants.accessKeyId,
    secretAccessKey: AwsConstants.secretAccessKey,
  });


  const chageStatus = async (value, row) => {
    setCustomerPost((prevState) =>
      prevState.map((mapItem) => {
        if (row.post_id === mapItem.post_id) {
          return {
            ...mapItem,
            is_active: value,
          };
        }
        return mapItem;
      })
    );
    let data = { post_id: row.post_id, is_active: value };
    let res = await APICall(ApiTypes.changePostStatus, data);
    if (res.status == 1) {
      if (value == false) {
        toast.success("Post is disabled successfully.");
      } else {
        toast.success("Post is enabled successfully.");
      }
    }
  };

  const handlePost = (post_id) => {
    navigate(`/post/view/${post_id}`);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage, pageGot) => {
    setPerPage(newPerPage);
    setPage(pageGot);
  };


  const getCustomerPostImageUrls = async (response) => {
    let url;
    if (response.length) {
      for (let i = 0; i < response.length; i++) {
        if (response[i].postMediaDetails) {
          url = await s3.getSignedUrlPromise("getObject", {
            Bucket: AwsConstants.Bucket,
            Key: `Home/SocialMedia/Post/${response[i].post_id}/${response[i].postMediaDetails[0].media_name}`,
            Expires: AwsConstants.signedUrlExpireSeconds,
          });
          response[i]["imageUrl"] = url;
        }
      }
      return response;
    }
  }


  const fetchPosts = async () => {
    const data = {
      member_id: memberId,
    };
    let res = await APICall(
      ApiTypes.viewPosts,
      data,
      `page=${page - 1}&size=${perPage}`
    );

    if (res.status === 1) {
      res.data.list.forEach((listItem, index) => {
        listItem["index"] = index;
      });
      setTotalRows(res.data.totalItems);
      let newArray = await getCustomerPostImageUrls(res.data.list);
      setCustomerPost(newArray);
      setLoading(false);
      setTableLoading(false)
    } else if (res.status === 0) {
      toast.error(res.message ? res.message : "Something went wrong.");
      setLoading(false);
      setTableLoading(false)
    }
  };

  const s3 = new S3({
    credentials: access,
    region: AwsConstants.region,
    signatureVersion: "v4",
  });

  const columns = [
    {
      name: "Sr. No.",
      selector: (row) => row.index + 1,
      sortable: true,
      width: "100px",
    },
    {
      name: "Image",
      selector: (row) =>
        row.imageUrl ? (
          <img className="custom-image" src={row.imageUrl} alt=""></img>
        ) : (
          <img className="custom-image" src={blank} alt=""></img>
        ),
      sortable: true,
      width: "100px",
    },
    {
      name: "Post By",
      selector: (row) => row?.postMemberProfile?.display_name,
      sortable: true,
    },
    {
      name: "Title",
      selector: (row) => row.post_heading,
      sortable: true,
    },
    {
      name: "Reaction",
      selector: (row) => row.total_reactions,
      sortable: true,
      width: "110px",
    },
    {
      name: "Comment",
      selector: (row) => row.total_comments,
      sortable: true,
      width: "110px",
    },
    {
      name: "Is Active",
      selector: (row) => <SwitchButton chageStatus={chageStatus} isActive={row.is_active} row={row} />,
      sortable: true,
      width: "100px",
    },
    {
      name: "Action",
      sortable: false,
      selector: (row) => (
        <>
          <span
            className="eye-icon"
            onClick={() => {
              handlePost(row.post_id);
            }}
          >
            <i className="fas fa-eye"></i>
          </span>
          {/* <Button height="24px" width="24px" variant="danger">
            <i className="fa fa-trash" aria-hidden="true"></i>
          </Button> */}
        </>
      ),
      width: "90px",
    },
  ];

  const addDefaultSrc = () => {
    setImgSrc(placeholder);
  };

  const getImageUrls = async (response) => {
    let newArray = [];
    if (response.length > 0) {
      for (let i = 0; i < response[0].parlourPictures.length; i++) {
        if (response[0].parlourPictures) {
          if (response[0].parlourPictures.length !== 0) {
            const url = await s3.getSignedUrlPromise("getObject", {
              Bucket: AwsConstants.Bucket,
              Key: `Home/Parlours/${response[0].parlour_id}/${response[0].parlourPictures[i].display_pic}`,
              Expires: AwsConstants.signedUrlExpireSeconds,
            });
            response[0].parlourPictures[i]["imageUrl"] = url;
          } else {
            response[0].parlourPictures[i]["imageUrl"] = null;
          }
        } else {
          response[0].parlourPictures[i]["imageUrl"] = null;
        }
      }
    }
    newArray.push(response[0]);
    return newArray;
  };

  const getPersonImageUrls = async (response) => {
    let newArray = [];
    let url;
    for (let i = 0; i < response.length; i++) {
      if (response.length !== 0 && response[i].person_profile_pic) {
        url = await s3.getSignedUrlPromise("getObject", {
          Bucket: AwsConstants.Bucket,
          Key: `Home/Parlours/${response[i].parlour_id}/Profile/${response[i].person_profile_pic}`,
          Expires: AwsConstants.signedUrlExpireSeconds,
        });
        response[i]["imageUrl"] = url;
        setImgSrc(url);

      } else {
        response[i]["imageUrl"] = null;
      }
      newArray.push(response[i]);
    }

    return newArray;
  };

  const formatDate = (start_time) => {
    if (start_time !== null) {
      return new Date("1970-01-01T" + start_time + "Z").toLocaleTimeString(
        "en-US",
        { timeZone: "UTC", hour12: true, hour: "numeric", minute: "numeric" }
      );
    } else {
      return null;
    }
  };

  const fetchShopAdmins = async () => {
    setLoading(true);
    let res = await APICall(
      ApiTypes.fetchParlour,
      null,
      `parlour_id=${parlour_id}`
    );
    if (res.status === 1) {
      if (res.data.length > 0) {
        let newArray = await getImageUrls(res.data);
        setShopAdmins(newArray);
        if (
          res.data[0].parloursocialMemberDetails &&
          res.data[0].parloursocialMemberDetails.member_id
        ) {

          setMemberId(res.data[0].parloursocialMemberDetails.member_id)

          // member_id = res.data[0].parloursocialMemberDetails.member_id;
        }
        else {
          setLoading(false);
        }
        setLoading(false)
      }
      await getPersonImageUrls(res.data);
    }
  };

  useEffect(() => {
    fetchShopAdmins();
  }, []);

  useEffect(() => {
    if (memberId) {
      fetchPosts();
    }
  }, [page, perPage , memberId]);

  const renderLocation = (value) => {
    let str = value.address_1 +
      ", " +
      (value?.address_2 ? value.address_2 + ", " : "") +
      (value?.city ? value.city + ", " : "") +
      (value.state ? value.state + ", " : "") +
      (value.address_3 ? value.address_3 + ", " : "");
    str = str.trim();
    str = str.slice(0, -1)
    return str;
  }


  return (
    <>
      <div className="container-fluid mt-4">
        <div className="h4 mb-4">
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-column justify-content-center">
              <div className="d-flex breadcrum-navigation-title">
                <div className="me-2">Shop Admin</div>
                <div className="me-2 text-muted">|</div>
                <div className=" d-flex">
                  <div
                    onClick={() => navigate("/home")}
                    className="cursor-pointer custom-breadcrum-inactive me-2"
                  >
                    Home
                  </div>
                  <div className="text-muted me-2">&gt;</div>
                  <div
                    onClick={() => navigate("/shopadmins")}
                    className="cursor-pointer custom-breadcrum-inactive me-2"
                  >
                    Shop Admins
                  </div>
                  <div className="text-muted me-2">&gt;</div>
                  <div className="cursor-pointer  custom-breadcrum-active me-2">
                    Shop Admin
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {!loading ? (
          <>
            {shopAdmins.map((value, index) => (

              <div className="key">
                <div className="p-4 my-3 bg-white rounded">
                  <div className="fs-5 fw-bold">
                    <div className="h5 mb-4">{value.parlour_name}</div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-lg-6 col-xl-4 mb-4 mb-lg-0">
                      <div className="selection-div p-4 bg-white rounded">
                        <div className="image">
                          <img
                            className="socialImage"
                            src={imgSrc ? imgSrc : placeholder}
                            onError={() => addDefaultSrc()}
                            alt=""
                          ></img>
                        </div>

                        <div className="d-flex gap-2 flex-column">
                          <div className="d-flex justify-content-center mt-2">
                            <div className="fw-bold"> {value.person_full_name} </div>
                          </div>
                          <dl className="mb-0 row">
                            <dt className="mb-1 h6 col-md-4 col-sm-8 col-lg-6 ">
                              Email
                            </dt>
                            <dd className="mb-1 text-muted col-sm-12 col-md-8 col-lg-6 wb-keep-a">
                              {value.email_id ? value.email_id : '-'}
                            </dd>
                          </dl>

                          <dl className="mb-0 row">
                            <dt className="mb-1 h6 col-md-4 col-sm-8 col-lg-6">
                              Moblie No
                            </dt>
                            <dd className="mb-1 text-muted col-sm-12 col-md-8 col-lg-6 wb-keep-a">
                              {value.contactno_1 ? value.contactno_1 : "-"}
                            </dd>
                          </dl>

                          <dl className="mb-0 row">
                            <dt className="mb-1 h6 col-md-4 col-sm-8 col-lg-6">
                              Mobile No 2
                            </dt>
                            <dd className="mb-1 text-muted col-sm-12 col-md-8 col-lg-6 wb-keep-a ">
                              {value.contactno_2 ? value.contactno_2 : "-"}
                            </dd>
                          </dl>
                          <dl className="mb-0 row">
                            <dt className="mb-1 h6 col-md-4 col-sm-8 col-lg-6">
                              Total Followers
                            </dt>
                            <dd className="mb-1 text-muted col-sm-12 col-md-8 col-lg-6 wb-keep-a ">
                              {value.parloursocialMemberDetails !== null
                                ? value.parloursocialMemberDetails
                                  ?.total_followers
                                : 0}
                            </dd>
                          </dl>
                          <dl className="mb-0 row">
                            <dt className="mb-1 h6 col-md-4 col-sm-8 col-lg-6">
                              Total Followings
                            </dt>
                            <dd className="mb-1 text-muted col-sm-12 col-md-8 col-lg-6 wb-keep-a ">
                              {value.socialMemberDetails !== null
                                ? value.parloursocialMemberDetails
                                  ?.total_following
                                  ? value.parloursocialMemberDetails
                                    .total_following
                                  : 0
                                : 0}
                            </dd>
                          </dl>
                        </div>
                        {/* <div className="social-followers d-flex justify-content-center gap-2 mt-3">
                        <div>
                          <div>
                            <div className="mb-0"> Followers </div>
                            {value.parloursocialMemberDetails !== null
                              ? value.parloursocialMemberDetails
                                  ?.total_followers
                              : 0}
                          </div>
                        </div>
                        <div>
                          <div>
                            <div className="mb-0"> Followings </div>
                            {value.socialMemberDetails !== null
                              ? value.parloursocialMemberDetails
                                  ?.total_following
                              : 0}
                          </div>
                        </div>
                      </div> */}
                      </div>
                    </div>
                    <div className="col-12 col-lg-6 col-xl-8">
                      <Carousel interval={null}>
                        {value.parlourPictures.map((value, index) => {
                          return (
                            <Carousel.Item key={index}>
                              <img
                                className="lazy-load-image"
                                src={value.imageUrl}
                                alt="First slide"
                              />
                            </Carousel.Item>
                          );
                        })}
                      </Carousel>

                      <div className=" mt-4 gap-3 d-flex  flex-column">
                        {value.address_1 ? <div>
                          {/* <label className="fw-bold">Address: </label> */}
                          <i className="fa-solid fa-location-dot me-2" aria-hidden="true"></i>
                          <span className="mb-0">
                            {renderLocation(value)}
                          </span>
                        </div>
                          : ""}
                        {/* 
                      <div className="d-flex  gap-4">
                        <div className="d-flex gap-2">
                          <label className="fw-bold">City: </label>
                          <p className="mb-0">{value.city}</p>
                        </div>
                        <div className="d-flex gap-2">
                          <label className="fw-bold">State: </label>
                          <p className="mb-0">{value.state}</p>
                        </div>
                      </div> */}

                        <div className="d-flex  gap-4">
                          <div className="d-flex gap-2">
                            <div className="h6">Opening: </div>
                            <div className="mb-0 text-muted">{value.start_time ? formatDate(value.start_time) : "-"}</div>
                          </div>
                          <div className="d-flex gap-2">
                            <div className="h6">Closing: </div>
                            <div className="mb-0 text-muted" >{value.start_time ? formatDate(value.end_time) : "-"}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            ))}

            <Tabs
              id="controlled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="post" title="Posts">
                <div className="py-1 my-4 m-auto bg-body rounded">
                  <div className="table-outer react-table">
                    <DataTable
                      data={customerPost}
                      persistTableHead={true}
                      columns={columns}
                      paginationServer
                      paginationTotalRows={totalRows}
                      onChangeRowsPerPage={handlePerRowsChange}
                      onChangePage={handlePageChange}
                      defaultSortField="id"
                      defaultSortAsc={true}
                      pagination
                      progressPending={tableLoading}
                      progressComponent={<CustomTableLoader />}
                      highlightOnHover
                      responsive={true}
                    />

                  </div>
                </div>
              </Tab>
              <Tab eventKey="service" title="Services">
                <ShopAdminServices parlour_id={parlour_id} />
              </Tab>
              <Tab eventKey="package" title="Packages">
                <ShopAdminPackages parlour_id={parlour_id} />
              </Tab>
            </Tabs>


          </>
        ) : (
          <SiteLoader />
        )}
      </div>
    </>
  );
}
