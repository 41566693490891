import React, { useEffect, useState } from "react";
import SiteLoader from "../../Layout/Loader/SiteLoader";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import APICall from "../../networking/AxiousServices";
import { ApiTypes } from "../../constants";
import { Button } from "react-bootstrap";
import SwitchButton from "../../Layout/Components/SwitchButton";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import CustomTableLoader from "../../Layout/Loader/CustomTableLoader";
import { useSelector } from "react-redux";

export default function Users() {

  const loggedUserData = useSelector((state) => state.data);
  document.title = "Kalon | Users";
  let navigate = useNavigate();
  const [admins, setAdmins] = useState([]);
  const [loading, setLoading] = useState(false);

  const chageStatus = async (value, row) => {
    setAdmins((prevState) =>
      prevState.map((mapItem) => {
        if (row.user_id == mapItem.user_id) {
          return {
            ...mapItem,
            is_active: value,
          };
        }
        return mapItem;
      })
    );
    let data = { user_id: row.user_id, is_active: value };
    let res = await APICall(ApiTypes.changeAdminStatus, data);
    if (res.status == 1) {
      if (value == false) {
        toast.success("User is disabled successfully.");
      } else {
        toast.success("User is enabled successfully.");
      }
    }
  };
  const fetchAdmins = async () => {
    setLoading(true);
    let res = await APICall(ApiTypes.fetchAdmins);
    if (res.status == 1) {
      let count = 0;
      res.data.forEach((innerRes) => {
        innerRes.index = count;
        count += 1;
      });
      setAdmins(res.data);
      setLoading(false);
    }
  };
  const handleEditAdmin = (use_id) => {
    navigate(`/edit/${use_id}/user`);
  };
  const handleDeleteAdmin = async (use_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      setLoading(true);
      if (result.isConfirmed) {
        let res = await APICall(ApiTypes.deleteAdmin, { user_id: use_id });
        if (res) {
          if (res.status == 1) {
            toast.success("User deleted successfully.");
            fetchAdmins();
          } else {
            setLoading(false);
          }
        } else {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    });
  };
  useEffect(() => {
    fetchAdmins();
  }, []);
  const columns = [
    {
      name: "Sr. No.",
      selector: (row, index) => index + 1,
      sortable: false,
      width: "100px",
    },
    {
      name: "Name",
      selector: (row) => row.display_name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.user_name,
      sortable: true,
    },
    {
      name: "Is Verified",
      selector: (row) =>
        row.password ? (
          <div className="is-verify-true">Yes</div>
        ) : (
          <div className="is-verify-false">No</div>
        ),
      sortable: true,
      width: "120px",
    },

    {
      name: "Role",
      selector: (row) => row.user_role.role_name,
      sortable: true,
      width: "150px",
    },
    {
      name: "Status",
      selector: (row) => (
        <SwitchButton
          isActive={row.is_active}
          chageStatus={chageStatus}
          row={row}
          isDisabled={loggedUserData.userLoginData.user_id === parseInt(row.user_id) ? true : false}
        />
      ),
      sortable: true,
      width: "90px",
    },
    {
      name: "Action",
      sortable: false,
      selector: (row) => (
        <>
          <sapn
            onClick={() => {
              handleEditAdmin(row.user_id);
            }
            }
            className="edit-icon"
          >
            <i className="fas fa-edit"></i>
          </sapn>
          {/* <Button
            onClick={() => {
              handleDeleteAdmin(row.user_id);
            }}
            height="24px"
            width="24px"
            variant="danger"
          >
            <i className="fa fa-trash" aria-hidden="true"></i>
          </Button> */}
        </>
      ),
      width: "90px",
    },
  ];
  return (
    <div className="container-fluid">
      <div className="row mt-4">
        <div className="col-12">
          <div className="h4 mb-4">
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-column justify-content-center">
                <div className="d-flex breadcrum-navigation-title">
                  <div className="me-2">Users</div>
                  <div className="me-2 text-muted">|</div>
                  <div className=" d-flex">
                    <div
                      onClick={() => navigate("/home")}
                      className="cursor-pointer custom-breadcrum-inactive me-2"
                    >
                      Home
                    </div>
                    <div className="text-muted me-2">&gt;</div>
                    <div className="cursor-pointer custom-breadcrum-active me-2">
                      Users
                    </div>
                  </div>
                </div>
              </div>
              <Button
                onClick={() => {
                  navigate("/add-user");
                }}
                className="ms-2"
                variant="primary"
              >
                Add User
              </Button>
            </div>
          </div>
          <div className="table-outer react-table">
            <DataTable
              data={admins}
              columns={columns}
              noHeader
              defaultSortField="id"
              defaultSortAsc={true}
              pagination
              highlightOnHover
              responsive={true}
              progressPending={loading}
              persistTableHead={true}
              progressComponent={<CustomTableLoader />}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
