import React, { useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addLoggedUser, isLoggedIn } from "../../actions/actions";
import { toast } from "react-toastify";
import APICall from "../../networking/AxiousServices";
import { ApiTypes } from "../../constants";
import SiteLoader from "../../Layout/Loader/SiteLoader";

function Login({ title }) {
  document.title = title;
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordEr, setPasswordEr] = useState("");

  const [passHide, setPassHide] = useState(true);
  const [loading, setLoading] = useState(false);

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const handleLogin = async (e) => {
    e.preventDefault();
    if (email == "") {
      setEmailError("Email field is required.");
    } else {
      if (
        email.match(
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
      ) {
        setEmailError("");
        if (password == "") {
          setPasswordEr("Password field is required");
        } else {
          setPasswordEr("");
          let data = { user_name: email, password: password };
          setLoading(true);
          const res = await APICall(ApiTypes.login, data);
          if (res.status === 0) {
            setLoading(false);
            toast.error("User not exist or it is blocked by admin.");
          } else {
            if (res.status === 1) {
              localStorage.setItem("loggedUser", JSON.stringify(res.data));
              localStorage.setItem("isLoggedIn", true);
              dispatch(addLoggedUser(res.data));
              dispatch(isLoggedIn(true));
              setLoading(false);
              navigate("/home");
              toast.success("Logged in successfully.");
            } else {
              toast.error(
                "Something went wrong. It might be Network Error or Cors Issue."
              );
              setLoading(false);
            }
          }
        }
      } else {
        setEmailError("Email is not valid");
      }
    }
  };

  return (
    <div className="login-box">
      <div className="web-login">
        <div className="padding-25px-without-top">
          <h2>Login</h2>
          <Form
            onSubmit={(e) => {
              handleLogin(e);
            }}
          >
            <Form.Group className="mb-3" controlId="formGroupEmail">
              <Form.Label className="login-label">Email</Form.Label>
              <Form.Control
                onChange={(e) => {
                  handleEmail(e);
                }}
                type="email"
                value={email}
                placeholder="Enter email"
              />
              {emailError != "" ? (
                <span className="validation">*{emailError}</span>
              ) : (
                ""
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGroupPassword">
              <Form.Label className="login-label">Password</Form.Label>
              <div className="position-relative">
                <Form.Control
                  onChange={(e) => handlePassword(e)}
                  type={passHide ? "password" : "text"}
                  value={password}
                  placeholder="Enter password"
                />
                {passHide ? (
                  <i
                    onClick={() => setPassHide(false)}
                    className="fa fa-eye eye-custom"
                    aria-hidden="true"
                  ></i>
                ) : (
                  <i
                    onClick={() => setPassHide(true)}
                    className="fa fa-eye-slash eye-custom"
                    aria-hidden="true"
                  ></i>
                )}
              </div>

              {passwordEr != "" ? (
                <span className="validation">*{passwordEr}</span>
              ) : (
                ""
              )}
            </Form.Group>
            {/* <div className="d-flex justify-content-between align-items-center">
                          {["checkbox"].map((type) => (
                            <div key={`-${type}`}>
                              <Form.Check
                                type={type}
                                id={`-${type}`}
                                label={`Remember me`}
                              />
                            </div>
                          ))}
                          <div className="forgot-password">
                            <p className="m-0">Forgot Password?</p>
                          </div>
                        </div> */}
            <div className="login-button d-flex align-items-center justify-content-center mt-3">
              {!loading ? (
                <button
                  // onClick={handleLogin}
                  type="submit"
                  className="btn btn-primary login-button"
                >
                  Login
                </button>
              ) : (
                <button className="btn btn-primary login-button">
                  <Spinner
                    style={{ height: "20px", width: "20px" }}
                    className="p-1"
                    animation="border"
                    variant="warning"
                  />
                </button>
              )}
            </div>
            <div
              onClick={() => {
                navigate("/forgot-password");
              }}
              className="forgot-link"
            >
              Forgot password ?
            </div>
          </Form>
          {/* {loading ? <SiteLoader /> : ""} */}
        </div>
      </div>
    </div>
  );
}
export default Login;
