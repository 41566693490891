function Loader() {
  return (
    <div className="container">
      <div className="row ">
        <div className="col d-flex justify-content-center my-5">
          <div
            className="spinner-grow  m-1"
            style={{ color: " #ffc45a", width: "1rem", height: "1rem" }}
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
          <div
            className="spinner-grow  m-1"
            style={{ color: " #ffc45a", width: "1rem", height: "1rem" }}
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
          <div
            className="spinner-grow  m-1"
            style={{ color: " #ffc45a", width: "1rem", height: "1rem" }}
            role="status"
          >
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Loader;
