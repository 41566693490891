import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { toast } from "react-toastify";
import { ApiTypes } from "../../../constants";
import SiteLoader from "../../../Layout/Loader/SiteLoader";
import APICall from "../../../networking/AxiousServices";
import dateFormat from "dateformat";
import { useNavigate } from "react-router-dom";

function Hashtag() {
  const [hashTag, setHashTag] = useState([]);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(null);
  document.title = "Kalon | HashTags";

  const navigate = useNavigate();

  const fetchHashTag = async () => {
    setLoading(true);
    let res = await APICall(
      ApiTypes.fetchHashTag,
      null,
      `page=${page - 1}&size=${perPage}`
    );

    if (res.status === 1) {
      res.data.list.forEach((listItem, index) => {
        listItem["index"] = index;
      });
      setTotalRows(res.data.totalItems);
      setHashTag(res.data.list);
      setLoading(false);
      if (res.status === 1) {
        setLoading(false);
      }
    } else if (res.status === 0 || res.status === undefined) {
      toast.error(res.message ? res.message : "Something went wrong.");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchHashTag();
  }, [page, perPage]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage, pageGot) => {
    setPerPage(newPerPage);
    setPage(pageGot);
  };

  const columns = [
    {
      name: "Sr. No.",
      selector: (row) => row.index + 1,
      sortable: true,
      width: "100px",
    },
    {
      name: "Hashtag Name",
      selector: (row) => row?.hashtag_name,
      sortable: true,
      center: true,
    },
    {
      name: "Created On",
      selector: (row) => dateFormat(row.created_on, "yyyy/mm/dd"),
      sortable: true,
      center: true,
      width: "130px",
    },
    {
      name: "Total Use",
      selector: (row) => row?.total_use,
      sortable: true,
      center: true,
      width: "110px",
    },
  ];

  return (
    <div className="container-fluid">
      <div className="row mt-4">
        <div className="col-12">
          <div className="h4 mb-4">
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-column justify-content-center">
                <div className="d-flex breadcrum-navigation-title">
                  <div className="me-2">Hashtags</div>
                  <div className="me-2 text-muted">|</div>
                  <div className="d-flex">
                    <div
                      onClick={() => navigate("/home")}
                      className="cursor-pointer custom-breadcrum-inactive me-2"
                    >
                      Home
                    </div>
                    <div className="text-muted me-2">&gt;</div>
                    <div className="cursor-pointer custom-breadcrum-active me-2">
                      Hashtags
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="table-outer react-table">
            <DataTable
              data={hashTag}
              columns={columns}
              noHeader
              persistTableHead={true}
              paginationServer
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              defaultSortField="id"
              defaultSortAsc={true}
              pagination
              highlightOnHover
              responsive={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hashtag;
