import React, { useRef, useState } from "react";
import { Col } from "react-bootstrap";
import ProfileImage from "../ProfileImage";
import ReplyComment from "./ReplyComment";

export default function CommentModule({ value }) {
  const [show, setShow] = useState(false);
  const replyMainRef = useRef(null)
  const viewReplyComment = () => {
    if (show === false) {
      setShow(true);
    } else {
      setShow(false);
    }
  };
  return (
    <>
      <div className="mt-3 ">
        <div className="d-flex justify-content-center gap-2 mb-2 px-2">
          <div>
            <ProfileImage commentMemberDetails={value.commentMemberDetails} />
          </div>



          <Col className="text-start d-flex flex-column">
            <div className="mb-2">
              <div className="h6 mb-0">
                {value.commentMemberDetails
                  ? value.commentMemberDetails.display_name
                  : ""}
              </div>
              <div id="passwordHelpBlock" className="text-muted ">
                {value.comments}
              </div>
            </div>

            <div className="cursor-pointer" style={{ width: "fit-content" }}>
              {value.selfPostComment.length > 0 && !show ? (
                <>
                  <i className="fa-solid fa-comment"></i>
                  <span className="me-2 text-muted" onClick={viewReplyComment}> View all replies ({value.selfPostComment.length})

                  </span>
                  <i className="fa-solid fa-angle-down"></i>
                </>
              ) : (
                ""
              )}
            </div>
          </Col>

        </div>
        {show ? <div className="reply-main py-2" ref={replyMainRef}>
          {value.selfPostComment
            ? value.selfPostComment.map((result, index) => (
              <div className="mb-2" key={index}>
                <ReplyComment setShow={setShow} show={show} value={value} result={result} />
              </div>
            ))
            : ""}
          <div
            className=""
            role="button"
            onClick={() => {
              replyMainRef.current.classList.add('hide-reply')
              setTimeout(() => {
                setShow(false)
              }, 600)
            }
            }
          >
            {show ? (
              <>
                <i className="fa-solid fa-comment me-2"></i>
                <span className="me-2 text-muted">Hide all replies</span>
                <i className="fa-solid fa-angle-up"></i>
              </>
            ) : (
              ""
            )}
          </div>
        </div> : ""}
      </div>

    </>
  );
}
