import React from 'react'
import { useState } from 'react';
import { Modal, Button ,Spinner} from "react-bootstrap";
import { ApiTypes } from '../../constants';
import APICall from '../../networking/AxiousServices';
import { SERVICE_REQUEST_STATUS } from "../../constants/Enumeration"
import { useNavigate } from 'react-router-dom';

export default function RejectModal({ category_service_request_id, showRejectModal, setShowRejectModal }) {

    const navigate = useNavigate();

    const [comment, setComment] = useState("");
    const [loading, setLoading] = useState(false);

    const handleCommentInput = (e) => {
        setComment(e.target.value ? e.target.value : "")
    }

    const handleClose = () => {
        setShowRejectModal(false)
        setComment("")
    }

    const handleReject = async (e) => {
        setLoading(true)
        e.preventDefault();
        let res = await APICall(
            ApiTypes.rejectServiceRequest,
            {
                category_service_request_id: category_service_request_id,
                service_request_status: SERVICE_REQUEST_STATUS.REJECTED
            }
        );
        if (res.status == 1) {
            setLoading(false)
            navigate('/service-requests')
        } else if (res.status == 0) {
            setLoading(false)
        }
    }
    return (<Modal show={showRejectModal} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>Reject Service Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className='container-fluid'>
                <div className="row">
                    <div className='col-12 my-4'>
                        <input
                            type="texts"
                            value={comment}
                            onChange={(e) => {
                                handleCommentInput(e)
                            }}
                            className="form-control"
                            id="comment"
                            placeholder="Enter Comment"
                        />
                    </div>
                </div>
            </div>


        </Modal.Body>
        <Modal.Footer>
            {!loading ? <>  <Button type="button" variant="secondary" onClick={handleClose}>
                Close
            </Button>
                <Button type="button" variant="primary" onClick={(e) => { handleReject(e) }}>
                    Reject
                </Button></> : <Button type="button" variant="primary">
                <Spinner
                    style={{ height: "20px", width: "20px" }}
                    className="p-1"
                    animation="border"
                    variant="warning"
                />
            </Button>}
        </Modal.Footer>
    </Modal>)
}
