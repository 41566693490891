import React from "react";
import "./Header.css";
import Navbar from "react-bootstrap/Navbar";
// import DropdownButton from "react-bootstrap/DropdownButton";
import LogoIcon from "../../Assets/Image/Icon/kalon-logo.svg";
import UserIcon from "../../Assets/Image/Icon/user-default.jpg";
import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux'
import { logoutUser, isLoggedIn } from "../../actions/actions"
import { toast } from 'react-toastify';

function Header() {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  let handleLogout = () => {
    navigate("/")
    dispatch(logoutUser(null))
    dispatch(isLoggedIn(false))
    toast.success("Logged out successfully.")
  }

  return (
    <Navbar className="navbar navbar-default navbar-fixed-top custom-header">
      <Navbar.Brand href="#home">
        <div className="logo-area">
          <img src={LogoIcon} alt="" />
        </div>
      </Navbar.Brand>
      <Navbar.Collapse className="justify-content-end">
        <Navbar.Text>
          <div className="personal-section">
            <div className="btn-group">
              <Dropdown>
                <Dropdown.Toggle
                  className="profile-dropdown header-custom-dropdown"
                  id="dropdown-basic"
                >
                  <div className="profile">
                    <img className="profile-img-area" src={UserIcon} alt="" />
                    {JSON.parse(localStorage.getItem('loggedUser')).display_name}
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {/* <Dropdown.Item href="#">Profile</Dropdown.Item> */}
                  <Dropdown.Item href="/change/password">
                    Change Password
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item onClick={handleLogout} >Logout</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </Navbar.Text>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Header;
