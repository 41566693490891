import React from "react";
import ProfileImage from "../ProfileImage";
import { Col } from "react-bootstrap";

function ReplyComment({ setShow , result, show }) {
  return (
    <>
      <div className="d-flex  justify-content-center mt-2 gap-2">
        <div>
          <ProfileImage commentMemberDetails={result.commentMemberDetails} />
        </div>
        <Col className="text-start d-flex flex-column">
          <div class="h6 mb-0">
            {result.commentMemberDetails
              ? result.commentMemberDetails?.display_name
              : ""}
          </div>
          <div id="passwordHelpBlock" className="text-muted " >
            {result.comments}
          </div>
        </Col>
      </div>
    
       
     </>
  );
}

export default ReplyComment;
