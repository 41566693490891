
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ApiTypes, AwsConstants } from '../../constants';
import SiteLoader from '../../Layout/Loader/SiteLoader';
import APICall from '../../networking/AxiousServices';
import dateFormat from "dateformat";
import { Badges } from '../../Layout/Components/Badges';
import { Credentials } from "aws-sdk";
import S3 from "aws-sdk/clients/s3";
import placeholder from "../../Assets/Image/Icon/placeholder.png"
import Accordion from 'react-bootstrap/Accordion'

export default function ViewOrder(props) {
    document.title = "Kalon | Order";
    let { order_id } = useParams();
    const [order, setOrder] = useState([]);
    const [loading, setLoading] = useState(true);
    const [parlourSrc, setParlourSrc] = useState();
    const [userImgSrc, setUserImgSrc] = useState()
    const [noRec, setNoRec] = useState(false);
    let newArray = [];
    let navigate = useNavigate();



    const access = new Credentials({
        accessKeyId: AwsConstants.accessKeyId,
        secretAccessKey: AwsConstants.secretAccessKey,
    });

    const s3 = new S3({
        credentials: access,
        region: AwsConstants.region,
        signatureVersion: "v4",
    });


    const addDefaultSrc = () => {
        setParlourSrc(placeholder)
    }

    const addUserDefaultSrc = () => {
        setUserImgSrc(placeholder)
    }

    const getImageUrls = async (response) => {
        let key = [];
        let url = [];
        let newArray = []
        if (response.order_details.parlour_details !== null) {
            key.push(`Home/Parlours/${response.order_details.parlour_details.parlour_id}/Profile/${response.order_details.parlour_details.person_profile_pic}`)
        }
        if (response.order_details.customer_details_data !== null) {
            key.push(`Home/Parlours/${response.order_details.customer_details_data.customer_id}/Profile/${response.order_details.customer_details_data.profile_pic}`)
        }

        for (let i = 0; i < key.length; i++) {
            url[i] = (await s3.getSignedUrlPromise("getObject", {
                Bucket: AwsConstants.Bucket,
                Key: `${key[i]}`,
                Expires: AwsConstants.signedUrlExpireSeconds,
            }));
        }

        if (response.order_details.parlour_details !== null) {
            response.order_details.parlour_details["imageUrl"] = url[0];
            setParlourSrc(url[0]);
        }

        if (response.order_details.customer_details_data !== null) {
            response.order_details.customer_details_data["imageUrl"] = url[1];
            setUserImgSrc(url[1]);
        }
        newArray.push(response);
        return newArray;
    };


    const orderView = async () => {
        props.setProgress(50);
        setLoading(true);
        let res = await APICall(ApiTypes.orderView, null, `order_id=${order_id}`);
        props.setProgress(70);
        if (res.status === 1) {
            let newData = await getImageUrls(res.data);
            setOrder(newData[0]);
            props.setProgress(100);
            setLoading(false);
        } else if (res.status === 0) {
            setNoRec(true);
            setLoading(false);
        }
    }

    const renderLocation = (value) => {
        let str = value.address_1 +
            ", " +
            (value?.address_2 ? value.address_2 + ", " : "") +
            (value?.city ? value.city + ", " : "") +
            (value.state ? value.state + ", " : "") +
            (value.pincode ? value.pincode + ", " : "");

        str = str.trim();
        str = str.slice(0, -1)
        return str;
    }



    const formatDate = (start_time) => {
        if (start_time !== null) {
            return new Date('1970-01-01T' + start_time + 'Z')
                .toLocaleTimeString('en-US',
                    { timeZone: 'UTC', hour12: true, hour: 'numeric', minute: 'numeric' }
                )
        }
        else {
            return null;
        }
    }

    useEffect(() => {
        orderView();
    }, [])

    return (<>
        <div className='container-fluid'>
            <div className="row mt-4">
                <div className='col-12'>
                    <div className="h4 mb-4">
                        <div className="d-flex justify-content-between">
                            <div className="d-flex flex-column justify-content-center">
                                <div className="d-flex breadcrum-navigation-title">
                                    <div className="me-2">Order</div>
                                    <div className="me-2 text-muted">|</div>
                                    <div className=" d-flex">
                                        <div
                                            onClick={() => navigate("/home")}
                                            className="cursor-pointer custom-breadcrum-inactive me-2"
                                        >
                                            Home
                                        </div>
                                        <div className="text-muted me-2">&gt;</div>
                                        <div
                                            onClick={() => navigate("/orders")}
                                            className="cursor-pointer custom-breadcrum-inactive me-2"
                                        >
                                            Orders
                                        </div>
                                        <div className="text-muted me-2">&gt;</div>
                                        <div className="cursor-pointer custom-breadcrum-active me-2">
                                            Order
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {!loading ?

                        !noRec ?
                            <div className='row'>
                                <div className='col-12 col-md-12 col-sm-12 col-lg-12 mx-0 justify-content-between'>
                                    <div className='gy-lg-2'>
                                        <div className='mb-xs-2 me-0'>
                                            {order.order_details !== null && order.order_details !== null &&
                                                <div className='card mb-4'>
                                                    <div className='h5 mb-3 order-name'>Order Details ({order.order_details.order_no})</div>
                                                    <div className="row">
                                                        <div className="col-lg-4 col-sm-6 col-12">
                                                            <div className='ps-3'>
                                                                <dl className='mb-1 row '>
                                                                    <dt className="mb-1 col-md-6 col-sm-8 col-lg-6 font-600">Intial Amount:</dt>
                                                                    <dd className="mb-1 col-sm-12 col-md-6 col-lg-6 text-muted">
                                                                        {order.order_details.initial_amount}
                                                                    </dd>
                                                                </dl>
                                                                <dl className='row mb-1'>
                                                                    <dt className="mb-1 col-md-6 col-sm-8 col-lg-6 font-600">Discounted Amount:</dt>
                                                                    <dd className="mb-1 col-sm-12 col-md-6 col-lg-6 text-muted">
                                                                        {order.order_details.discount_amount}
                                                                    </dd>
                                                                </dl>
                                                                <dl className='row mb-1'>
                                                                    <dt className="mb-1 col-md-6 col-sm-8 col-lg-6 font-600">Coupon Amount:</dt>
                                                                    <dd className="mb-1 col-sm-12 col-md-6 col-lg-6 text-muted">
                                                                        {order.order_details.coupon_amount}
                                                                    </dd>
                                                                </dl>
                                                                <dl className='row mb-1'>
                                                                    <dt className="mb-1 col-md-6 col-sm-8 col-lg-6 font-600">Final Amount:</dt>
                                                                    <dd className="mb-1 col-sm-12 col-md-6 col-lg-6 text-muted">
                                                                        {order.order_details.final_amount}
                                                                    </dd>
                                                                </dl>

                                                            </div>
                                                        </div>

                                                        <div className='col-lg-4 col-sm-6 col-12'>
                                                            <div className="ps-2">
                                                                <dl className='row mb-1'>
                                                                    <dt className="mb-1 col-md-6 col-sm-8 col-lg-6 font-600" >Order Status:</dt>
                                                                    <dd className="mb-1 col-sm-12 col-md-6 col-lg-6 text-muted">
                                                                        {Badges.OrderStatusBadges(order.order_details.order_status)}
                                                                    </dd>
                                                                </dl>
                                                                <dl className='row mb-1'>
                                                                    <dt className="mb-1 col-md-6 col-sm-8 col-lg-6 font-600">Payment Mode:</dt>
                                                                    <dd className="mb-1 col-sm-12 col-md-6 col-lg-6 text-muted">
                                                                        {Badges.PaymentmModeBadges(order.order_details.payment_mode)}
                                                                    </dd>
                                                                </dl>
                                                                <dl className='row mb-1'>
                                                                    <dt className="mb-1 col-md-6 col-sm-8 col-lg-6 font-600">Payment Status:</dt>
                                                                    <dd className="mb-1 col-sm-12 col-md-6 col-lg-6 text-muted">
                                                                        {Badges.PayMentStatusBadges(order.order_details.payment_status)}
                                                                    </dd>
                                                                </dl>
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-3 pb-3 col-sm-6 col-8 mx-auto text-center' >
                                                            <div className='h5'>Schedule Date & Time</div>
                                                            <div className='shadow  p-2 orderDate'>
                                                                <dl className='row mb-1'>
                                                                    <dd className="mb-1 h5">
                                                                        {
                                                                            dateFormat(order.order_details.order_date, "mmm d, yyyy")
                                                                        }
                                                                    </dd>
                                                                </dl>
                                                                <dl className='row mb-1'>
                                                                    <dd className="mb-1 h5">
                                                                        {formatDate(order.order_details.time_slot_details_data?.slot_time)}
                                                                    </dd>
                                                                </dl>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>

                                        <div className='row'>
                                            <div className="col-xl-8">
                                                <div className='card'>
                                                    <div className='h5 mb-3 order-name'>Parlour Details</div>
                                                    <div className='orderImg'>
                                                        <img src={parlourSrc} onError={() => addDefaultSrc()} alt="" />
                                                    </div>
                                                    <div className='col-12 text-center h5'>
                                                        {order.order_details.parlour_details.parlour_name}
                                                    </div>
                                                    <div className='col-12 pe-0 text-center'>
                                                        <dl className='row mb-1 '>
                                                            <dd className="mb-1 col-12 text-muted">
                                                                <i className="fa-solid fa-location-dot me-2" aria-hidden="true"></i>
                                                                {renderLocation(order.order_details.parlour_details)}
                                                            </dd>
                                                        </dl>
                                                    </div>
                                                    <div className='d-flex'>
                                                        <div className='col-lg-6 col-7 ps-3'>

                                                            <dl className='row mb-1'>
                                                                <dt className="mb-1 col-md-6 col-sm-8 col-lg-6 font-600 ">Person Name:</dt>
                                                                <dd className="mb-1 col-sm-12 col-md-6 col-lg-6 text-muted">
                                                                    {order.order_details.parlour_details.person_full_name}
                                                                </dd>
                                                            </dl>
                                                            <dl className='row mb-1'>
                                                                <dt className="mb-1 col-md-6 col-sm-8 col-lg-6 font-600 ">Contact No:</dt>
                                                                <dd className="mb-1 col-sm-12 col-md-6 col-lg-6 text-muted">
                                                                    {order.order_details.parlour_details.contactno_1}
                                                                </dd>
                                                            </dl>
                                                        </div>
                                                        <div className='col-lg-6 col-5'>
                                                            <dl className='row mb-1'>
                                                                <dt className="mb-1 col-md-6 col-sm-8 col-lg-6 font-600 ">Start Time:</dt>
                                                                <dd className="mb-1 col-sm-12 col-md-6 col-lg-6 text-muted">
                                                                    {formatDate(order.order_details.parlour_details.start_time)}
                                                                </dd>
                                                            </dl>
                                                            <dl className='row mb-1'>
                                                                <dt className="mb-1 col-md-6 col-sm-8 col-lg-6 font-600 ">End Time:</dt>
                                                                <dd className="mb-1 col-sm-12 col-md-6 col-lg-6 text-muted">
                                                                    {formatDate(order.order_details.parlour_details.end_time)}
                                                                </dd>
                                                            </dl>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-xl-4 mt-4 mt-xl-0">
                                                {order.order_details.customer_details_data !== null &&
                                                    <div className="pe-0 h-100">

                                                        <div className='card h-100'>
                                                            <div className='h5 mb-3 order-name'>User Details</div>
                                                            <div className='orderImg'>
                                                                <img src={userImgSrc} onError={() => addUserDefaultSrc()} alt="" />
                                                            </div>
                                                            <dl className='col-12 text-center mb-1'>
                                                                <dd className="mb-1 col-12 h5 ">
                                                                    {order.order_details.customer_details_data.full_name}
                                                                </dd>
                                                            </dl>
                                                            <div className='py-2 px-3'>
                                                                <>
                                                                    <dl className='row mb-1'>
                                                                        <dt className="mb-1 col-md-6 col-sm-8 col-lg-6 font-600">Mobile No:</dt>
                                                                        <dd className="mb-1 col-sm-12 col-md-6 col-lg-6 text-muted">
                                                                            {order.order_details.customer_details_data.mobile_no}
                                                                        </dd>
                                                                    </dl>

                                                                    <dl className='row mb-1'>
                                                                        <dt className="mb-1 col-md-6 col-sm-8 col-lg-6 font-600">Email id:</dt>
                                                                        <dd className="mb-1 col-sm-12 col-md-6 col-lg-6 text-muted">
                                                                            {order.order_details.customer_details_data.email_id ? order.order_details.customer_details_data.email_id : "-"}
                                                                        </dd>
                                                                    </dl>
                                                                </>

                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>

                                        <div className='m-auto mb-2 mt-2 mt-xs-2 pe-0'>
                                            {order.item_list.length > 0 ? <div className='mb-0 h5 py-2 order-name border-0'>Item Details</div> : ""}
                                        </div>
                                        {order.item_list.map((value, index) => (
                                            <>
                                                <Accordion key={index} className={`mb-2 pe-0 ${!value.is_package ? 'accordion-order' : ''}`}>
                                                    <Accordion.Item className="border-warning" eventKey="0">
                                                        <Accordion.Header >
                                                            <div className='accordion-tabs'>
                                                                <div className='accordion-tab'>
                                                                    <div className='d-flex'>
                                                                        <div className='font-600 me-2'>
                                                                            {!value.is_package ? value.service_details?.service_name : value.package_details?.package_name}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className='accordion-tab'>
                                                                    <div className='d-flex'>
                                                                        <div className='font-600 me-2'>
                                                                            Price:
                                                                        </div>
                                                                        <div className=' text-muted '>
                                                                            {!value.is_package ? value.service_details?.service_price : value.package_details.package_price}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className='accordion-tab'>
                                                                    <div className='d-flex'>
                                                                        <div className='font-600 me-2'>
                                                                            Dicounted Price:
                                                                        </div>
                                                                        <div className='text-muted'>
                                                                            {!value.is_package ? value.service_details?.service_discount : value.package_details.package_discount}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className='accordion-tab'>
                                                                    <div className='d-flex'>
                                                                        <div className='font-600 me-2'>
                                                                            Quantity:
                                                                        </div>
                                                                        <div className='text-muted'>
                                                                            {value.qty}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='accordion-tab'>
                                                                    <div >
                                                                        <div className='font-600 d-flex'>
                                                                            {!value.is_package ? "Service" : "Package"}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Accordion.Header>
                                                        <Accordion.Body className={(!value.is_package ? 'accordianBody' : '')}>
                                                            {value.is_package && value.item_details.map((result, index) => (
                                                                <div key={index} className="accordion-tabs">
                                                                    <div className='accordion-tab'>
                                                                        <div className='d-flex'>
                                                                            <div className='font-600 me-2'>
                                                                                {result.package_service_mapping_details.package_services_details?.service_name}
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className='accordion-tab'>
                                                                        <div className='d-flex'>
                                                                            <div className='font-600 me-2'>
                                                                                Price:
                                                                            </div>
                                                                            <div className=' text-muted '>
                                                                                {result.package_service_mapping_details.package_services_details?.service_price}
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className='accordion-tab'>
                                                                        <div className='d-flex'>
                                                                            <div className='font-600 me-2'>
                                                                                Dicounted Price:
                                                                            </div>
                                                                            <div className='text-muted'>
                                                                                {result.package_service_mapping_details.package_services_details?.service_discount}
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className='accordion-tab'>
                                                                        <div className='d-flex'>
                                                                            <div className='font-600 me-2'>
                                                                                Quantity:
                                                                            </div>
                                                                            <div className='text-muted'>
                                                                                {value.item_details[index].qty}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='accordion-tab'>
                                                                        <div >
                                                                            <div className='font-600 d-flex  ps-4'>
                                                                                {value.is_package ? "Package-Service" : "Package"}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                            }
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </Accordion>
                                            </>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            : <div className='h6 text-center d-flex mt-5 m-auto flex-column justify-content-center'>
                                <span>Order Not Found!</span>
                            </div>

                        :
                        <SiteLoader />
                    }
                </div>
            </div>
        </div >
    </>
    )
}
