import React, { useState } from "react";
import {Form , Spinner} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux'
import { addLoggedUser, isLoggedIn } from "../../actions/actions"
import { toast } from 'react-toastify';
import APICall from "../../networking/AxiousServices"
import { ApiTypes } from "../../constants";
import SiteLoader from "../../Layout/Loader/SiteLoader";

export default function ForgotPassword({ title }) {
  document.title = title;
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState("");

  const handleResetPasswordEmail = async (e) => {

    e.preventDefault();
    if (email == "") {
      setEmailError("Email field is required.")
    }
    else {
      if (email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )) {
        setEmailError("")
        setLoading(true)
        const res = await APICall(ApiTypes.sendForgotLink, { email: email , host_origin : window.location.origin})
        if (res.status == 1) {
          setEmail("")
          setLoading(false);
          toast.success(res.message)
        }
        else if (res.status == 0) {
          setLoading(false);
          toast.error(res.message)
        }
      }
      else {
        setEmailError("Email is not valid");
      }

    }
  }
  const handleEmail = (e) => {
    setEmail(e.target.value);
  }
  return (
    <div className="login-box">
      <div className="web-login">
        <div className="padding-25px-without-top">
          <h2>Enter your email</h2>
          <Form onSubmit={(e) => { handleResetPasswordEmail(e) }} >
            <Form.Group className="mb-3" controlId="formGroupEmail">
              {/* <Form.Label className="login-label">
                Email
              </Form.Label> */}
              <Form.Control
                onChange={(e) => { handleEmail(e) }}
                type="email"
                value={email}
                placeholder="Enter email"
              />
              {emailError != "" ? <span className='validation'>*{emailError}</span> : ""}
            </Form.Group>


            {!loading ? <button
              // onClick={handleLogin}
              type="submit"
              className="btn btn-primary login-button"
            >
              Submit
            </button>: (
                <button className="btn btn-primary login-button">
                  <Spinner
                    style={{ height: "20px", width: "20px" }}
                    className="p-1"
                    animation="border"
                    variant="warning"
                  />
                </button>
              )}
          </Form>
        </div>
      </div >
    </div >
  )
}
