import React from 'react'
import LoginImage from "../../Assets/Image/Icon/login-image-kalon.png";
import LogoIcon from "../../Assets/Image/Icon/kalon-logo.svg";
// import LogoIcon from "../../Assets";

export default function AuthContainer({ AuthComponent, title, progress }) {
    document.title = title;
    return (
        <div className="login-page">
            <div className="container-fluid p-0">
                <div className="row m-0">
                    <div className="col-12 col-md-6 col-sm-12 p-0">
                        <div className="left-seciton d-flex align-items-center justify-content-center">
                            <img className="login-image" src={LoginImage} alt="" />
                            <div className="logo-area">
                                <img src={LogoIcon} alt="" />
                            </div>
                            {/* <p>
              <b>Beautician</b>
            </p> */}
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-sm-12 p-0">
                        <div className="box">
                            <AuthComponent title={title} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
