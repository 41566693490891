import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Line } from "react-chartjs-2";

export default function Chart({ type, labels, chartdata , charttitle }) {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const data = {
    labels,
    datasets: [
      {
        label: "Dataset 2",
        data: chartdata,
        borderColor: "#ffc45a",
        backgroundColor: "#fff",
        fill: false,
        tension: 0.1,
        shadowBlur : 2,
        shadowOffsetX : 2,
        shadowOffsetY : 2,
        borderShadowColor :"#000" 
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
      title: {
        display: true,
        text: charttitle,
      }
    },
  };

  if (type == "line-chart") {
    return <Line options={options} data={data} />;
  }
}
