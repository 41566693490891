import React, { useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import "react-data-table-component-extensions/dist/index.css";
import APICall from "../../networking/AxiousServices";
import { ApiTypes, AwsConstants } from "../../constants";
import { Button, Breadcrumb } from "react-bootstrap";
import SwitchButton from "../../Layout/Components/SwitchButton";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import S3 from "aws-sdk/clients/s3";
import { Credentials } from "aws-sdk";
import Swal from "sweetalert2";
import blank from "../../Assets/Image/Default/blank.png";
import CustomTableLoader from "../../Layout/Loader/CustomTableLoader";
import { BANNER_SCREEN } from "../../constants/Enumeration";

export default function Index() {
    document.title = "Kalon | Banners";
    let navigate = useNavigate();
    const [banners, setBanners] = useState([]);
    const [loading, setLoading] = useState(false);
    const textInput = useRef(null);
    const access = new Credentials({
        accessKeyId: AwsConstants.accessKeyId,
        secretAccessKey: AwsConstants.secretAccessKey,
    });

    const s3 = new S3({
        credentials: access,
        region: AwsConstants.region,
        signatureVersion: "v4",
    });

    const chageStatus = async (value, row) => {
        setBanners((prevState) =>
            prevState.map((mapItem) => {
                if (row.banner_id === mapItem.banner_id) {
                    return {
                        ...mapItem,
                        is_active: value,
                    };
                }
                return mapItem;
            })
        );
        let data = { banner_id: row.banner_id, is_active: value };
        let res = await APICall(ApiTypes.changeBannerStatus, data);
        if (res.status == 1) {
            if (value == false) {
                toast.success("Banner is disabled successfully.");
            } else {
                toast.success("Banner is enabled successfully.");
            }
        }
    };
    const getImageUrls = async (response) => {
        let newArray = [];
        for (let i = 0; i < response.length; i++) {

            if (response[i].banner_media) {
                const url = await s3.getSignedUrlPromise("getObject", {
                    Bucket: AwsConstants.Bucket,
                    Key: `Home/Banners/${response[i].banner_id}/${response[i].banner_media}`,
                    Expires: AwsConstants.signedUrlExpireSeconds,
                });
                response[i]["banner_url"] = url;
            } else {
                response[i]["banner_url"] = null;
            }

            response[i]["index"] = i;
            newArray.push(response[i]);
        }
        return newArray;
    };

    // const getImageUrls = (response) => {
    //     let newAr = [];
    //     var res = new Promise((resolve, reject) => {
    //         response.forEach(async (item, index) => {
    //             const url = await s3.getSignedUrlPromise("getObject", {
    //                 Bucket: AwsConstants.Bucket,
    //                 Key: `Home/Banners/${item.banner_id}/${item.banner_media}`,
    //                 Expires: AwsConstants.signedUrlExpireSeconds,
    //             });
    //             let newJson = {
    //                 ...item,
    //                 banner_url: url,
    //                 index : index
    //             }
    //             newAr = [...newAr, newJson]
    //             if (index === response.length - 1) resolve();
    //         });
    //     });

    //     res.then(() => {
    //         setBanners(newAr);
    //         setLoading(false);
    //     });
    // };

    const fetchBanners = async () => {
        setLoading(true);
        const bodyData = {
            banner_screen: "DASHBOARD"
        };
        let res = await APICall(ApiTypes.fetchBanners, bodyData);
        if (res.status === 1) {
            if (res.data) {
                let newArray = await getImageUrls(res.data);
                setBanners(newArray);
                setLoading(false);
            } else {
                setLoading(false)
            }

        }
    };

    const handleEditBanner = (ban_id) => {
        navigate(`/edit/${ban_id}/banner`);
    };

    const handleDeleteBanner = async (ban_id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            setLoading(true);
            if (result.isConfirmed) {
                let res = await APICall(ApiTypes.deleteBanner, {
                    banner_id: ban_id,
                });
                if (res) {
                    if (res.status == 1) {
                        toast.success("Banner deleted successfully.");
                        fetchBanners();
                    } else {
                        setLoading(false);
                    }
                } else {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        });
    };

    useEffect(() => {
        fetchBanners();
    }, []);

    const columns = [
        {
            name: "Sr. No.",
            selector: (row) => row.index + 1,
            sortable: true,
            width: "100px",
        },
        {
            name: "Banner Title",
            selector: (row) => row.banner_title,
            sortable: true,
            width: "150px",
        },
        {
            name: "Banner Screen",
            selector: (row) => BANNER_SCREEN[row.banner_description],
            sortable: true,
        },
        {
            name: "Banner Description",
            selector: (row) => row.banner_description,
            sortable: true,
        },
        {
            name: "Banner Image",
            selector: (row) => row.banner_url ? (
                <img className="custom-image" src={row.banner_url}></img>
            ) : (
                <img className="custom-image" src={blank}></img>
            ),
            sortable: true,
            width: "140px",
        },
        {
            name: "Status",
            selector: (row) => (
                <SwitchButton
                    isActive={row.is_active}
                    chageStatus={chageStatus}
                    row={row}
                />
            ),
            sortable: true,
            width: "90px",
        },
        {
            name: "Action",
            sortable: false,
            selector: (row) => (
                <>
                    <sapn
                        onClick={() => {
                            handleEditBanner(row.banner_id);
                        }}
                        className="edit-icon"
                    >
                        <i className="fas fa-edit"></i>
                    </sapn>
                    {/* <Button
                        onClick={() => {
                            handleDeleteBanner(row.banner_id);
                        }}
                        height="24px"
                        width="24px"
                        variant="danger"
                    >
                        <i className="fa fa-trash" aria-hidden="true"></i>
                    </Button> */}
                </>
            ),
            width: "90px",
        },
    ];

    return (
        <div className="container-fluid">
            <div className="row mt-4">
                <div className="col-12">
                    <div className="h4 mb-4">
                        <div className="d-flex justify-content-between">
                            <div className="d-flex flex-column justify-content-center">
                                <div className="d-flex breadcrum-navigation-title">
                                    <div className="me-2">Banners</div>
                                    <div className="me-2 text-muted">|</div>
                                    <div className="d-flex">
                                        <div onClick={() => navigate('/home')} className="cursor-pointer custom-breadcrum-inactive me-2">Home</div>
                                        <div className="text-muted me-2">&gt;</div>
                                        <div className="cursor-pointer custom-breadcrum-active me-2">Banners</div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                    <div className="d-flex justify-content-end mb-4">
                        {/* <input
                                        ref={textInput}
                                        onInput={fetchBanners}
                                        type="search"
                                        className="col-4 px-2"
                                        placeholder="Search..."
                                    /> */}
                        <Button
                            onClick={() => {
                                navigate("/add-banner");
                            }}
                            className="ms-2"
                            variant="primary"
                        >
                            Add Banner
                        </Button>
                    </div>

                    <div className="table-outer react-table">
                        <DataTable
                            data={banners}
                            columns={columns}
                            noHeader
                            defaultSortField="id"
                            defaultSortAsc={true}
                            pagination
                            highlightOnHover
                            responsive={true}
                            progressPending={loading}
                            persistTableHead={true}
                            progressComponent={<CustomTableLoader />}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
