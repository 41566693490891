import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import "react-data-table-component-extensions/dist/index.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ApiTypes } from "../../constants";
import APICall from "../../networking/AxiousServices";
import blank from "../../Assets/Image/Default/blank.png";
import { useSelector } from "react-redux";
import SiteLoader from "../../Layout/Loader/SiteLoader";
import { BANNER_SCREEN } from "../../constants/Enumeration";
import imageCompression from 'browser-image-compression';

export default function AddBanner() {

    document.title = "Kalon | Add Banner";
    let navigate = useNavigate();

    const loggedUserData = useSelector((state) => state.data);
    const inputFile = useRef(null);
    const [bannerTitle, setBannerTitle] = useState("");
    const [bannerDetails, setBannerDetails] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [bannerTitleEr, setBannerTitleEr] = useState("");
    const [bannerDesEr, setBannerDesEr] = useState("");
    const [fileEr, setFileEr] = useState("");
    const [imageUrls, setImageUrls] = useState([]);
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [bannerScreen, setBannerScreen] = useState(null);
    const [bannerScreenEr, setBannerScreenEr] = useState("");
    const [bannerOrder, setBannerOrder] = useState(null);
    const [bannerOrderEr, setBannerOrderEr] = useState("");

    const handleBannerTitle = (e) => {
        if (e.target.value) {
            setBannerTitle(e.target.value);
        } else {
            setBannerTitle("");
        }
    };

    const handleBannerDescription = (e) => {
        if (e.target.value) {
            setBannerDetails(e.target.value);
        } else {
            setBannerDetails("");
        }

    };

    const handleFile = (e) => {
        if (e.target.files[0].name.match(/\.(jpg|jpeg|png)$/)) {
            setFileEr("");
            setSelectedFile(e.target.files[0]);
            setFiles([...files, e.target.files[0]]);
            inputFile.current.value = null;
        } else {
            setFileEr("Only jpg, jpeg and png files are supported.");
            setTimeout(() => {
                setFileEr("");
            }, 3000);
            inputFile.current.value = null;
        }
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        let fileNewArray = []
        for (let index = 0; index < files.length; index++) {
            let fileInner = files[index];
            const options = {
                maxSizeMB: 1,
                maxWidthOrHeight: 1920,
                useWebWorker: true
            }
            try {
                const compressedFile = await imageCompression(fileInner, options);
                let fileNew = new File([compressedFile], fileInner.name);
                fileNewArray = [...fileNewArray, fileNew]
            } catch (error) {
                console.log(error);
            }
        }
        if (bannerTitle === "") {
            setBannerTitleEr("Banner title field is required.");
        } else {
            if (bannerTitle.length <= 3) {
                setBannerTitleEr("Minimum 4 characters is required.");
            } else {
                setBannerTitleEr("");
                if (bannerScreen) {
                    setBannerScreenEr("")

                    if (bannerOrder) {
                        setBannerOrderEr("")
                        if (fileNewArray.length === 0) {
                            setFileEr("Select image for the banner.");
                        } else {
                            setFileEr("");
                            var formData = new FormData();
                            fileNewArray.forEach((fileInner) => {
                                formData.append("banner_media", fileInner);
                            });
                            formData.append("banner_title", bannerTitle.trim());
                            formData.append("banner_screen", bannerScreen);
                            formData.append("banner_order", bannerOrder);
                            formData.append("banner_description", bannerDetails.trim());
                            formData.append("updated_by", loggedUserData.userLoginData.user_id);
                            formData.append("is_active", true);
                            setLoading(true);
                            let res = await APICall(ApiTypes.addBanner, formData);
                            if (res === false) {
                                setLoading(false);
                            } else {
                                if (res.status === 1) {
                                    setLoading(false);
                                    toast.success("Banners added successfully");
                                    navigate("/banner");
                                } else {
                                    setLoading(false);
                                    toast.error(res.message);
                                }
                            }
                        }

                    } else {
                        setBannerOrderEr("Banner order is required.")
                    }
                } else {
                    setBannerScreenEr("Select banner screen.")
                }
            }
        }
    };

    useEffect(() => {
        if (selectedFile) {
            const reader = new FileReader();
            reader.readAsDataURL(selectedFile);
            reader.addEventListener(
                "load",
                function () {
                    setImageUrls([...imageUrls, reader.result]);
                },
                false
            );
            setSelectedFile(null);
        }
    }, [selectedFile]);

    const handleRemoveImage = (index) => {
        imageUrls.splice(index, 1);
        files.splice(index, 1);
        let FilesNew = [...files];
        let imageNew = [...imageUrls];
        setImageUrls(imageNew);
        setFiles(FilesNew);
    };

    const handleBannerScreen = (e) => {
        if (e.target.value) {
            setBannerScreen(e.target.value)
        } else {
            setBannerScreen(null)
        }
    }

    const handleBannerOrder = (e) => {
        if (e.target.value) {
            setBannerOrder(parseInt(e.target.value))
        } else {
            setBannerOrder(null)
        }
    }
    return (
        <div className="container-fluid">
            <div className="row mt-4">
                <div className="col-12">
                    <div className="h4 mb-4">
                        <div className="d-flex justify-content-between">
                            <div className="d-flex flex-column justify-content-center">
                                <div className="d-flex breadcrum-navigation-title">
                                    <div className="me-2">Add Banner</div>
                                    <div className="me-2 text-muted">|</div>
                                    <div className="d-flex">
                                        <div onClick={() => navigate('/home')} className="cursor-pointer custom-breadcrum-inactive me-2">Home</div>
                                        <div className="text-muted me-2">&gt;</div>
                                        <div onClick={() => navigate('/banner')} className="cursor-pointer custom-breadcrum-inactive me-2">Banner</div>
                                        <div className="text-muted me-2">&gt;</div>
                                        <div className="cursor-pointer custom-breadcrum-active me-2">Add Banner</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ background: "#fff" }}>
                        <Form className="py-4 px-3" onSubmit={(e) => handleSubmit(e)}>
                            <div className="row ">
                                <label
                                    className="col-md-4 col-sm-2 col-lg-3 col-xl-2"
                                    htmlFor="banner-title"
                                >
                                    Banner Title<span className="validation">*</span>
                                </label>
                                <div className="col-md-8 col-sm-8 col-lg-9 col-xl-8 ">
                                    <input
                                        className="form-control"
                                        type="text"
                                        value={bannerTitle}
                                        onChange={(e) => {
                                            handleBannerTitle(e);
                                        }}
                                        id="banner-title"
                                        placeholder="Enter banner"
                                    />
                                    {bannerTitleEr != "" ? (
                                        <div className="validation">*{bannerTitleEr}</div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                            <div className="row mt-4">
                                <label
                                    className="col-md-4 col-sm-2 col-lg-3 col-xl-2"
                                    htmlFor="banner-details"
                                >
                                    Banner Details
                                </label>
                                <div className="col-md-8 col-sm-8 col-lg-9 col-xl-8 ">
                                    <textarea
                                        placeholder="Enter banner details"
                                        className="col-md-8 col-sm-8 col-lg-9 col-xl-8 form-control"
                                        value={bannerDetails}
                                        onChange={(e) => {
                                            handleBannerDescription(e);
                                        }}
                                        id="banner-details"
                                        rows="3"
                                    ></textarea>
                                    {bannerDesEr !== "" ? (
                                        <span className="validation">*{bannerDesEr}</span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                            <div className="row mt-4">
                                <labels
                                    className="col-md-4 col-sm-2 col-lg-3 col-xl-2"
                                    htmlFor="banner-details"
                                >
                                    Banner Screen <span className="validation">*</span>
                                </labels>
                                <div className="col-md-8 col-sm-8 col-lg-9 col-xl-8 ">
                                    <select className="form-select" onChange={(e) => handleBannerScreen(e)} aria-label="Default select example">
                                        <option selected={bannerScreen === null ? true : false} >Select</option>
                                        {Object.keys(BANNER_SCREEN).map((banner_screen) => {
                                            return (
                                                <option selected={banner_screen === bannerScreen ? true : false} value={banner_screen}>
                                                    {BANNER_SCREEN[banner_screen]}
                                                </option>
                                            )
                                        })}
                                    </select>
                                    {bannerScreenEr !== "" ? (
                                        <span className="validation">*{bannerScreenEr}</span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                            <div className="row mt-4">
                                <label
                                    className="col-md-4 col-sm-2 col-lg-3 col-xl-2"
                                    htmlFor="banner-order"
                                >
                                    Banner Order <span className="validation">*</span>
                                </label>
                                <div className="col-md-8 col-sm-8 col-lg-9 col-xl-8 ">
                                    <input
                                        className="form-control"
                                        type="number"
                                        min="0"
                                        value={bannerOrder}
                                        onChange={(e) => {
                                            if (e.target.value >= 0) {
                                                handleBannerOrder(e);
                                            }
                                        }}
                                        id="banner-order"
                                        placeholder="Enter banner order"
                                    />
                                    {bannerOrderEr != "" ? (
                                        <div className="validation">*{bannerOrderEr}</div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                            <div className="row mt-4">
                                <label
                                    className="col-md-4 col-sm-2 col-lg-3 col-xl-2"
                                    htmlFor="banner-image"
                                >
                                    Banner Image <span className="validation">*</span>{" "}
                                </label>
                                <div className="col-md-8 col-sm-8 col-lg-9 col-xl-8 ">
                                    <div className="mt-2 gridrow">
                                        {imageUrls.length != 0
                                            ? imageUrls.map((imgUrl, index) => {
                                                return (
                                                    <div key={index} className="d-flex flex-column">
                                                        <img
                                                            className="image-category"
                                                            width="100px"
                                                            height="100px"
                                                            src={imgUrl}
                                                            alt=""
                                                        />
                                                        <button
                                                            type="button"
                                                            onClick={() => {
                                                                handleRemoveImage(index);
                                                            }}
                                                            className="btn btn-primary"
                                                        >
                                                            Remove Image{" "}
                                                        </button>
                                                    </div>
                                                );
                                            })
                                            : ""}
                                        {!imageUrls.length ?

                                            <div>
                                                <div className="position-relative d-flex flex-column justify-content-center">
                                                    <img
                                                        style={{ opacity: "0.5" }}
                                                        className="image-category"
                                                        width="100px"
                                                        height="100px"
                                                        src={blank}
                                                        alt=""
                                                    />
                                                    <div
                                                        onClick={() => {
                                                            inputFile.current.click();
                                                        }}
                                                        className="upload-icon"
                                                    >
                                                        <i
                                                            className="fa fa-plus-circle"
                                                            aria-hidden="true"
                                                        ></i>
                                                    </div>
                                                </div>
                                            </div>
                                            : ""}

                                    </div>
                                    <input
                                        ref={inputFile}
                                        onChange={(e) => {
                                            handleFile(e);
                                        }}
                                        accept="image/*"
                                        type="file"
                                        className="mx-2 d-none form-control-file"
                                        id="image-banner"
                                    />
                                    {fileEr != "" ? (
                                        <span className="validation">*{fileEr}</span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                            <div className="row">
                                <div className="mt-4 col-md-8 offset-md-2 createbtn">
                                    <button type="submit" className="btn btn-primary w-auto mb-2">
                                        Create
                                    </button>
                                </div>
                            </div>
                        </Form>
                        {loading ? <SiteLoader /> : ""}
                    </div>
                </div>
            </div>
        </div>
    );
};