import React, { useState } from "react";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarContent,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import "./Sidebar.css";
import Dashboard from "../../Assets/Image/Icon/sidebar/dashboard.svg";
import Appointment from "../../Assets/Image/Icon/sidebar/appointment.svg";
import NewsBlogs from "../../Assets/Image/Icon/sidebar/news-blogs.svg";
import Notification from "../../Assets/Image/Icon/sidebar/notification.svg";
import Report from "../../Assets/Image/Icon/sidebar/report.svg";
import Settings from "../../Assets/Image/Icon/sidebar/settings.svg";
import Category from "../../Assets/Image/Icon/sidebar/category.png"
import Service from "../../Assets/Image/Icon/sidebar/Services.png"
import Packages from "../../Assets/Image/Icon/sidebar/Packages.png"
import ShopAdmins from "../../Assets/Image/Icon/sidebar/Shop Admin.png"
import Customers from "../../Assets/Image/Icon/sidebar/Customers.png"
import Banner from "../../Assets/Image/Icon/sidebar/Banner.png"
import Users from "../../Assets/Image/Icon/sidebar/User.png"


import Transaction from "../../Assets/Image/Icon/sidebar/transaction.svg";

import { useNavigate, useLocation } from "react-router-dom";

function Sidebar() {
  let navigate = useNavigate();
  const location = useLocation();
  const [menuCollapse, setMenuCollapse] = useState(true);
  const [setupCollapse, setSetupCollapse] = useState(true);
  const [socialMediaCollapse, setSocialMediaCollapse] = useState(true);
  const menuIconClick = () => {
    //condition checking to change state from true to false and vice versa
    menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
  };

  return (
    <ProSidebar breakPoint="sm" collapsed={menuCollapse}>
      <SidebarHeader>
        <div className="closemenu" onClick={menuIconClick}>
          <i
            style={{ color: "#ffc45a" }}
            className="fa-solid fa-angle-left "
          ></i>
        </div>
      </SidebarHeader>
      <SidebarContent>
        <Menu iconShape="square">
          <MenuItem
            icon={<img src={Dashboard} alt=""></img>}
            onClick={(e) => {
              const activeSubClass = document.getElementsByClassName("active-submenu");
              if (activeSubClass.length) {
                activeSubClass[0].classList.remove("active-submenu");
              }
              e.target.classList.add("active-submenu")
              navigate("/home");
            }}
            active={location.pathname === "/home" ? true : false}
          >
            Dashboard
          </MenuItem>
          <SubMenu
            icon={<img src={Settings} alt=""></img>}
            onOpenChange={() => {
              setupCollapse ? setSetupCollapse(false) : setSetupCollapse(true);
            }}
            open={setupCollapse}
            title="Setup"
          >
            <MenuItem
              icon={<img src={Category} width="20px" height="20px" alt="" />}
              active={
                location.pathname === "/categories" ||
                  location.pathname === "/add-category"
                  ? true
                  : false
              }
              onClick={(e) => {
                const activeSubClass = document.getElementsByClassName("active-submenu");
                if (activeSubClass.length) {
                  activeSubClass[0].classList.remove("active-submenu");
                }
                e.target.classList.add("active-submenu")
                navigate("/categories");
              }}
            >
              Categories
            </MenuItem>
            <MenuItem
              icon={<img src={Service} width="20px" height="20px" alt="" />}
              active={
                location.pathname === "/services" ||
                  location.pathname === "/add-service"
                  ? true
                  : false
              }
              onClick={(e) => {
                const activeSubClass = document.getElementsByClassName("active-submenu");
                if (activeSubClass.length) {
                  activeSubClass[0].classList.remove("active-submenu");
                }
                e.target.classList.add("active-submenu")
                navigate("/services");
              }}
            >
              Services
            </MenuItem>


            <MenuItem
              icon={<img src={Service} width="20px" height="20px" alt="" />}
              active={
                location.pathname === "/requested-services"
                  ? true
                  : false
              }
              onClick={(e) => {
                const activeSubClass = document.getElementsByClassName("active-submenu");
                if (activeSubClass.length) {
                  activeSubClass[0].classList.remove("active-submenu");
                }
                e.target.classList.add("active-submenu")
                navigate("/service-requests");
              }}
            >
              Service Requests
            </MenuItem>


            <MenuItem
              icon={<img src={Packages} width="20px" height="20px" alt="" />}
              active={
                location.pathname === "/packages" ||
                  location.pathname === "/add-package"
                  ? true
                  : false
              }
              onClick={(e) => {
                const activeSubClass = document.getElementsByClassName("active-submenu");
                if (activeSubClass.length) {
                  activeSubClass[0].classList.remove("active-submenu");
                }
                e.target.classList.add("active-submenu")
                navigate("/packages");
              }}
            >
              Packages
            </MenuItem>

            <MenuItem
              icon={<img src={ShopAdmins} width="20px" height="20px" alt="" />}
              active={
                location.pathname === "/shopadmins" ||
                  location.pathname === "/add-shop-admin"
                  ? true
                  : false
              }
              onClick={(e) => {
                const activeSubClass = document.getElementsByClassName("active-submenu");
                if (activeSubClass.length) {
                  activeSubClass[0].classList.remove("active-submenu");
                }
                e.target.classList.add("active-submenu")
                navigate("/shopadmins");
              }}
            >
              Shop Admin
            </MenuItem>

            
            <MenuItem
              icon={<img src={ShopAdmins} width="20px" height="20px" alt="" />}
              active={
                location.pathname === "/requested/shop-admin" 
                  ? true
                  : false
              }
              onClick={(e) => {
                const activeSubClass = document.getElementsByClassName("active-submenu");
                if (activeSubClass.length) {
                  activeSubClass[0].classList.remove("active-submenu");
                }
                e.target.classList.add("active-submenu")
                navigate("/requested/shop-admin");
              }}
            >
              Shop Admin Requests
            </MenuItem>


            <MenuItem
              icon={<img src={Customers} width="20px" height="20px" alt="" />}
              active={location.pathname === "/customers" ? true : false}
              onClick={(e) => {
                const activeSubClass = document.getElementsByClassName("active-submenu");
                if (activeSubClass.length) {
                  activeSubClass[0].classList.remove("active-submenu");
                }
                e.target.classList.add("active-submenu")
                navigate("/customers");
              }}
            >
              Customers
            </MenuItem>
            <MenuItem
              icon={<img src={Banner} width="20px" height="20px" alt="" />}
              active={location.pathname === "/banner" ? true : false}
              onClick={(e) => {
                const activeSubClass = document.getElementsByClassName("active-submenu");
                if (activeSubClass.length) {
                  activeSubClass[0].classList.remove("active-submenu");
                }
                e.target.classList.add("active-submenu")
                navigate("/banner");
              }}
            >
              Banner
            </MenuItem>
            <MenuItem
              icon={<img src={Users} width="20px" height="20px" alt="" />}
              active={
                location.pathname === "/users" ||
                  location.pathname === "/add-user"
                  ? true
                  : false
              }
              onClick={(e) => {
                const activeSubClass = document.getElementsByClassName("active-submenu");
                if (activeSubClass.length) {
                  activeSubClass[0].classList.remove("active-submenu");
                }
                e.target.classList.add("active-submenu")
                navigate("/users");
              }}
            >
              Users
            </MenuItem>
            {/* <MenuItem
              active={location.pathname == "/rolesright" ? true : false}
              onClick={() => {
                navigate("/rolesright");
              }}
            >
              <i className="fa-solid fa-user-secret me-3"></i>Role & Right
            </MenuItem> */}
          </SubMenu>
          <MenuItem
            icon={<img src={Appointment} alt=""></img>}
            active={location.pathname === "/orders" ? true : false}
            onClick={(e) => {
              const activeSubClass = document.getElementsByClassName("active-submenu");
              if (activeSubClass.length) {
                activeSubClass[0].classList.remove("active-submenu");
              }
              e.target.classList.add("active-submenu")
              navigate("/orders");
            }}
          >
            Orders
          </MenuItem>
          {/* <MenuItem
            icon={<img src={Transaction}></img>}
            active={location.pathname == "/transaction" ? true : false}
            onClick={() => {
              navigate("/transaction");
            }}
          >
            Transaction
          </MenuItem> */}
          {/* <MenuItem
            icon={<img src={Notification} alt=""></img>}
            active={location.pathname === "/notification" ? true : false}
            onClick={(e) => {
              const activeSubClass = document.getElementsByClassName("active-submenu");
              if (activeSubClass.length) {
                activeSubClass[0].classList.remove("active-submenu");
              }
              e.target.classList.add("active-submenu");
              navigate("/notification");
            }}
          >
            Notification
          </MenuItem> */}
          {/* <MenuItem
            icon={<img src={Report} alt=""></img>}
            active={location.pathname === "/report" ? true : false}
            onClick={(e) => {
              const activeSubClass = document.getElementsByClassName("active-submenu");
              if (activeSubClass.length) {
                activeSubClass[0].classList.remove("active-submenu");
              }
              e.target.classList.add("active-submenu")
              navigate("/report");
            }}
          >
            Report
          </MenuItem> */}
          <MenuItem
            icon={<img src={NewsBlogs} alt=""></img>}
            active={location.pathname === "/news-blogs" ? true : false}
            onClick={(e) => {
              const activeSubClass = document.getElementsByClassName("active-submenu");
              if (activeSubClass.length) {
                activeSubClass[0].classList.remove("active-submenu");
              }
              e.target.classList.add("active-submenu")
              navigate("/news-blogs");
            }}
          >
            News & Blogs
          </MenuItem>

          <SubMenu
            icon={<img src={NewsBlogs} alt=""></img>}
            onOpenChange={() => {
              socialMediaCollapse ? setSocialMediaCollapse(false) : setSocialMediaCollapse(true);
            }}
            open={socialMediaCollapse}
            title="Social-Media"
          >
            <MenuItem
              icon={<img src={NewsBlogs} width="20px" height="20px" alt=""></img>}
              active={location.pathname === "/posts" ? true : false}
              onClick={(e) => {
                const activeSubClass = document.getElementsByClassName("active-submenu");
                if (activeSubClass.length) {
                  activeSubClass[0].classList.remove("active-submenu");
                }
                e.target.classList.add("active-submenu")
                navigate("/posts");
              }}
            >
              Posts
            </MenuItem>
            <MenuItem
              icon={<img src={NewsBlogs} width="20px" height="20px" alt=""></img>}
              active={location.pathname === "/hashtag" ? true : false}
              onClick={(e) => {
                const activeSubClass = document.getElementsByClassName("active-submenu");
                if (activeSubClass.length) {
                  activeSubClass[0].classList.remove("active-submenu");
                }
                e.target.classList.add("active-submenu")
                navigate("/hashtag");
              }}
            >
              HashTag
            </MenuItem>
          </SubMenu>

          {/* <MenuItem icon={<img src={}></img>} active={location.pathname == '/settings' ? true : false} onClick={() => { navigate("/settings") }}>
            Settings
          </MenuItem> */}
        </Menu>
      </SidebarContent>
    </ProSidebar>
  );
}

export default Sidebar;
