import React, { useEffect, useState } from "react";
import {Form , Spinner} from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addLoggedUser, isLoggedIn } from "../../actions/actions";
import { toast } from "react-toastify";
import APICall from "../../networking/AxiousServices";
import { ApiTypes } from "../../constants";
import SiteLoader from "../../Layout/Loader/SiteLoader";

export default function SetPassword({ title }) {
  document.title = title;
  let navigate = useNavigate();
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [passEr, setPassEr] = useState("");
  const [confirmEr, setConfirmEr] = useState(null);

  const [passHide, setPassHide] = useState(true);
  const [comPasHide, setComPasHide] = useState(true);

  const [loading, setLoading] = useState(true);
  const [apiLoading, setApiLoading] = useState(false);

  const [isExpired, setIsExpired] = useState(false);
  const [userId, setUserId] = useState(null);
  const handlePassword = (value) => {
    if (value != "") {
      if (value != confirmPassword) {
        setConfirmEr(true);
      } else {
        setConfirmEr(false);
      }
    } else {
      setConfirmEr(null);
    }
    setPassword(value);
  };
  const handleConfirmPassword = (value) => {
    if (value !== "") {
      if (value !== password) {
        setConfirmEr(true);
      } else {
        setConfirmEr(false);
      }
    } else {
      setConfirmEr(null);
    }
    setConfirmPassword(value);
  };
  const handleSetPassword = async (e) => {
    setApiLoading(true);
    e.preventDefault();
    if (password !== "") {
      setPassEr("");
      if (!confirmEr) {
        let res = await APICall(ApiTypes.setAdminPassword, {
          user_id: userId,
          password: password,
        });
        if (res.status === 0) {
          setApiLoading(false);
          toast.error(res.message);
        } else if (res.status === 1) {
          setApiLoading(false);
          toast.success(res.message);
          navigate("/");
        } else {
          setApiLoading(false);
          toast.error("Something went wrong.");
        }
      }
    } else {
      setPassEr("Confirm password is required.");
    }
  };
  let checkToken = async () => {
    setLoading(true);
    let res = await APICall(ApiTypes.checkAdminToken, {
      email_token: query.get("token"),
    });
    if (res.status === 1) {
      setUserId(res.data.user_id);
      setIsExpired(false);
      setLoading(false);
    } else if (res.status === 0) {
      setLoading(false);
      setIsExpired(true);
      toast.error("This page is expired.");
    } else {
      setLoading(false);
      setIsExpired(true);
      toast.error("Something went wrong.");
    }
  };
  useEffect(() => {
    checkToken();
  }, []);

  if (loading) {
    return <SiteLoader />;
  } else {
    return (
      <div className="login-box">
        <div className="web-login">
          {!isExpired ? (
            <div className="padding-25px-without-top">
              <h2>Set Password</h2>
              <Form
                onSubmit={(e) => {
                  handleSetPassword(e);
                }}
              >
                <Form.Group className="mb-3" controlId="formGroupEmail">
                  <Form.Label className="login-label">Password</Form.Label>
                  <div className="position-relative">
                    <Form.Control
                      onChange={(e) => {
                        handlePassword(e.target.value);
                      }}
                      type={passHide ? "password" : "text"}
                      value={password}
                      placeholder="Enter password"
                    />
                    {passHide ? (
                      <i
                        onClick={() => setPassHide(false)}
                        className="fa fa-eye eye-custom"
                        aria-hidden="true"
                      ></i>
                    ) : (
                      <i
                        onClick={() => setPassHide(true)}
                        className="fa fa-eye-slash eye-custom"
                        aria-hidden="true"
                      ></i>
                    )}
                  </div>

                  {passEr !== "" ? (
                    <span className="validation">*{passEr}</span>
                  ) : (
                    ""
                  )}
                </Form.Group>
                <Form.Group className="mb-3" controlId="formGroupPassword">
                  <Form.Label className="login-label">
                    Confirm Password
                  </Form.Label>
                  <div className="position-relative">
                    <Form.Control
                      onChange={(e) => handleConfirmPassword(e.target.value)}
                      type={comPasHide ? "password" : "text"}
                      value={confirmPassword}
                      placeholder="Enter confirm password"
                    />
                    {comPasHide ? (
                      <i
                        onClick={() => setComPasHide(false)}
                        className="fa fa-eye eye-custom"
                        aria-hidden="true"
                      ></i>
                    ) : (
                      <i
                        onClick={() => setComPasHide(true)}
                        className="fa fa-eye-slash eye-custom"
                        aria-hidden="true"
                      ></i>
                    )}
                  </div>
                  {confirmEr != null ? (
                    <div
                      className={`validation ${
                        !confirmEr ? "text-success" : ""
                      }`}
                    >
                      {confirmEr
                        ? "Password does not match."
                        : "Password matched."}
                    </div>
                  ) : (
                    ""
                  )}
                </Form.Group>
                <div className="login-button d-flex align-items-center justify-content-center mt-3">
                 {!apiLoading ?  <button
                    type="submit"
                    className="btn btn-primary login-button"
                  >
                    Set Password
                  </button> : <button className="btn btn-primary login-button">
                  <Spinner
                    style={{ height: "20px", width: "20px" }}
                    className="p-1"
                    animation="border"
                    variant="warning"
                  />
                </button> }
                </div>
              </Form>
            </div>
          ) : (
            "Page expired"
          )}
        </div>
      </div>
    );
  }
}
