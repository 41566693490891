import React, { useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import "react-data-table-component-extensions/dist/index.css";
import APICall from "../../networking/AxiousServices";
import { ApiTypes, AwsConstants } from "../../constants";
import { Button, Breadcrumb } from "react-bootstrap";
import SwitchButton from "../../Layout/Components/SwitchButton";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import S3 from "aws-sdk/clients/s3";
import { Credentials } from "aws-sdk";
import Swal from "sweetalert2";
import blank from "../../Assets/Image/Default/blank.png";
import CustomTableLoader from "../../Layout/Loader/CustomTableLoader";

export default function NewsAndBlogs() {
    document.title = "Kalon | News & Blogs";
    let navigate = useNavigate();
    const [newsAndBlogList, setNewsAndBlogList] = useState([]);
    const [loading, setLoading] = useState(false);
    const textInput = useRef(null);
    const [totalRows, setTotalRows] = useState(null);
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1);

    const chageStatus = async (value, row) => {
        setNewsAndBlogList((prevState) =>
            prevState.map((mapItem) => {
                if (row.news_id === mapItem.news_id) {
                    return {
                        ...mapItem,
                        is_active: value,
                    };
                }
                return mapItem;
            })
        );
        let data = { news_id: row.news_id, is_active: value };
        let res = await APICall(ApiTypes.changeNewsStatus, data);
        if (res.status == 1) {
            if (value == false) {
                toast.success("News is disabled successfully.");
            } else {
                toast.success("News is enabled successfully.");
            }
        }
    };

    const fetchNewsAndBlogs = async () => {
        setLoading(true);
        const bodyData = {
            // search_keyword: textInput.current.value ? textInput.current.value : "",
            search_keyword: "",
        };
        let res = await APICall(ApiTypes.getNewsAndBlogs,
            bodyData,
            `page=${page - 1}&size=${perPage}`
        );
        if (res.status === 1) {
            res.data.list.forEach((listItem, index) => {
                if (page - 1) {
                    listItem["index"] = index + ((page - 1) * perPage);
                } else {
                    listItem["index"] = index
                }
            });
            setTotalRows(res.data.totalItems);
            setNewsAndBlogList(res.data.list);
            setLoading(false);
        }
    };


    const handlePageChange = (page) => {
        setPage(page);
    };
    const handlePerRowsChange = async (newPerPage, pageGot) => {
        setPerPage(newPerPage);
        setPage(pageGot);
    };

    useEffect(() => {
        fetchNewsAndBlogs();
    }, [page, perPage]);

    const columns = [
        {
            name: "Sr. No.",
            selector: (row) => row.index + 1,
            width: "100px"
        },
        {
            name: "News Title",
            selector: (row) => <span className="cursor-pointer" title={row.news_title}>{row.news_title}</span>,
        },
        {
            name: "News Author",
            selector: (row) => <span className="cursor-pointer" title={row.news_author}>{row.news_author}</span>,
            width: "200px"
        },
        {
            name: "News Image",
            selector: (row) => row.news_main_image ?
                <img className="custom-image" src={row.news_main_image} alt=""></img> : <img className="custom-image" src={blank} alt=""></img>,
            width: "120px"
        },
        {
            name: "News Url",
            selector: (row) => <a target="_blank " href={row.news_url}>{row.news_url}</a>,
            width: "350px"
        },
        {
            name: "website",
            selector: (row) => <a target="_blank " href={row.website}>{row.website}</a>,
            width: "350px"
        },
        {
            name: "Status",
            selector: (row) => (
                <SwitchButton
                    isActive={row.is_active}
                    chageStatus={chageStatus}
                    row={row}
                />
            ),
            sortable: true,
            width: "100px"
        },
    ];
    return (
        <div class="container-fluid">
            <div className="row mt-4">
                <div className="col-12">
                    <div className="h4 mb-4">
                        <div className="d-flex justify-content-between">
                            <div className="d-flex flex-column justify-content-center">
                                <div className="d-flex breadcrum-navigation-title">
                                    <div className="me-2">News And Blogs</div>
                                    <div className="me-2 text-muted">|</div>
                                    <div className=" d-flex">
                                        <div
                                            onClick={() => navigate("/home")}
                                            className="cursor-pointer custom-breadcrum-inactive me-2"
                                        >
                                            Home
                                        </div>
                                        <div className="text-muted me-2">&gt;</div>
                                        <div className="cursor-pointer custom-breadcrum-active me-2">
                                            News And Blogs
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex ms-4"></div>
                    <div className="table-outer react-table">
                        {/* {!loading ? ( */}
                        <DataTable
                            data={newsAndBlogList}
                            columns={columns}
                            noHeader
                            paginationServer
                            persistTableHead={true}
                            filter={false}
                            print={false}
                            paginationTotalRows={totalRows}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            defaultSortField="id"
                            defaultSortAsc={true}
                            pagination
                            progressPending={loading}
                            highlightOnHover
                            progressComponent={<CustomTableLoader />}
                            responsive={true}
                        />
                        {/* ) : (
        <SiteLoader />
      )} */}
                    </div>
                </div>
            </div>
        </div>
    );
};
