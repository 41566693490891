import React, { useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import APICall from "../../networking/AxiousServices";
import { ApiTypes, AwsConstants } from "../../constants";
import { Button } from "react-bootstrap";
import SwitchButton from "../../Layout/Components/SwitchButton";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import S3 from "aws-sdk/clients/s3";
import { Credentials } from "aws-sdk";
import Swal from "sweetalert2";
import blank from "../../Assets/Image/Default/blank.png";
import CustomTableLoader from "../../Layout/Loader/CustomTableLoader";

export default function ShopAdmin() {
  document.title = "Kalon | Shop Admins";
  let navigate = useNavigate();
  const [shopAdmins, setShopAdmins] = useState([]);
  const [loading, setLoading] = useState(false);
  const textInput = useRef(null);

  const access = new Credentials({
    accessKeyId: AwsConstants.accessKeyId,
    secretAccessKey: AwsConstants.secretAccessKey,
  });

  const s3 = new S3({
    credentials: access,
    region: AwsConstants.region,
    signatureVersion: "v4",
  });

  const chageStatus = async (value, row) => {
    setShopAdmins((prevState) =>
      prevState.map((mapItem) => {
        if (row.parlour_id == mapItem.parlour_id) {
          return {
            ...mapItem,
            is_active: value,
          };
        }
        return mapItem;
      })
    );
    let data = { parlour_id: row.parlour_id, is_active: value };
    let res = await APICall(ApiTypes.changeParlourStatus, data);
    if (res.status == 1) {
      if (value == false) {
        toast.success("Parlour is disabled successfully.");
      } else {
        toast.success("Parlour is enabled successfully.");
      }
    }
  };
  const getImageUrls = async (response) => {
    let newArray = [];
    for (let i = 0; i < response.length; i++) {
      if (response[i].parlourPictures) {
        if (response[i].parlourPictures.length != 0) {
          const url = await s3.getSignedUrlPromise("getObject", {
            Bucket: AwsConstants.Bucket,
            Key: `Home/Parlours/${response[i].parlour_id}/${response[i].parlourPictures[0].display_pic}`,
            Expires: AwsConstants.signedUrlExpireSeconds,
          });
          response[i]["imageUrl"] = url;
        } else {
          response[i]["imageUrl"] = null;
        }
      } else {
        response[i]["imageUrl"] = null;
      }
      response[i]["index"] = i;
      newArray.push(response[i]);
    }
    return newArray;
  };

  const fetchShopAdmins = async () => {
    setLoading(true);
    const bodyData = {
      search_keyword: textInput.current.value ? textInput.current.value : "",
    };
    let res = await APICall(ApiTypes.fetchShopAdmins, bodyData);
    if (res.status === 1) {
      let newArray = await getImageUrls(res.data);
      setShopAdmins(newArray);
      setLoading(false);
    }
  };


  const handleEditParlour = (par_id) => {
    navigate(`/edit/${par_id}/shopadmin`);
  };

  const handleViewParlour = (par_id) => {
    navigate(`/shopadmin/view/${par_id}`)
  }

  const handleDeleteParlour = (par_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await APICall(ApiTypes.deleteParlour, { parlour_id: par_id });
        if (res) {
          if (res.status == 1) {
            toast.success("parlour deleted successfully.");
            fetchShopAdmins();
          }
        }
      }
    });
  };

  useEffect(() => {
    fetchShopAdmins();
  }, []);

  const columns = [
    {
      name: "No.",
      selector: (row) => row.index + 1,
      sortable: false,
      width: "80px",
    },
    {
      name: "Image",
      selector: (row) =>
        row.imageUrl ? (
          <img className="custom-image" src={row.imageUrl} alt=""></img>
        ) : (
          <img className="custom-image" src={blank} alt="
          "></img>
        ),
      width: "100px"
    },
    {
      name: "Parlour Name",
      selector: (row) => row.parlour_name,
      sortable: true,
    },
    {
      name: "Person  Name",
      selector: (row) => row.person_full_name,
      sortable: true,
    },
    // {
    //   name: "Address",
    //   selector: row => row.address_1 + ', '+row.address_2+', '+row.address_3 ,
    //   sortable: true,
    //   width: "180px",
    // },
    // {
    //   name: "State",
    //   selector: row => row.state,
    //   sortable: true,
    //   width: "120px",
    // },
    // {
    //   name: "City",
    //   selector: row => row.city,
    //   sortable: true,
    //   width: "120px",
    // },
    {
      name: "Contacts",
      selector: (row) => (
        <div>
          <span>{row.contactno_1}</span>
          {/* <span>{row.contactno_2}</span> */}
        </div>
      ),
      sortable: true,
      width: "110px"
    },
    // {
    //   name: "Details",
    //   selector: row => row.details,
    //   sortable: true,
    // },
    {
      name: "Email",
      selector: (row) => row.email_id,
      sortable: true,

    },
    {
      name: "Duration",
      selector: (row) => (
        <div className="d-flex flex-column">
          <span>
            <b>{row.start_time ? "FROM" : ""}</b>{" "}
            {row.start_time ? row.start_time : ""}
          </span>
          <span className="mt-1">
            <b>{row.end_time ? "TO" : ""}</b> {row.end_time ? row.end_time : ""}
          </span>
        </div>
      ),
      sortable: true,
      width: "150px"
    },
    // {
    //   name: "Speciality",
    //   selector: (row) => row.speciality_details,
    //   sortable: true,
    //   width: "120px",
    // },

    {
      name: "Status",
      selector: (row) => (
        <SwitchButton
          isActive={row.is_active}
          chageStatus={chageStatus}
          row={row}
        />
      ),
      sortable: true,
      width: "90px"

    },
    {
      name: "Action",
      sortable: false,
      selector: (row) => (
        <>
          <span
            onClick={() => {
              handleViewParlour(row.parlour_id);
            }}
            className="eye-icon"
          >
            <i className="fas fa-eye"></i>
          </span>
          <span
            onClick={() => {
              handleEditParlour(row.parlour_id);
            }}
            className="edit-icon ms-2"
          >
            <i className="fas fa-edit"></i>
          </span>
          {/* <Button
            onClick={() => {
              handleDeleteParlour(row.parlour_id);
            }}
            height="24px"
            width="24px"
            variant="danger"
          >
            <i className="fa fa-trash" aria-hidden="true"></i>
          </Button> */}
        </>
      ),
      width: "90px"
    },
  ];

  return (
    <div className="container-fluid">
      <div className="row mt-4">
        <div className="col-12">
          <div className="h4 mb-4">
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-column justify-content-center">
                <div className="d-flex breadcrum-navigation-title">
                  <div className="me-2">Shop Admins</div>
                  <div className="me-2 text-muted">|</div>
                  <div className=" d-flex">
                    <div
                      onClick={() => navigate("/home")}
                      className="cursor-pointer custom-breadcrum-inactive me-2"
                    >
                      Home
                    </div>
                    <div className="text-muted me-2">&gt;</div>
                    <div className="cursor-pointer  custom-breadcrum-active me-2">
                      Shop Admins
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>


          <div className="d-flex justify-content-between mb-4">
            <input
              ref={textInput}
              onInput={fetchShopAdmins}
              type="search"
              className="col-4 px-2"
              placeholder="Search..."
            />
            <Button
              onClick={() => {
                navigate("/add-shop-admin");
              }}
              variant="primary"
            >
              Add Shop Admin
            </Button>
          </div>

          <div className="d-flex gap-2 col-3 align-items-center ms-2">

          </div>

          <div className="table-outer react-table">
            <DataTable
              noHeader
              defaultSortField="id"
              defaultSortAsc={true}
              pagination
              highlightOnHover
              data={shopAdmins}
              columns={columns}
              responsive={true}
              progressPending={loading}
              persistTableHead={true}
              progressComponent={<CustomTableLoader />}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
