import React from 'react'
import Switch from "react-switch";

export default function SwitchButton({ row, isActive, chageStatus, isDisabled = false }) {
    return (<Switch onColor={'#000'} uncheckedIcon={false} disabled={isDisabled} checkedIcon={false} onChange={(e) => {
        if(!isDisabled){
            chageStatus(e, row)
        }
    }} checked={isActive} />)
}
