import React, { useEffect, useState } from "react";
import SiteLoader from "../../Layout/Loader/SiteLoader";
import DataTable from "react-data-table-component";
import "react-data-table-component-extensions/dist/index.css";
import APICall from "../../networking/AxiousServices";
import { ApiTypes, AwsConstants } from "../../constants";
import { Button } from "react-bootstrap";
import SwitchButton from "../../Layout/Components/SwitchButton";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import S3 from "aws-sdk/clients/s3";
import { Credentials } from "aws-sdk";
import Swal from "sweetalert2";
import blank from "../../Assets/Image/Default/blank.png";
import CustomTableLoader from "../../Layout/Loader/CustomTableLoader";
import { SERVICE_TYPE } from "../../constants/Enumeration";
import { Badges } from "../../Layout/Components/Badges";

export default function Services() {
    document.title = "Kalon | Services";
    let navigate = useNavigate();
    const [services, setServices] = useState([]);
    const [loading, setLoading] = useState(false);


    const [totalRows, setTotalRows] = useState(null);
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1);

    const access = new Credentials({
        accessKeyId: AwsConstants.accessKeyId,
        secretAccessKey: AwsConstants.secretAccessKey,
    });

    const s3 = new S3({
        credentials: access,
        region: AwsConstants.region,
        signatureVersion: "v4",
    });


    const handlePageChange = (page) => {
        setPage(page);
    };
    const handlePerRowsChange = async (newPerPage, pageGot) => {
        setPerPage(newPerPage);
        setPage(pageGot);
    };
    useEffect(() => {
        fetchServices();
    }, [page, perPage]);
    const chageStatus = async (value, row) => {
        // setServices((prevState) => prevState.map((mapItem) => ({
        //   ...mapItem,
        //   refServices: mapItem.refServices.map((mapItemInner) => {
        //     if (row.category_service_id == mapItemInner.category_service_id) {
        //       return {
        //         ...mapItemInner,
        //         is_active: value
        //       }

        //     } else {
        //       return {
        //         ...mapItemInner,
        //         is_active: false
        //       };
        //     }
        //   })
        // })))
        let data = {
            category_service_id: row.category_service_id,
            is_active: value,
        };
        let res = await APICall(ApiTypes.changeServiceStatus, data);
        if (res.status == 1) {
            if (value == false) {
                toast.success("Service is disabled successfully.");
            } else {
                toast.success("Service is enabled successfully.");
            }
            fetchServices();
        }
    };

    const getImageUrls = async (response) => {
        let newArray = [];
        for (let i = 0; i < response.length; i++) {
            // let refServices = response[i].refServices;
            // if (refServices[0].categoryServicePictureDetails) {
            //   if (refServices[0].categoryServicePictureDetails.length != 0) {
            //     const url = await s3.getSignedUrlPromise("getObject", {
            //       Bucket: AwsConstants.Bucket,
            //       Key: `Home/Services/${refServices[0].category_service_id}/${refServices[0].categoryServicePictureDetails[0].display_pic}`,
            //       Expires: AwsConstants.signedUrlExpireSeconds,
            //     });
            //     refServices[0]["imageUrl"] = url;
            //   } else {
            //     refServices[0]["imageUrl"] = null;
            //   }
            // } else {
            //   refServices[0]["imageUrl"] = null;
            // }
            // refServices[0]["index"] = i;


            if (response[i].request_pictures) {
                if (response[i].request_pictures.length != 0) {
                    const url = await s3.getSignedUrlPromise("getObject", {
                        Bucket: AwsConstants.Bucket,
                        Key: `Home/RequestServices/${response[i].category_service_request_id}/${response[i].request_pictures[0].display_pic}`,
                        Expires: AwsConstants.signedUrlExpireSeconds,
                    });
                    response[i]['imageUrl'] = url;
                }
                else {
                    response[i]['imageUrl'] = null;
                }
            }
            else {
                response[i]['imageUrl'] = null;
            }
            if (page - 1) {
                response[i]["index"] = i + ((page - 1) * perPage);
            } else {
                response[i]["index"] = i
            }


            // for (let j = 0; j < refServices.length; j++) {

            // }

            newArray.push(response[i]);
        }
        return newArray;
    };

    const fetchServices = async () => {
        setLoading(true);
        let res = await APICall(ApiTypes.getServiceRequestList, { page: page, size: perPage });
        if (res.status == 1) {
            let newArray = await getImageUrls(res.data.service_requests.list);
            setTotalRows(res.data.service_requests.totalItems);
            setServices(newArray);
            setLoading(false);
        }
    };
    const handleEditService = (ser_id) => {
        navigate(`/edit/${ser_id}/service`);
    };

    const handleDeleteService = (ser_id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                let res = await APICall(ApiTypes.deleteService, {
                    category_service_id: ser_id,
                });
                if (res) {
                    if (res.status == 1) {
                        toast.success("Service deleted successfully.");
                        fetchServices();
                    }
                }
            }
        });
    };

    useEffect(() => {
        fetchServices();
    }, []);

    // const InnerColumns = [
    //   {
    //     name: "Sr. No.",
    //     selector: (row) => row.index + 1,
    //     sortable: true,
    //     width: "90px",
    //   },
    //   {
    //     name: "Service Name",
    //     selector: (row) => row.service_name,
    //     sortable: true,
    //     width: "200px",
    //   },
    //   {
    //     name: "Category",
    //     selector: (row) => row.categoryDetails.category_name,
    //     sortable: true,
    //   },
    //   {
    //     name: "Service Details",
    //     selector: (row) => row.service_details,
    //     sortable: true,
    //   },
    //   {
    //     name: "Price",
    //     selector: (row) => (
    //       <span>
    //         {" "}
    //         <i className="fa fa-inr" aria-hidden="true"></i> {row.service_price}
    //       </span>
    //     ),
    //     sortable: true,
    //     width: "100px",
    //   },
    //   {
    //     name: "Discount",
    //     selector: (row) => (
    //       <span>
    //         <i className="fa fa-inr" aria-hidden="true"></i>{" "}
    //         {row.service_discount}{" "}
    //       </span>
    //     ),
    //     sortable: true,
    //     width: "100px",
    //   },
    //   {
    //     name: "Time (Min)",
    //     selector: (row) => row.time_in_min,
    //     sortable: true,
    //     width: "130px",
    //   },
    //   {
    //     name: "Image",
    //     selector: (row) =>
    //       row.imageUrl ? (
    //         <img className="custom-image" src={row.imageUrl}></img>
    //       ) : (
    //         <img className="custom-image" src={blank}></img>
    //       ),
    //     sortable: true,
    //     width: "100px",
    //   },
    //   {
    //     name: "Status",
    //     selector: (row) => (
    //       <SwitchButton
    //         isActive={row.is_active}
    //         chageStatus={chageStatus}
    //         row={row}
    //       />
    //     ),
    //     sortable: true,
    //     width: "100px",
    //   },
    //   {
    //     name: "Action",
    //     sortable: false,
    //     selector: (row) => (
    //       <>
    //         <Button
    //           style={{ marginRight: "4px" }}
    //           onClick={() => {
    //             handleEditService(row.category_service_id);
    //           }}
    //           variant="primary"
    //         >
    //           <i className="fas fa-edit"></i>
    //         </Button>
    //         <Button
    //           onClick={() => {
    //             handleDeleteService(row.category_service_id);
    //           }}
    //           height="24px"
    //           width="24px"
    //           variant="danger"
    //         >
    //           <i className="fa fa-trash" aria-hidden="true"></i>
    //         </Button>
    //       </>
    //     ),
    //   },
    // ];
    const columns = [
        {
            name: "Sr. No.",
            selector: (row) => row.index + 1,
            sortable: true,
            width: "90px",
        },
        {
            name: "Service Name",
            selector: (row) => row.service_name,
            sortable: true,
        },
        // {
        //     name: "Service Details",
        //     selector: (row) => row.service_details,
        //     sortable: true,
        // },
        // {
        //     name: "Service Type",
        //     selector: (row) => SERVICE_TYPE[row.category_service_type],
        //     sortable: true,
        // },
        {
            name: "Category Name",
            selector: (row) => row.categories.category_name ? row.categories.category_name : "",
            sortable: true,
        },
        {
            name: "Parlour Name",
            selector: (row) => row.parlour_detail.parlour_name ? <span onClick={()=>[
                    navigate(`/shopadmin/view/${row.parlour_detail.parlour_id}`)
            ]} className="p-0 cursor-pointer nav-link">{row.parlour_detail.parlour_name} </span> : "",
            sortable: true,
        },
        {
            name: "Price",
            selector: row => <span> <i className="fa fa-inr" aria-hidden="true"></i> {row.service_price}</span>,
            sortable: true,
            width: "130px"
        },
        // {
        //     name: "Discount",
        //     selector: row => <span><i className="fa fa-inr" aria-hidden="true"></i> {row.service_discount} </span>,
        //     sortable: true,
        //     width: "130px"
        // },
        {
            name: "Time (Min)",
            selector: row => row.time_in_min,
            sortable: true,
            width: "130px"
        },
        {
            name: "Image",
            selector: (row) => row.imageUrl ? <img className="custom-image" src={row.imageUrl}></img> : <img className="custom-image" src={blank}></img>,
            sortable: false,
            width: "100px"
        },
        {
            name: "Request Status",
            selector: (row) => (
                Badges.ServiceRequestStatusBadges(row.service_status)
                // <span
                //   className="payment-status"
                //   style={{ background: `${statusColors[row.payment_status]}` }}
                // >
                //   {row.payment_status}
                // </span>
            ),
            sortable: true,
            width: "150px",
        },
        {
            name: "Action",
            sortable: false,
            width: "90px",
            selector: row => <span onClick={() => {
                navigate(`/service-request/${row.category_service_request_id}`)
            }} className="cursor-pointer"><i class="fa fa-arrow-right" aria-hidden="true"></i></span>,
        }
    ];
    // const MyExpander = (props) => (
    //   <div>
    //     <DataTable
    //       data={props.data.refServices}
    //       columns={InnerColumns}
    //       theme="dark"
    //       defaultSortField="id"
    //       defaultSortAsc={true}
    //       highlightOnHover
    //       responsive={true}
    //     />
    //   </div>
    // );
    return (
        <div className="container-fluid">
            <div className="row mt-4">
                <div className="col-12">
                    <div className="h4 mb-4">
                        <div className="d-flex justify-content-between">
                            <div className="d-flex flex-column justify-content-center">
                                <div className="d-flex breadcrum-navigation-title">
                                    <div className="me-2">Service Requests</div>
                                    <div className="me-2 text-muted">|</div>
                                    <div className=" d-flex">
                                        <div
                                            onClick={() => navigate("/home")}
                                            className="cursor-pointer custom-breadcrum-inactive me-2"
                                        >
                                            Home
                                        </div>
                                        <div className="text-muted me-2">&gt;</div>
                                        <div className="cursor-pointer custom-breadcrum-active me-2">
                                            Service Requests
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="react-table">
                        <div className="table-outer">
                            <DataTable
                                id="main-table"
                                data={services}
                                columns={columns}
                                // expandableRowsComponent={MyExpander}
                                // expandableRows={true}
                                defaultSortField="id"
                                defaultSortAsc={true}
                                pagination
                                highlightOnHover
                                responsive={true}
                                className="myclass"
                                progressPending={loading}
                                persistTableHead={true}
                                paginationServer
                                paginationTotalRows={totalRows}
                                onChangeRowsPerPage={handlePerRowsChange}
                                onChangePage={handlePageChange}
                                progressComponent={<CustomTableLoader />}
                            />
                            {/* {loading ?<SiteLoader />:""} */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
