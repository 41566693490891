import React from 'react'
import ComingSoon from '../Layout/Components/ComingSoon'

export default function Report() {
  document.title = "Kalon | Report";
  return (
      <div className="container">
            <ComingSoon />
      </div>
  )
}
