import React, { useState, useEffect } from "react";
import "react-data-table-component-extensions/dist/index.css";
import { useSelector } from "react-redux";
import Appointment from "../Assets/Image/Icon/sidebar/appointment.svg";
import ReactHorizontalDatePicker from "react-horizontal-strip-datepicker";
import "react-horizontal-strip-datepicker/dist/ReactHorizontalDatePicker.css";
import DatePicker from "react-horizontal-datepicker";
import { Card, Badge, Spinner } from "react-bootstrap";
import blank from "../Assets/Image/Default/blank.png";
import Chart from "../Layout/Components/Chart";
import APICall from "../networking/AxiousServices";
import { ApiTypes } from "../constants";
import { useNavigate } from "react-router-dom";
import Customers from "../Assets/Image/Icon/sidebar/Customers.png";
import { Badges } from "../Layout/Components/Badges";
import Users from "../Assets/Image/Icon/sidebar/User.png";
import InfiniteScroll from 'react-infinite-scroll-component';

function Home(props) {
  //For table data
  document.title = "Kalon | Home";
  const loggedUserData = useSelector((state) => state.data);
  const [orderLoading, setOrderLoading] = useState(false);
  const [monthLabels, setMonthLabels] = useState(false);
  const [motnthData, setMonthData] = useState([]);

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [upcomingOrders, setUpcomingOrders] = useState([]);
  const [page, setPage] = useState(1);
  const [hashMore, setHashMore] = useState(true);

  const navigate = useNavigate();

  const [allCounts, setAllCounts] = useState([]);

  const getTotalCardCount = async () => {
    let res = await APICall(ApiTypes.getDashboardCount)
    let res_graph = await APICall(ApiTypes.dashboardGraphData, {
      year: new Date().getFullYear()
    })

    if (res.status === 1 && res_graph.status === 1) {
      setAllCounts(res.data)

      let monthArray = [];
      let valueArray = [];
      if (res_graph.data.graph.length) {
        res_graph.data.graph.forEach(item => {
          monthArray = [...monthArray, item.month_name]
          valueArray = [...valueArray, item.order_count]
        });
        setMonthLabels(monthArray)
        setMonthData(valueArray)
      } else {
        setMonthLabels([])
      }
    }
  }

  const removeActiveSubMenu = (result) => {
    const activeSubClass = document.getElementsByClassName("active-submenu");
    if (activeSubClass.length) {
      activeSubClass[0].classList.remove("active-submenu");
    }
    navigate(`/${result}`);
  }
  const fetchOrderDetails = async (date, is_same = false, page_no) => {
    // setOrderLoading(true)
    let res_order_list = await APICall(ApiTypes.getOrderListByDate, {
      date: date,
      page: page_no,
      size: 10
    })
    if (res_order_list.status === 1) {
      if (is_same) {
        setUpcomingOrders([...upcomingOrders, ...res_order_list.data.list])
      } else {
        setUpcomingOrders(res_order_list.data.list)
      }
      if ([...upcomingOrders, ...res_order_list.data.list].length >= res_order_list.totalItems || res_order_list.data.list.length < 10) {
        setHashMore(false)
      } else {
        setHashMore(true)
      }
    }
    // setOrderLoading(false)
  }
  const onSelectedDay = (d) => {
    setSelectedDate(d)

  }

  useEffect(() => {
    let currentMonth = selectedDate.getMonth() + 1;
    setPage(1)
    setHashMore(true)
    fetchOrderDetails(selectedDate.getFullYear() + "-" + currentMonth + "-" + selectedDate.getDate(), false, 1)
  }, [selectedDate]);
  useEffect(() => {
    let currentMonth = selectedDate.getMonth() + 1;
    fetchOrderDetails(selectedDate.getFullYear() + "-" + currentMonth + "-" + selectedDate.getDate(), true, page)
  }, [page]);
  useEffect(() => {
    getTotalCardCount()
  }, []);
  const renderTime = (item) => {
    let string = new Date()
  }
  return (
    <div className="container-fluid">
      <div className="mt-4 row">
        <div className="col-sm-12 col-lg-8 col-md-12 col-12">
          <div className="row">
            <div className="col-sm-6 col-lg-4 col-md-4 col-12 mb-4">
              <div className="card border-radius-12 border-0">
                <div className="card-body border-radius-12 p-3 d-flex flex-column border-0">
                  <h6 className="mb-25 text-dark">
                    <i className="dashboard-cards-icon fa-solid fa-file-text me-3"></i>
                  </h6>
                  <div className="d-flex  justify-content-between">
                    <span onClick={() => removeActiveSubMenu("services")} className="cursor-pointer font-weight-bolder h6 mb-0 text-dark">
                      Services
                    </span>
                    <span className="text-muted">{allCounts?.categoryServicesCount}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4 col-md-4 col-12 mb-4">
              <div className="card border-radius-12 border-0">
                <div className="card-body border-radius-12 p-3 d-flex flex-column border-0">
                  <h6 className="mb-25 text-dark">
                    <i className="dashboard-cards-icon fa-solid fa-th me-3"></i>
                  </h6>
                  <div className="d-flex  justify-content-between">
                    <span onClick={() => { return removeActiveSubMenu("packages") }} className="cursor-pointer font-weight-bolder h6 mb-0 text-dark">
                      Packages
                    </span>
                    <span className="text-muted">{allCounts?.packagesCount}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4 col-md-4 col-12 mb-4">
              <div className="card border-radius-12 border-0">
                <div className="card-body border-radius-12 p-3 d-flex flex-column border-0">
                  <h6 className="mb-25 text-dark">
                    <i className="dashboard-cards-icon fa-solid fa-user me-3"></i>
                  </h6>
                  <div className="d-flex  justify-content-between">
                    <span onClick={() => { return removeActiveSubMenu("shopadmins") }} className="cursor-pointer font-weight-bolder h6 mb-0 text-dark">
                      Parlours
                    </span>
                    <span className="text-muted">{allCounts?.parloursCount}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4 col-md-4 col-12 mb-4">
              <div className="card border-radius-12 border-0">
                <div className="card-body border-radius-12 p-3 d-flex flex-column border-0">
                  <h6 className="mb-25 text-dark">
                    <i className="dashboard-cards-icon fa-solid fa-users me-3"></i>
                  </h6>
                  <div className="d-flex  justify-content-between">
                    <span onClick={() => { return removeActiveSubMenu("customers") }} className="cursor-pointer font-weight-bolder h6 mb-0 text-dark">
                      Customers
                    </span>
                    <span className="text-muted">{allCounts?.customersCount}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4 col-md-4 col-12 mb-4">
              <div className="card border-radius-12 border-0">
                <div className="card-body border-radius-12 p-3 d-flex flex-column border-0">
                  <h6 className="mb-25 text-dark">
                    <img
                      className="dashboard-cards-icon"
                      src={Appointment}
                      alt=""
                    ></img>
                  </h6>
                  <div className="d-flex  justify-content-between">
                    <span onClick={() => { return removeActiveSubMenu("orders") }} className="cursor-pointer font-weight-bolder h6 mb-0 text-dark">
                      Orders
                    </span>
                    <span className="text-muted">{allCounts?.ordersCount}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4 col-md-4 col-12 mb-4">
              <div className="card border-radius-12 border-0">
                <div className="card-body border-radius-12 p-3 d-flex flex-column border-0">
                  <h6 className="mb-25 text-dark">
                    <i
                      className="dashboard-cards-icon fa fa-solid fa-sticky-note"
                      aria-hidden="true"
                    ></i>
                  </h6>
                  <div className="d-flex  justify-content-between">
                    <span onClick={() => { return removeActiveSubMenu("posts") }} className="cursor-pointer font-weight-bolder h6 mb-0 text-dark">
                      Posts
                    </span>
                    <span className="text-muted">{allCounts?.postsCount}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-lg-12 col-md-12 col-12 ">
              <div className="card p-3">
                <Chart
                  type="line-chart"
                  chartdata={motnthData}
                  labels={monthLabels}
                  charttitle={"Overall Orders"}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-lg-4 col-md-12 mt-4 mt-lg-0 col-12">
          <Card className="h-100 ps-3 pe-3">
            <Card.Body className="px-0">
              <div className="mb-4">
                <div>
                  <div className="h6 mb-4">Upcoming Orders</div>
                </div>

                {/* <ReactHorizontalDatePicker
                  // selectedDay={(date) => {
                  //   console.log('date' , date) 
                  //   console.log("date" , date.getFullYear() + "-"+date.getMonth()+"-"+date.getDate())
                    
                  // }}
                  selectedDay={onSelectedDay}
                  enableScroll={true}
                  enableDays={80}
                  enableDaysBefore={1}
                /> */}
                <DatePicker getSelectedDay={onSelectedDay}
                  endDate={100}
                  selectDate={selectedDate}
                  labelFormat={"MMMM"}
                  color={"#ffc45a"}
                />
              </div>

              {/*<div className="border-danger w-100 border-top"></div>*/}
              <div className="w-100 border-top"></div>
              <div id="order-upcoming-main" className="col-12 mt-3 dash-appointments-main p-2">
                {!orderLoading ? upcomingOrders.length ?

                  <InfiniteScroll
                    dataLength={upcomingOrders.length} //This is important field to render the next data
                    next={() => setPage(page + 1)}
                    hasMore={hashMore}
                    scrollableTarget="order-upcoming-main"
                    loader={<h4>Loading...</h4>}
                  >
                    {upcomingOrders.map((order, index) => {
                      return (<div key={index} className="border border-warning p-2 mb-2">
                        <div className="d-flex justify-content-between mb-2">
                          <div className="h6">{order.order_no}</div>
                          {Badges.OrderStatusBadges(order.order_status)}
                        </div>

                        <div className="d-flex">
                          <div className="d-flex flex-column justify-content-center">
                            <img className="dashboard-cards-icon me-2" src={Customers} alt="" />
                          </div>
                          <div className="d-flex w-100 justify-content-between border border-warning px-3 py-2 border-radius-12 mb-2">
                            <div className="d-flex">
                              <img className="custom-image me-2" src={blank} alt="" />
                              <div>
                                <div className="h6 mb-0">{order?.customer_details_data?.full_name}</div>
                                <div className="text-muted">
                                  <i className="fa-solid fa-phone me-2" aria-hidden="true"></i>
                                  {order?.customer_details_data?.mobile_no}
                                </div>
                                <div className="text-muted">
                                  <i className="fa-solid fa-clock me-2" aria-hidden="true"></i>
                                  {order?.time_slot_details_data?.slot_time}
                                </div>
                              </div>
                            </div>
                            <div className="text-end">
                              <div></div>
                              <div className="text-muted">₹ {order?.final_amount}</div>
                            </div>
                          </div>
                        </div>

                        <div className="border-warning w-100 border-top my-2"></div>

                        <div className="d-flex">
                          <div className="d-flex flex-column justify-content-center">
                            <img className="dashboard-cards-icon me-2" src={Users} alt=""></img>
                          </div>
                          <div className="d-flex w-100 justify-content-between border border-warning px-3 py-2 border-radius-12 mb-2">
                            <div className="d-flex">
                              <img className="custom-image me-2" src={blank}></img>
                              <div>
                                <div className="h6 mb-0">{order.parlour_details__data.parlour_name}</div>
                                <div className="text-muted mt-2">
                                  <i className="fa-solid fa-phone me-2" aria-hidden="true"></i>
                                  {order.parlour_details__data.contactno_1}
                                </div>
                                {/* <div className="text-muted">
                            <i className="fa-solid fa-clock" aria-hidden="true"></i>{" "}
                            10:20 - 10:50
                          </div> */}
                              </div>
                            </div>
                            <div className="text-end">
                              <div></div>
                              {/* <div className="text-muted">₹ 200</div> */}
                            </div>
                          </div>
                        </div>
                      </div>)
                    })}
                  </InfiniteScroll>
                  : <div className="text-center h6">Order Not Found!</div> : <div className="text-center h6">  <Spinner animation="border" variant="warning" /></div>}
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default Home;
